import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import {resources} from './resources';

// detect language
const explicitLangMatch = document.location.search.match(/\?(ru|en)/);
const implicitLangMatch = navigator.language.match(/^(ru|en)/);
const language = (explicitLangMatch)
  ? explicitLangMatch[1]
  : ((implicitLangMatch) ? implicitLangMatch[1] : 'en');

document.body.classList[(language === 'ru') ? 'add' : 'remove']('ru');

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: language,
    keySeparator: false,
    interpolation: {
      escapeValue: false
    }
  }).then();

export default i18n;
