import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Popup from 'reactjs-popup';

import './playlistNameDialog.css';

type PlaylistNameDialogProps = {
  playlistNamedHandler(playlistName?: string): void;
  playlistSuffix?: string;
};

export function PlaylistNameDialog(props: PlaylistNameDialogProps): JSX.Element {
  const { t } = useTranslation();

  const [playlistName, setPlaylistName] = useState<string>(
    `Unchartify: ${props.playlistSuffix ?? ''}`
  );
  const [nameValid, setNameValid] = useState<boolean>(true);

  const updatePlaylistName = (name: string) => {
    setPlaylistName(name.trim());
    setNameValid(name.trim().length > 2);
  };

  function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter' && nameValid) {
      props.playlistNamedHandler(playlistName);
    }
  }

  return (
    <Popup
      open={true}
      onClose={() => props.playlistNamedHandler()}
      position="center center"
      className="playlist-name-dialog"
      closeOnDocumentClick
    >
      <span className={'playlist-name-dialog-caption'}>
        {t('PlaylistNameDialog.Caption')}
      </span>
      <input
        defaultValue={playlistName}
        maxLength={100}
        autoFocus={true}
        onKeyPress={handleKeyPress}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          updatePlaylistName(e.target.value)
        }
      />
      <button
        type={'submit'}
        className={!nameValid ? 'disabled' : undefined}
        onClick={() => props.playlistNamedHandler(playlistName)}
      >
        {t('PlaylistNameDialog.Action')}
      </button>
    </Popup>
  );
}
