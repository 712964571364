import {configureStore} from '@reduxjs/toolkit';
import {storeReducer} from './store.reducer';
import {useDispatch} from 'react-redux';

const store = configureStore({
  reducer: storeReducer,
});

export type IStore = ReturnType<typeof storeReducer>;
export type IDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<IDispatch>();

export default store;
