import {useContext, useState} from 'react';
import SpotifyApiContext from '../../spotify/spotifyApiContext';
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';
import {useAppDispatch} from '../../redux/store';
import {albumsStoreSlice} from '../../redux/albums/albums.slice';

function useSaveAlbum(): [(id: string) => Promise<void>, boolean] {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const api = useContext(SpotifyApiContext);
  const dispatch = useAppDispatch();
  const saveAlbum = (albumId: string) => {
    setIsLoading(true);

    return api
      .saveAlbumsToLibrary([albumId])
      .then(() => {
        dispatch(albumsStoreSlice.actions.addSavedAlbumIds([albumId]));

        toast(t('AlbumMenu.SaveToast'), {
          type: 'success',
          position: 'top-center'
        });
      })
      .catch(() => {
        toast(t('AlbumMenu.SaveToastErr'), {
          type: 'error',
          position: 'top-center'
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return [saveAlbum, isLoading];
}

export default useSaveAlbum;
