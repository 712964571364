export const resources = {
  en: {
    translation: {
      'lang.en': 'English',
      'lang.ru': 'Russian',
      'Switch language': 'Switch language',

      'Open profile': 'Open profile',
      'Logout': 'Logout',

      'Uncharted': 'Uncharted',
      'Explore': 'Explore',
      'Artists': 'Artists',
      'Genres': 'Genres',
      'Albums': 'Albums',
      'Labels': 'Labels',
      'Bridge': 'Bridge',
      'Social': 'Socialize',

      'ScreenCaption.Bridge': 'Captain\'s bridge',
      'ScreenCaption.ExploreArtists': 'Explore artists',
      'ScreenCaption.ExploreGenres': 'Explore genres',
      'ScreenCaption.ExploreAlbums': 'Explore albums',
      'ScreenCaption.ExploreLabels': 'Explore labels',
      'ScreenCaption.Artist': 'Artist details',
      'ScreenCaption.Album': 'Album details',
      'ScreenCaption.CollectedTracks': 'Your mix',

      'Detecting devices...': 'Detecting devices...',
      'Launch Spotify anywhere in your network then ': 'Launch Spotify anywhere in your network then ',
      'detect app for instant play': 'detect app for instant play',
      'Click to detect Spotify app in your network': 'Click to detect Spotify app in your network',
      'Touch album covers to play albums on selected device': 'Touch album covers to play albums on selected device',
      'Playback device': 'Play',

      'Type artist name': 'Type artist name',
      'Followed only': 'Followed only',

      'Type artist or album name': 'Type artist or album name',
      'Saved only': 'Saved only',

      'Type genre name': 'Type genre name',
      'Show all': 'Show all',
      'browse.selected.1': 'Browse ',
      'browse.selected.2': ' selected',
      'browse.selected.3': 'genres',

      'GenreCategory.Recent': 'Recent genres',
      'GenreCategory.Recent.Desc': 'Genres you\'ve listen recently',
      'GenreCategory.Top': 'Top genres',
      'GenreCategory.Top.Desc': 'Your most listened genres',
      'GenreCategory.New': 'New genres',
      'GenreCategory.New.Desc': 'New genres added by Spotify',
      'GenreCategory.All': 'All genres',
      'GenreCategory.All.Desc': 'All {{count}} Spotify genres grouped alphabetically',
      'GenreMenu.CollectTracks': 'Add albums to mix',

      'Expand similar artists button': 'Expand similar artists button',
      'Collapse similar artists': 'Collapse similar artists',
      'Collapse similar artists button': 'Hide similar artists button',

      'Spotify icon': 'Spotify icon',
      'Loading...': 'Loading...',

      'Play album in Spotify app': 'Play album in Spotify app',
      'Open album page in Spotify app': 'Open album page in Spotify app',

      'Back to genre selection': 'Back to genre selection',
      'Back to genre selection button': 'Back to genre selection button',
      'Configure view options': 'Configure view options',
      'Configure view options button': 'Configure view options button',

      'Show albums': 'Show albums',
      'Show singles & EPs': 'Show singles & EPs',
      'Show compilations': 'Show compilations',
      'Select years range': 'Select years range',
      'From': 'From',
      'To': 'To',
      'Only exact matches': 'Only exact matches',
      'GenreExplorerBrowser.Remember': 'Remember preferences',
      'Apply': 'Apply',

      'Explore Genres': 'Explore Genres',

      'Artists.InitErr': 'An error occurred while obtaining the list of followed artists. Please, reload the page.',

      'ArtistMenu.FollowToast': 'You successfully followed the artist',
      'ArtistMenu.FollowToastErr': 'An error occurred while following the artist',
      'ArtistMenu.UnfollowToast': 'You successfully unfollowed the artist',
      'ArtistMenu.UnfollowToastErr': 'An error occurred while unfollowing the artist',

      'ArtistMenu.Explore': 'Explore',
      'ArtistMenu.Play': 'Play',

      'ArtistMenu.CollectTracks': 'Add to mix',
      'ArtistMenu.CollectTracks.Top': 'Top tracks',
      'ArtistMenu.CollectTracks.Disco': 'Albums & singles',
      'ArtistMenu.CollectTracks.Any': 'Any tracks',
      'ArtistMenu.CollectTracks.One': 'One',
      'ArtistMenu.CollectTracks.Few': 'Few',
      'ArtistMenu.CollectTracks.Many': 'Many',
      'ArtistMenu.CollectTracks.Maximum': 'Maximum',

      'ArtistMenu.Details': 'Details',
      'ArtistMenu.Follow': 'Follow',
      'ArtistMenu.Unfollow': 'Unfollow',
      'ArtistMenu.Open': 'Open',
      'ArtistMenu.OpenApp': 'Open in Spotify app',
      'ArtistMenu.OpenWeb': 'Open in Spotify web app',
      'ArtistMenu.FindInGoogle': 'Find in Google',
      'ArtistMenu.CopyToClipboard': 'Copy artist name',

      'Albums.InitErr': 'An error occurred while obtaining the list of saved albums. Please, reload the page.',
      'Playlists.InitErr': 'An error occurred while obtaining the list of your playlists. Please, reload the page.',

      'AlbumMenu.SaveToast': 'The album was saved to your library',
      'AlbumMenu.SaveToastErr': 'An error occurred while saving the album to your library',
      'AlbumMenu.RemoveToast': 'The album was removed from your library',
      'AlbumMenu.RemoveToastErr': 'An error occurred while removing the album from your library',

      'AlbumMenu.AlbumLabel': 'Album',
      'AlbumMenu.ExploreAlbum': 'Explore',
      'AlbumMenu.PlayAlbum': 'Play',
      'AlbumMenu.SaveAlbum': 'Save to library',
      'AlbumMenu.RemoveAlbum': 'Remove from library',
      'AlbumMenu.Open': 'Open',
      'AlbumMenu.OpenAlbum': 'Open in Spotify app',
      'AlbumMenu.OpenAlbumWeb': 'Open in Spotify web app',
      'AlbumMenu.OpenAlbumInSongLink': 'Open in other services',
      'AlbumMenu.FindInGoogle': 'Find in Google',
      'AlbumMenu.CopyName': 'Copy album name',
      'AlbumMenu.AlbumDetails': 'Details',
      'AlbumMenu.ArtistLabel': 'Artist',
      'AlbumMenu.ArtistLabel_plural': 'Artists',
      'AlbumMenu.ExploreArtist': 'Explore',
      'AlbumMenu.PlayArtist': 'Play',
      'AlbumMenu.ArtistDetails': 'Details',
      'AlbumMenu.FollowArtist': 'Follow',
      'AlbumMenu.UnfollowArtist': 'Unfollow',
      'AlbumMenu.OpenArtist': 'Open',
      'AlbumMenu.OpenArtistApp': 'Open in Spotify app',
      'AlbumMenu.OpenArtistWeb': 'Open in Spotify web app',

      'AlbumMenu.CollectTracks': 'Add to mix',
      'AlbumMenu.CollectTracks.One': 'One track',
      'AlbumMenu.CollectTracks.Few': 'Few tracks',
      'AlbumMenu.CollectTracks.Many': 'Many tracks',
      'AlbumMenu.CollectTracks.All': 'Entire album',

      'TrackMenu.TrackLabel': 'Track',
      'TrackMenu.PlayTrack': 'Play',
      'TrackMenu.CollectTrack': 'Add to mix',
      'TrackMenu.DropTrack': 'Remove from mix',
      'TrackMenu.Open': 'Open',
      'TrackMenu.OpenTrack': 'Open in Spotify app',
      'TrackMenu.OpenTrackWeb': 'Open in Spotify web app',
      'TrackMenu.OpenTrackInSongLink': 'Open in other services',
      'TrackMenu.FindInGoogle': 'Find in Google',
      'TrackMenu.CopyName': 'Copy track name',

      'SubscriberMenu.MoreFeatures': 'More features',
      'SubscriberMenu.MoreFeaturesDesc': 'Login with Spotify to activate',

      'PlaylistMenu.AddToPlaylist': 'Add to playlist',
      'PlaylistMenu.CreatePlaylist': 'Create new playlist',
      'PlaylistNameDialog.Caption': 'Playlist name',
      'PlaylistNameDialog.Action': 'Create playlist',
      'PlaylistDescription': 'Created with Unchartify recommendations discovery app (https://unchartify.com)',

      'TracksCollectorBadge.Open': 'Open your mix',
      'TracksCollectorBadge.PlaylistIcon': 'Your mix counter icon',
      'TracksCollector.CollectedTracks': 'Your mix',
      'TracksCollector.TotalCount': 'Tracks',
      'TracksCollector.TotalDuration': 'Duration',
      'TracksCollector.EmptyButton': 'Clear',
      'TracksCollector.CollectionFailed': 'Failed adding tracks to mix',
      'TracksCollector.CollectionSucceeded': '{{count}} track added to mix',
      'TracksCollector.CollectionSucceeded_plural': '{{count}} tracks added to mix',
      'TracksCollector.CollectButtonAlt': 'Add to mix button',
      'TracksCollector.AddToPlaylistFailed': 'Failed adding tracks to the playlist',
      'TracksCollector.AddToPlaylistSucceeded': 'Successfully added {{count}} track to the playlist',
      'TracksCollector.AddToPlaylistSucceeded_plural': 'Successfully added {{count}} tracks to the playlist',
      'TracksCollector.CreatePlaylistFailed': 'Failed creating new playlist',
      'TracksCollector.Zero.Title': 'Your mix is empty',
      'TracksCollector.Zero.Promo1': 'Mix artists, albums or genres exploration results',
      'TracksCollector.Zero.Promo2': '...or artists and albums individually',
      'TracksCollector.Zero.Subtitle': 'Accumulate found tracks here, to play \'em all or move to the Spotify playlist',

      'Tracks.SortOption.Added': 'In order of addition',
      'Tracks.SortOption.Artist': 'By artist A-Z',
      'Tracks.SortOption.Title': 'By title A-Z',
      'Tracks.SortOption.YearNew': 'From new to old',
      'Tracks.SortOption.YearOld': 'From old to new',
      'Tracks.SortOption.PopularityDesc': 'From popular to unpopular',
      'Tracks.SortOption.PopularityAsc': 'From unpopular to popular',
      'Tracks.SortOption.Random': 'In random order',

      'CabinMode.Recent': 'Recent',
      'CabinMode.Top': 'Top',
      'CabinMode.Recommended': 'Recommended',
      'CabinMode.ShortTerm': 'Month',
      'CabinMode.MediumTerm': 'Half a year',
      'CabinMode.LongTerm': 'All time',
      'CabinMode.Latest': 'Latest',
      'CabinMode.OpenMenu': 'Open menu button',

      'Bridge.Artists': 'Artists',
      'Bridge.Albums': 'Albums',
      'Bridge.Genres': 'Genres',
      'Bridge.Labels': 'Labels',
      'Bridge.Greeting1': 'Welcome to the captain\'s bridge',
      'Bridge.Greeting2': 'Where to sail today?',
      'Bridge.FindArtists': 'Find more artists',
      'Bridge.FindAlbums': 'Find more albums',
      'Bridge.FindGenres': 'Find more genres',
      'Bridge.FindLabels': 'Find more labels',

      'Footer.Copyright': '© 2021-24 Unchartify. This app doesn\'t have relations to Spotify AB or it’s partners in any way',

      'Players.Detect': 'Find Spotify devices',

      'Players.NotFound1': 'Unchartify plays music through official Spotify apps & devices',
      'Players.NotFound2': 'Nearby active Spotify apps or devices',
      'Players.NotFound3': 'not found',

      'Players.Rewind': 'Previous track',
      'Players.Forward': 'Next track',
      'Players.Play': 'Play',
      'Players.Pause': 'Pause',

      'Players.MenuButton': 'Menu open button icon',
      'Players.DeviceIcon': 'Spotify device icon',
      'Players.Mobile1': 'No playback device detected. Please launch Spotify app anywhere in your network then ',
      'Players.Mobile2': 'Click to detect Spotify app or device in your network',
      'Players.Mobile3': 'detect it',
      'Players.Mobile4': 'Login with Spotify',
      'Players.Mobile5': ' to play everything via any Spotify player or device in your network',

      'ArtistDetails.Albums': 'Albums',
      'ArtistDetails.Singles': 'Singles & EPs',
      'ArtistDetails.Compilations': 'Compilations',
      'ArtistDetails.AppearsOn': 'Appears on',
      'ArtistDetails.TopTracks': 'Top tracks',
      'ArtistDetails.SimilarArtists': 'Similar artists',
      'ArtistDetails.RecommendedArtists': 'Recommended artists',
      'ArtistDetails.Popularity': 'Popularity',
      'ArtistDetails.Followers': 'Followers',

      'AlbumDetails.Tracklist': 'Tracks',
      'AlbumDetails.SimilarAlbums': 'Similar albums',
      'AlbumDetails.Popularity': 'Popularity',
      'AlbumDetails.AlbumType.album': 'Album',
      'AlbumDetails.AlbumType.single': 'Single',
      'AlbumDetails.AlbumType.compilation': 'Compilation',
      'AlbumDetails.SongsCount': '{{count}} track',
      'AlbumDetails.SongsCount_plural': '{{count}} tracks',

      'AlbumsList.SortOption.Relevance': 'By relevance',
      'AlbumsList.SortOption.YearNew': 'From new to old',
      'AlbumsList.SortOption.YearOld': 'From old to new',
      'AlbumsList.SortOption.Artist': 'By artist A-Z',
      'AlbumsList.SortOption.Title': 'By title A-Z',

      'Landing.Logo': 'Unchartify logo',
      'Landing.Headline1': 'Sail the uncharted waters ',
      'Landing.Headline2': 'of Spotify ',
      'Landing.Headline3': 'music library',
      'Landing.Description': 'Spotify has a music library 10 miles deep, but its official experience is\u00a0built ' +
                'around automatic recommendations which tend to\u00a0keep you stuck in\u00a0a\u00a0bubble. And it\'s unfortunately ' +
                'easy to\u00a0get lost when trying to\u00a0explore manually. Unchartify is\u00a0the\u00a0breeze in your sails, helping ' +
                'you navigate through an\u00a0ocean of\u00a0new music to\u00a0find your next favorite track or artist.',
      'Landing.FeaturesButton': 'See app\'s amazing features!',
      'Landing.Captain': 'Captain image',
      'Landing.Login': 'Login with Spotify',
      'Landing.SkipLogin': 'Continue as guest',
      'Landing.ArtistsCaption': 'Artists\ndiscovery',
      'Landing.ArtistsDesc': 'Choose artist you follow or any other artist from Spotify library and spiral downwards ' +
                'similarity chain using interactive flowchart. ',
      'Landing.GenresCaption': 'Genres\ndiscovery',
      'Landing.GenresDesc': 'Discover latest and classic albums from more than 5900+ genres invented by Spotify ' +
                '"data alchemists". Beware: you\'ll be blown away by their creativity!',
      'Landing.AlbumsCaption': 'Albums\ndiscovery',
      'Landing.AlbumsDesc': 'Find endless stream of albums you\'ll love using interactive flowchart of similar albums. ' +
                'Build it from your favorite or any of the millions unknown ones.',
      'Landing.PlayCaption': 'Mix & play, instantly',
      'Landing.PlayDesc': 'Create 24h long playlists with a couple of clicks. Play everything you see on any Spotify player in your network ' +
                '(phone, desktop, devices).',
      'Landing.Back': 'Go back',
      'Landing.WhyLoginLink': '* Why to login?',
      'Landing.BridgeCaption': 'Captain\'s Bridge',
      'Landing.BridgeDesc': 'Total personalisation! Start your journey from your recent, top or recommended artists, albums, genres and labels.',
      'Landing.YourMixCaption': 'Spotify playlists',
      'Landing.YourMixDesc': 'Generate massive 24h long playlists with music you\'ll love, in a couple of clicks, from any context.',
      'Landing.LibraryCaption': 'Library management',
      'Landing.LibraryDesc': 'Follow artists, save albums, without leaving the app. Exploring your favourite music has never been easier.',
      'Landing.NowPlayingCaption': 'Now playing',
      'Landing.NowPlayingDesc': 'Monitor what your Spotify is playing and immediately jump into exploration of a new cool artist, album, genre or label.',

      'Logo.BackToBridge': 'Back to captain\'s bridge',

      'Reauthorize': 'Session expired. Please, <1>reauthorize</1>',

      'FriendlyDuration.Hours': '{{count}} hour',
      'FriendlyDuration.Hours_plural': '{{count}} hours',
      'FriendlyDuration.Minutes': '{{count}} minute',
      'FriendlyDuration.Minutes_plural': '{{count}} minutes',
      'FriendlyDuration.Seconds': '{{count}} second',
      'FriendlyDuration.Seconds_plural': '{{count}} seconds',
      'FriendlyDuration.H': '{{count}}h',
      'FriendlyDuration.M': '{{count}}m',
      'FriendlyDuration.S': '{{count}}s',

      'LabelsExplorer.SuggestPlaceholder': 'Type label name',
      'LabelCategory.Recent': 'Recent labels',
      'LabelCategory.Recent.Desc': 'Labels of albums you\'ve listened recently',
      'LabelCategory.Top': 'Top labels',
      'LabelCategory.Top.Desc': 'Labels of your most listened albums',
      'LabelCategory.Recommended': 'Recommended',
      'LabelCategory.Recommended.Desc': 'Labels of your recommended albums',
    }
  },
  ru: {
    translation: {
      'lang.en': 'Английский',
      'lang.ru': 'Русский',
      'Switch language': 'Сменить язык',

      'Open profile': 'Открыть профиль',
      'Logout': 'Выйти',

      'Uncharted': 'Uncharted',
      'Explore': 'Исследуй',
      'Artists': 'Исполнители',
      'Genres': 'Жанры',
      'Albums': 'Альбомы',
      'Labels': 'Лейблы',
      'Bridge': 'Мостик',
      'Social': 'Общайся',

      'ScreenCaption.Bridge': 'Мостик',
      'ScreenCaption.ExploreArtists': 'Исполнители',
      'ScreenCaption.ExploreGenres': 'Жанры',
      'ScreenCaption.ExploreAlbums': 'Альбомы',
      'ScreenCaption.ExploreLabels': 'Лейблы',
      'ScreenCaption.Artist': 'Исполнитель',
      'ScreenCaption.Album': 'Альбом',
      'ScreenCaption.CollectedTracks': 'Твой микс',

      'Detecting device...': 'Ищу проигрыватели...',
      'Launch Spotify anywhere in your network then ': 'Запусти любой Spotify проигрыватель в сети, а после ',
      'detect app for instant play': 'подключись к нему для проигрывания',
      'Click to detect Spotify app in your network': 'Нажми для поиска Spotify проигрывателей в сети',
      'Touch album covers to play albums on selected device': 'Нажимай на обложки альбомов для' +
                ' воспроизведения с помощью выбранного проигрывателя',
      'Playback device': 'Проигрывай',

      'Type artist name': 'Введи имя исполнителя',
      'Followed only': 'Только любимые',

      'Type artist or album name': 'Введи имя исполнителя или альбома',
      'Saved only': 'Только любимые',

      'Type genre name': 'Введи название жанра',
      'Show all': 'Показать все',
      'browse.selected.1': 'Просмотри ',
      'browse.selected.2': ' выбранных',
      'browse.selected.3': 'жанров',

      'GenreCategory.Recent': 'Недавние жанры',
      'GenreCategory.Recent.Desc': 'То, что ты слушал в последнее время',
      'GenreCategory.Top': 'Лучшие жанры',
      'GenreCategory.Top.Desc': 'Твои любимые жанры за всё время',
      'GenreCategory.New': 'Новые жанры',
      'GenreCategory.New.Desc': 'Появившиеся в Spotify за последнее время',
      'GenreCategory.All': 'Все жанры',
      'GenreCategory.All.Desc': 'Алфавитный список всех {{count}} жанров Spotify',

      'GenreMenu.CollectTracks': 'Добавить альбомы в микс',

      'Show artist details': 'Посмотреть информацию об исполнителе',
      'Show artist details button': 'Кнопка \'Посмотреть информацию об исполнителе\'',
      'Expand similar artists': 'Показать похожих исполнителей',
      'Expand similar artists button': 'Кнопка \'Показать похожих исполнителей\'',
      'Collapse similar artists': 'Скрыть похожих исполнителей',
      'Collapse similar artists button': 'Кнопка \'Скрыть похожих исполнителей\'',

      'Play artist in Spotify app': 'Воспроизвести исполнителя в Spotify проигрывателе',
      'Open artist page in Spotify': 'Открыть страницу исполнителя в приложении Spotify',
      'Spotify icon': 'Иконка Spotify',
      'Loading...': 'Идёт загрузка...',
      'Singles & EPs': 'Синглы и EP',

      'Play album in Spotify app': 'Воспроизвести альбом в Spotify проигрывателе',
      'Open album page in Spotify app': 'Открыть страницу альбома в приложении Spotify',

      'Back to genre selection': 'Вернуться к выбору жанра',
      'Back to genre selection button': 'Кнопка \'Вернуться к выбору жанра\'',
      'Configure view options': 'Настроить опции отображения',
      'Configure view options button': 'Кнопка \'Настроить опции отображения\'',

      'Show albums': 'Показывать альбомы',
      'Show singles & EPs': 'Показывать синглы и EP',
      'Show compilations': 'Показывать сборники',
      'Select years range': 'Выбрать период',
      'From': 'От',
      'To': 'До',
      'Only exact matches': 'Полные совпадения',
      'GenreExplorerBrowser.Remember': 'Запомнить выбор',
      'Apply': 'Применить',

      'Explore Genres': 'Жанры',

      'Artists.InitErr': 'Ошибка при получении списка исполнителей, на которых ты подписан. Пожалуйста, перезагрузи страницу.',
      'Playlists.InitErr': 'Ошибка при получении списка твоих плейлистов. Пожалуйста, перезагрузи страницу.',
      'Albums.InitErr': 'Ошибка при получении списка сохранённых альбомов. Пожалуйста, перезагрузи страницу.',

      'ArtistMenu.FollowToast': 'Ты успешно подписался на исполнителя',
      'ArtistMenu.FollowToastErr': 'Ошибка при подписке на исполнителя',
      'ArtistMenu.UnfollowToast': 'Ты успешно отписался от исполнителя',
      'ArtistMenu.UnfollowToastErr': 'Ошибка при отписке от исполнителя',

      'ArtistMenu.Explore': 'Исследовать',
      'ArtistMenu.Play': 'Воспроизвести',

      'ArtistMenu.CollectTracks': 'Добавить в микс',
      'ArtistMenu.CollectTracks.Top': 'Популярные треки',
      'ArtistMenu.CollectTracks.Disco': 'Альбомы и синглы',
      'ArtistMenu.CollectTracks.Any': 'Любые треки',
      'ArtistMenu.CollectTracks.One': 'Один',
      'ArtistMenu.CollectTracks.Few': 'Несколько',
      'ArtistMenu.CollectTracks.Many': 'Много',
      'ArtistMenu.CollectTracks.Maximum': 'Максимум',

      'ArtistMenu.Details': 'Показать детали',
      'ArtistMenu.Follow': 'Подписаться',
      'ArtistMenu.Unfollow': 'Отписаться',
      'ArtistMenu.Open': 'Открыть',
      'ArtistMenu.OpenApp': 'Открыть в Spotify',
      'ArtistMenu.OpenWeb': 'Открыть в Spotify Web',
      'ArtistMenu.FindInGoogle': 'Найти в Google',
      'ArtistMenu.CopyToClipboard': 'Скопировать имя исполнителя',

      'AlbumMenu.SaveToast': 'Альбом сохранён в фонотеку',
      'AlbumMenu.SaveToastErr': 'Ошибка при сохранении альбома в фонотеку',
      'AlbumMenu.RemoveToast': 'Альбом удалён из фонотеки',
      'AlbumMenu.RemoveToastErr': 'Ошибка при удалении альбома из фонотеки',

      'AlbumMenu.AlbumLabel': 'Альбом',
      'AlbumMenu.ExploreAlbum': 'Исследовать',
      'AlbumMenu.PlayAlbum': 'Воспроизвести',
      'AlbumMenu.SaveAlbum': 'Сохранить в фонотеку',
      'AlbumMenu.RemoveAlbum': 'Удалить из фонотеки',
      'AlbumMenu.Open': 'Открыть',
      'AlbumMenu.OpenAlbum': 'Открыть в Spotify',
      'AlbumMenu.OpenAlbumWeb': 'Открыть в Spotify Web',
      'AlbumMenu.OpenAlbumInSongLink': 'Открыть в других сервисах',
      'AlbumMenu.FindInGoogle': 'Найти в Google',
      'AlbumMenu.CopyName': 'Скопировать название альбома',
      'AlbumMenu.AlbumDetails': 'Показать детали',
      'AlbumMenu.ArtistLabel': 'Исполнитель',
      'AlbumMenu.ArtistLabel_plural': 'Исполнители',
      'AlbumMenu.ExploreArtist': 'Исследовать',
      'AlbumMenu.PlayArtist': 'Воспроизвести',
      'AlbumMenu.ArtistDetails': 'Показать детали',
      'AlbumMenu.FollowArtist': 'Подписаться',
      'AlbumMenu.UnfollowArtist': 'Отписаться',
      'AlbumMenu.OpenArtist': 'Открыть',
      'AlbumMenu.OpenArtistApp': 'Открыть в Spotify',
      'AlbumMenu.OpenArtistWeb': 'Открыть в Spotify Web',
      'AlbumMenu.FindArtistYM': 'Найти в Яндекс.Музыке',

      'AlbumMenu.CollectTracks': 'Добавить в микс',
      'AlbumMenu.CollectTracks.One': 'Один трек',
      'AlbumMenu.CollectTracks.Few': 'Несколько треков',
      'AlbumMenu.CollectTracks.Many': 'Много треков',
      'AlbumMenu.CollectTracks.All': 'Альбом целиком',

      'TrackMenu.TrackLabel': 'Трек',
      'TrackMenu.PlayTrack': 'Воспроизвести',
      'TrackMenu.CollectTrack': 'Добавить в микс',
      'TrackMenu.DropTrack': 'Убрать из микса',
      'TrackMenu.Open': 'Открыть',
      'TrackMenu.OpenTrack': 'Открыть в Spotify',
      'TrackMenu.OpenTrackWeb': 'Открыть в Spotify Web',
      'TrackMenu.OpenTrackInSongLink': 'Открыть в других сервисах',
      'TrackMenu.FindInGoogle': 'Найти в Google',
      'TrackMenu.CopyName': 'Скопировать название трека',

      'SubscriberMenu.MoreFeatures': 'Больше возможностей',
      'SubscriberMenu.MoreFeaturesDesc': 'Войди в Spotify для включения',

      'PlaylistMenu.AddToPlaylist': 'Перенести в плейлист',
      'PlaylistMenu.CreatePlaylist': 'Создать новый плейлист',
      'PlaylistNameDialog.Caption': 'Название плейлиста',
      'PlaylistNameDialog.Action': 'Создать плейлист',
      'PlaylistDescription': 'Плейлист создан с помощью Unchartify (https://unchartify.com), приложения для эффективного поиска новых музыкальных открытий',

      'TracksCollectorBadge.Open': 'Открыть микс',
      'TracksCollectorBadge.PlaylistIcon': 'Иконка счётчика треков в миксе',
      'TracksCollector.CollectedTracks': 'Твой микс',
      'TracksCollector.TotalCount': 'Треков',
      'TracksCollector.TotalDuration': 'Длительность',
      'TracksCollector.EmptyButton': 'Очистить',
      'TracksCollector.CollectionFailed': 'Не удалось собрать треки',
      'TracksCollector.CollectionSucceeded_0': '{{count}} трек добавлен в микс',
      'TracksCollector.CollectionSucceeded_1': '{{count}} трека добавлено в микс',
      'TracksCollector.CollectionSucceeded_2': '{{count}} треков добавлено в микс',
      'TracksCollector.CollectButtonAlt': 'Кнопка добавления в микс',
      'TracksCollector.AddToPlaylistFailed': 'Не удалось добавить треки в плейлист',
      'TracksCollector.AddToPlaylistSucceeded_0': '{{count}} трек успешно добавлен в плейлист',
      'TracksCollector.AddToPlaylistSucceeded_1': '{{count}} трека успешно добавлено в плейлист',
      'TracksCollector.AddToPlaylistSucceeded_2': '{{count}} треков успешно добавлено в плейлист',
      'TracksCollector.CreatePlaylistFailed': 'Не удалось создать новый плейлист',
      'TracksCollector.Zero.Title': 'Твой микс пуст',
      'TracksCollector.Zero.Subtitle': 'Накапливай треки, чтобы послушать их все или создать из них Spotify плейлист',
      'TracksCollector.Zero.Promo1': 'Добавляй в микс результаты исследования исполнителей и альбомов',
      'TracksCollector.Zero.Promo2': '...или исполнителей и альбомы по отдельности',

      'Tracks.SortOption.Added': 'В порядке добавления',
      'Tracks.SortOption.Artist': 'По исполнителю А-Я',
      'Tracks.SortOption.Title': 'По названию А-Я',
      'Tracks.SortOption.YearNew': 'Сначала новые',
      'Tracks.SortOption.YearOld': 'Сначала старые',
      'Tracks.SortOption.PopularityDesc': 'Сначала популярные',
      'Tracks.SortOption.PopularityAsc': 'Сначала непопулярные',
      'Tracks.SortOption.Random': 'В произвольном порядке',

      'CabinMode.Recent': 'Недавние',
      'CabinMode.Recommended': 'Рекомендованные',
      'CabinMode.Top': 'Лучшие',
      'CabinMode.ShortTerm': 'За месяц',
      'CabinMode.MediumTerm': 'За полгода',
      'CabinMode.LongTerm': 'За всё время',
      'CabinMode.Latest': 'Последние',
      'CabinMode.OpenMenu': 'Кнопка открытия меню',

      'Bridge.Artists': 'Исполнители',
      'Bridge.Albums': 'Альбомы',
      'Bridge.Genres': 'Жанры',
      'Bridge.Labels': 'Лейблы',
      'Bridge.Greeting1': 'Добро пожаловать на капитанский мостик',
      'Bridge.Greeting2': 'Куда идём сегодня?',
      'Bridge.FindArtists': 'Больше',
      'Bridge.FindAlbums': 'Больше альбомов',
      'Bridge.FindGenres': 'Больше жанров',
      'Bridge.FindLabels': 'Больше лейблов',

      'Footer.Copyright': '© 2021-24 Unchartify. Это приложение никак не связано с компанией Spotify AB или её партнёрами',

      'Players.Detect': 'Найти проигрыватели и устройства',

      'Players.NotFound1': 'Unchartify проигрывает музыку через приложения и устройства Spotify',
      'Players.NotFound2': 'Активные приложения и устройства в вашей сети',
      'Players.NotFound3': 'не найдены',

      'Players.Rewind': 'Предыдущий трек',
      'Players.Forward': 'Следующий трек',
      'Players.Play': 'Воспроизвести',
      'Players.Pause': 'Поставить на паузу',

      'Players.MenuButton': 'Иконка кнопки открытия меню',
      'Players.DeviceIcon': 'Иконка Spotify устройства',
      'Players.Mobile1': 'Проигрыватели не обнаружены. Пожалуйста, запустите любой Spotify проигрыватель в вашей сети, после чего ',
      'Players.Mobile2': 'Нажмите для поиска проигрывателей',
      'Players.Mobile3': 'обнаружьте его',
      'Players.Mobile4': 'Войдите в свой аккаунт Spotify',
      'Players.Mobile5': ', чтобы проигрывать музыку на любом Spotify-проигрывателе в сети',

      'ArtistDetails.Albums': 'Альбомы',
      'ArtistDetails.Singles': 'Синглы и EP',
      'ArtistDetails.Compilations': 'Компиляции',
      'ArtistDetails.AppearsOn': 'Ещё с исполнителем',
      'ArtistDetails.TopTracks': 'Популярные треки',
      'ArtistDetails.SimilarArtists': 'Похожие исполнители',
      'ArtistDetails.RecommendedArtists': 'Рекомендованные исполнители',
      'ArtistDetails.Popularity': 'Популярность',
      'ArtistDetails.Followers': 'Подписчики',

      'AlbumDetails.Popularity': 'Популярность',
      'AlbumDetails.Tracklist': 'Треклист',
      'AlbumDetails.SimilarAlbums': 'Похожие альбомы',
      'AlbumDetails.AlbumType.album': 'Альбом',
      'AlbumDetails.AlbumType.single': 'Сингл',
      'AlbumDetails.AlbumType.compilation': 'Сборник',
      'AlbumDetails.SongsCount_0': '{{count}} трек',
      'AlbumDetails.SongsCount_1': '{{count}} трека',
      'AlbumDetails.SongsCount_2': '{{count}} треков',

      'AlbumsList.SortOption.Relevance': 'По релевантности',
      'AlbumsList.SortOption.YearNew': 'От новых к старым',
      'AlbumsList.SortOption.YearOld': 'От старых к новым',
      'AlbumsList.SortOption.Artist': 'По исполнителю А-Я',
      'AlbumsList.SortOption.Title': 'По названию А-Я',

      'Landing.Logo': 'Логотип Unchartify',
      'Landing.Headline1': 'Исследуй неизведанные',
      'Landing.Headline2': 'океаны музыки в Spotify',
      'Landing.Description': 'Spotify славится своей бескрайней музыкальной библиотекой, ' +
                'однако фокусируется на автоматических рекомендациях, рано или поздно изолирующих тебя в пузыре. ' +
                'Выбраться из него непросто, а потеряться, пытаясь найти что-то ценное вручную, очень легко. ' +
                'Unchartify проведёт тебя по океану музыки, которую невозможно не полюбить.',
      'Landing.FeaturesButton': 'Посмотри, что умеет Unchartify!',
      'Landing.Captain': 'Изображение капитана',
      'Landing.Login': 'Войти через Spotify',
      'Landing.SkipLogin': 'Войти как гость',
      'Landing.ArtistsCaption': 'Исследование\nисполнителей',
      'Landing.ArtistsDesc': 'Выбери любимого или любого другого исполнителя из библиотеки Spotify ' +
                'и изучай похожих исполнителей на интерактивной диаграмме. Подписывайся на понравившихся.',
      'Landing.GenresCaption': 'Исследование\nжанров',
      'Landing.GenresDesc': 'Открой для себя свежие и классические альбомы в более чем 5900 жанров, изобретённых ' +
                '"дата-алхимиками" Spotify. Берегись, ты будешь ослеплён их креативностью!',
      'Landing.AlbumsCaption': 'Исследование\nальбомов',
      'Landing.AlbumsDesc': 'Погрузись в бесконечный поток классных альбомов с помощью интерактивной диаграммы похожих альбомов. ' +
                'Начни исследование с любимого или любого другого из миллионов альбомов библиотеки Spotify.',
      'Landing.PlayCaption': 'Миксуй и слушай,\nпрямо сейчас',
      'Landing.PlayDesc': 'Создавай 24-часовые миксы крутых треков в пару кликов. Прослушивай в один клик всё, что видишь на экране – ' +
                'на любом Spotify-проигрывателе в сети (телефон, компьютер, медиа-устройства).',
      'Landing.Back': 'Вернуться',
      'Landing.WhyLoginLink': '* Зачем входить?',
      'Landing.BridgeCaption': 'Капитанский мостик',
      'Landing.BridgeDesc': 'Тотальная персонализация! Начни своё путешествие с отправной точки: недавно прослушанных, топовых или рекомендованных исполнителей, альбомов, жанров и лейблов.',
      'Landing.YourMixCaption': 'Создание Spotify плейлистов',
      'Landing.YourMixDesc': 'Создавай многочасовые Spotify плейлисты с музыкой, которую обязательно полюбишь: в пару кликов, с любого экрана.',
      'Landing.LibraryCaption': 'Управление фонотекой',
      'Landing.LibraryDesc': 'Добавляй интересные находки в свою фонотеку, не покидая приложения. Твои любимые исполнители и альбомы всегда на ладони - чтобы начать новое исследование!',
      'Landing.NowPlayingCaption': 'Будь в контексте,\nиспользуй момент',
      'Landing.NowPlayingDesc': 'Твой Spotify играет классный, неизвестный тебе трек? Исследуй его исполнителя, альбом, жанр или лейбл — незамедлительно с помощью функции "Играет сейчас".',

      'Logo.BackToBridge': 'Вернуться на капитанский мостик',

      'Reauthorize': 'Сессия завершилась. Пожалуйста, <1>авторизуйся повторно</1>',

      'FriendlyDuration.Hours_0': '{{count}} час',
      'FriendlyDuration.Hours_1': '{{count}} часа',
      'FriendlyDuration.Hours_2': '{{count}} часов',
      'FriendlyDuration.Minutes_0': '{{count}} минута',
      'FriendlyDuration.Minutes_1': '{{count}} минуты',
      'FriendlyDuration.Minutes_2': '{{count}} минут',
      'FriendlyDuration.Seconds_0': '{{count}} секунда',
      'FriendlyDuration.Seconds_1': '{{count}} секунды',
      'FriendlyDuration.Seconds_2': '{{count}} секунд',
      'FriendlyDuration.H': '{{count}}ч',
      'FriendlyDuration.M': '{{count}}м',
      'FriendlyDuration.S': '{{count}}с',

      'LabelsExplorer.SuggestPlaceholder': 'Введи имя лейбла',
      'LabelCategory.Recent': 'Недавние лейблы',
      'LabelCategory.Recent.Desc': 'Лейблы недавно прослушанных альбомов',
      'LabelCategory.Top': 'Лучшие лейблы',
      'LabelCategory.Top.Desc': 'Лейблы твоих любимых альбомов',
      'LabelCategory.Recommended': 'Рекомендованные',
      'LabelCategory.Recommended.Desc': 'Лейблы твоих рекомендованных альбомов',
    }
  }
};
