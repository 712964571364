import React, {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {MenuDivider, MenuItem, SubMenu} from '@szhsin/react-menu';
import copy from 'copy-to-clipboard';

import SpotifyAlbum from '../../spotify/spotifyAlbum';

import {isAlbumSavedSelector} from '../../redux/albums/albums.selector';
import useSaveAlbum from '../../hooks/albums/useSaveAlbum';
import useRemoveAlbum from '../../hooks/albums/useRemoveAlbum';
import useCollectAlbumTracks, {AlbumTracksCount} from '../../hooks/albums/useCollectAlbumTracks';
import useAlbumMixMenuItems from '../album-mix-menu-items/useAlbumMixMenuItems';
import usePlayAlbum from '../../hooks/player/usePlayAlbum';
import {permissionsSelector} from '../../redux/permissions/permissions.selector';

export default function useAlbumMenuItems(album: SpotifyAlbum): JSX.Element[] {

  const {t} = useTranslation();
  const history = useHistory();
  const isAlbumSaved = useSelector(isAlbumSavedSelector(album.id));
  const permissions = useSelector(permissionsSelector);

  const [saveAlbum] = useSaveAlbum();
  const [removeAlbum] = useRemoveAlbum();
  const collectAlbumTracks = useCollectAlbumTracks();
  const playAlbum = usePlayAlbum();

  const exploreAlbum = () =>
    history.push(`/albums/${album.id}`);

  const handlePlayAlbum = useCallback(() => album && playAlbum(album), [album, playAlbum]);

  const collectAlbumTopTracks = (count: AlbumTracksCount) => {
    collectAlbumTracks([album], count, '').then();
  };

  const handleAlbumSave = () => {
    saveAlbum(album.id).then();
  };

  const handleAlbumRemove = () => {
    removeAlbum(album.id).then();
  };

  const openAlbumInSpotifyApp = () => {
    window.open(album.uri, '_blank');
  };

  const openAlbumInSpotifyWebApp = () => {
    window.open(album.url, '_blank');
  };

  const openAlbumInSongLink = () => {
    window.open(`https://album.link/s/${album.id}`, '_blank');
  };

  const openAlbumDetails = () => {
    history.push(`/album/${album.id}`);
  };

  const getSearchString = () => `${album.artists.map(a => a.name).join(', ')} "${album.name}"`;

  const copyAlbumNameToClipboard = () => copy(getSearchString());

  const findInGoogle = () => {
    window.open(`https://www.google.com/search?q=${encodeURIComponent(getSearchString())}"`, '_blank');
  };

  const hasArtists = album.artists.filter(a => a.name.toLowerCase() !== 'various artists').length !== 0;

  return [
    <MenuItem key='almmi1' onClick={handlePlayAlbum}
      disabled={!permissions.canPlayNow}>{t('AlbumMenu.PlayAlbum')}</MenuItem>,
    <SubMenu key='almsm1' label={t('AlbumMenu.CollectTracks')} disabled={!permissions.canCollectTracks}>
      {useAlbumMixMenuItems(collectAlbumTopTracks)}
    </SubMenu>,
    ...(hasArtists) ? [<MenuItem key='almmi7' onClick={exploreAlbum}>{t('AlbumMenu.ExploreAlbum')}</MenuItem>] : [],
    <MenuItem key='almmi2' onClick={openAlbumDetails}>{t('AlbumMenu.AlbumDetails')}</MenuItem>,
    ...(!isAlbumSaved)
      ? [<MenuItem key='almmi3' onClick={handleAlbumSave}
        disabled={!permissions.canManageLibrary}>{t('AlbumMenu.SaveAlbum')}</MenuItem>]
      : [<MenuItem key='almmi4' onClick={handleAlbumRemove}
        disabled={!permissions.canManageLibrary}>{t('AlbumMenu.RemoveAlbum')}</MenuItem>],
    <SubMenu key='almsm2' label={t('AlbumMenu.Open')}>
      <MenuItem key='almmi5' onClick={openAlbumInSpotifyApp}>{t('AlbumMenu.OpenAlbum')}</MenuItem>,
      <MenuItem key='almmi6' onClick={openAlbumInSpotifyWebApp}>{t('AlbumMenu.OpenAlbumWeb')}</MenuItem>
      <MenuItem key='almmi8' onClick={openAlbumInSongLink}>{t('AlbumMenu.OpenAlbumInSongLink')}</MenuItem>
      <MenuDivider/>
      <MenuItem key='almmi9' onClick={findInGoogle}>{t('AlbumMenu.FindInGoogle')}</MenuItem>
      <MenuItem key='almmi10' onClick={copyAlbumNameToClipboard}>{t('AlbumMenu.CopyName')}</MenuItem>
    </SubMenu>
  ];
}

