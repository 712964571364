import {IPermissionsStore} from './permissions.interface';

export const permissionsStoreInitialState: IPermissionsStore = {
  isSubscriber: false,
  canViewProfile: false,
  canPlay: false,
  canPlayNow: false,
  canViewNowPlaying: false,
  canCollectTracks: false,
  hasLibrary: false,
  canManageLibrary: false,
  canOpenSpotifyLinks: false,
  hasPersonalization: false,
  canManagePlaylists: false
};
