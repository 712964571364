import React, {useContext, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useAppDispatch} from '../redux/store';
import {useRouteMatch} from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';

import Api from '../spotify/api';
import {AlbumSelectionLoader} from '../album-selection-loader/albumSelectionLoader';
import SpotifyApiContext from '../spotify/spotifyApiContext';
import SpotifyAlbum from '../spotify/spotifyAlbum';

import {AlbumTile} from '../album-tile/albumTile';
import {activateGenericBackground, deactivateGenericBackground} from '../util/background';
import useCollectAlbumTracks, {AlbumTracksCount} from '../hooks/albums/useCollectAlbumTracks';
import {genresExplorerSettingsSelector} from '../redux/gui-settings/guiSettings.selector';
import {guiSettingsStoreSlice} from '../redux/gui-settings/guiSettings.slice';
import AlbumSelectionOptions, {AlbumSelectionOptionValues} from '../album-selection-options/albumSelectionOptions';
import {persistGuiSettings} from '../redux/gui-settings/guiSettings.actions';
import CollectButton from '../album-selection-collect-button/collectButton';

import {getYYYYFromReleaseDate} from '../util/getYearFromReleaseDate';
import {toTitleCase} from '../util/titleCase';

import './genreExplorer.css';
import './genreExplorerMobile.css';

interface GenreExplorerProps {
    isLargeScreenMode?: boolean;
}

export const GenreExplorer: (props: GenreExplorerProps) => JSX.Element = (props: GenreExplorerProps) => {

  const spotifyApi = useContext(SpotifyApiContext);

  const albumsLoader: AlbumSelectionLoader = useMemo(() => {
    return new AlbumSelectionLoader(
      spotifyApi,

      (genre: string) => `genre:"${genre}"`,

      async (query: string, limit: number, offset: number) => {
        const response = await spotifyApi.searchTracks(query, limit, offset);
        return [response?.items.map(t =>
          Api.getSpotifyAlbum(t.album as SpotifyApi.AlbumObjectFull)) ?? [], !!response?.next];
      }
    );
  }, [spotifyApi]);

  const defaultOptions = useSelector(genresExplorerSettingsSelector);
  const [albums, setAlbums] = useState<SpotifyAlbum[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [genres, setGenres] = useState<string[]>([]);
  const [options, setOptions] = useState<AlbumSelectionOptionValues>(defaultOptions.options);
  const hasAlbums = albums.length !== 0;

  const dispatch = useAppDispatch();

  const optionsRoute = useRouteMatch('/genres/:genre/:options?');
  let routeParams = {genre: '', options: ''};
  if (optionsRoute) {
    routeParams = optionsRoute.params as {genre: string, options: string};
  }

  const collectAlbumsTracks = useCollectAlbumTracks();

  const loadAlbums = async (initial = false) => {
    if ((initial || loading) && !albumsLoader.isLoading()) {
      const nextAlbums = await albumsLoader.nextAlbums();
      if (!albumsLoader.hasMoreAlbums()) {
        setLoading(false);
      }
      const currentAlbums = initial ? [] : albums;
      setAlbums([...currentAlbums, ...nextAlbums]);
    }
  };

  useEffect(() => {
    activateGenericBackground();
    return () => deactivateGenericBackground();
  }, []);

  useEffect(() => {
    const genres = toTitleCase(routeParams.genre).split(',')
      .map(g => g.replace('Uk ', 'UK '));

    setGenres(genres);
    setAlbums([]);
    setLoading(true);

    albumsLoader.init(
      genres,
      options.includeAlbums,
      options.includeSingles,
      options.includeVA,
      options.years.from,
      options.years.to
    );
    loadAlbums(true).then();
  }, [albumsLoader, routeParams.genre, options]);

  const applyOptions = (options: AlbumSelectionOptionValues) => {
    dispatch(guiSettingsStoreSlice.actions.setGenreExplorerState({options: options}));
    dispatch(persistGuiSettings());
    setOptions(options);
  };

  const collectTracks = async (count: AlbumTracksCount) => {
    setLoading(true);
    await collectAlbumsTracks(albums, count, genres[0] + ((genres.length !== 1) ? '+' : ''));
    setLoading(false);
  };

  let currentYear = '';
  const albumBlocks: JSX.Element[] = [];
  for (const album of albums) {
    const year = getYYYYFromReleaseDate(album.releaseDate);
    if (year !== currentYear) {
      const genresLabelAddon = (genres.length > 3) ? ' +' + (genres.length - 3) : '';
      albumBlocks.push(<div key={year} className="year-label">{genres.slice(0, 2).join(', ')}{genresLabelAddon}
        <strong> in {year}</strong></div>);
      currentYear = year;
    }
    albumBlocks.push(<AlbumTile album={album} key={album.id} displayArtist={true}/>);
  }

  const loader = loading ? <div className="spinner"/> : null;

  return <div className="genre-explorer" style={{backgroundImage: 'url(seabg.png)'}}>
    <div className={`album-selection-options ${props.isLargeScreenMode ? 'album-selection-options-large-screen' : ''}`}>
      <AlbumSelectionOptions options={options} onApply={applyOptions}/>
      {hasAlbums && <CollectButton onClick={collectTracks} />}
    </div>

    <InfiniteScroll
      dataLength={albums.length}
      next={loadAlbums}
      hasMore={true}
      loader={loader}
      className="scroll-container">

      {albumBlocks}
    </InfiniteScroll>
  </div>;
};
