import React from 'react';
import {Menu} from '@szhsin/react-menu';
import {useTranslation} from 'react-i18next';

import useArtistMixMenuItems from '../../menus/artist-mix-menu-items/useArtistMixMenuItems';
import {ArtistTracksCount} from '../../hooks/artists/artistTracksCollectionEnums';

import './artistMixMenuButton.css';

type ArtistMixMenuButtonProps = {
    numberOfTracksSelected(count: ArtistTracksCount): void,
    disabled?: boolean
}

export default function ArtistMixMenuButton(props: ArtistMixMenuButtonProps): JSX.Element {
  const {t} = useTranslation();
  return <>
    <Menu
      className="artist-mix-menu"
      portal={true}
      arrow={true}
      align="start"
      menuButton={<div className="artist-mix-menu-button">
        <img src={'playlist.svg'} alt={t('ArtistMenu.CollectTracks')}/>
        <span>{t('ArtistMenu.CollectTracks')}</span>
      </div>}
      theming="dark">
      {useArtistMixMenuItems(props.numberOfTracksSelected)}
    </Menu>
  </>;
}
