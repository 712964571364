import {isMobile} from 'react-device-detect';

const mobileClass = isMobile ? 'mobile ' : '';

export const activateGenericBackground: (url?: string) => void = (url?: string) => {
  document.body.style.backgroundImage = 'url(' + (url ?? 'seabg.png') + ')';
  document.body.className = mobileClass + (url ? 'shadow-bg' : '');
};

export const deactivateGenericBackground: () => void = () => {
  document.body.style.backgroundImage = '';
  document.body.className = mobileClass;
};
