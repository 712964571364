import {useAppDispatch} from '../../redux/store';
import {yourMixStoreSlice} from '../../redux/your-mix/yourMix.slice';
import {persistYourMix} from '../../redux/your-mix/yourMix.actions';

function useEmptyYourMix(): () => void {
  const dispatch = useAppDispatch();
  return () => {
    dispatch(yourMixStoreSlice.actions.emptyCollector());
    dispatch(persistYourMix());
  };
}

export default useEmptyYourMix;
