import {useCallback} from 'react';
import {GUI_SETTINGS_STORAGE_KEY, guiSettingsStoreInitialState} from '../../redux/gui-settings/guiSettings.constants';
import {useAppDispatch} from '../../redux/store';
import {guiSettingsStoreSlice} from '../../redux/gui-settings/guiSettings.slice';
import {IGuiSettingsStore} from '../../redux/gui-settings/guiSettings.interface';

function useInitGuiSettings(): () => Promise<void> {
  const dispatch = useAppDispatch();

  return useCallback(() => {
    const guiSettingJson = localStorage.getItem(GUI_SETTINGS_STORAGE_KEY);
    const guiSettings = guiSettingJson
      ? JSON.parse(guiSettingJson) as IGuiSettingsStore
      : guiSettingsStoreInitialState;

    if (!guiSettings.genreExplorer) {
      guiSettings.genreExplorer = guiSettingsStoreInitialState.genreExplorer;
    }
    if (!guiSettings.labelExplorer) {
      guiSettings.labelExplorer = guiSettingsStoreInitialState.labelExplorer;
    }
    dispatch(guiSettingsStoreSlice.actions.setGuiSettingsState(guiSettings));
    return Promise.resolve();
  }, [dispatch]);
}

export default useInitGuiSettings;
