import {BridgeDataLoader, DataMode} from '../bridge/bridgeDataLoader';
import {ApiTimeRange} from '../spotify/api';
import i18next from 'i18next';

export type LabelsCategory = {
    name: string
    description: string
    icon: string
    groups: LabelsGroup[]
}

export type LabelsGroup = {
    name: string
    labels: string[]
}

export class LabelsManager {
  private bridgeDataLoader: BridgeDataLoader;

  constructor(bridgeDataLoader: BridgeDataLoader) {
    this.bridgeDataLoader = bridgeDataLoader;
  }

  async getCategories(): Promise<LabelsCategory[]> {

    const recentLabels = await this.bridgeDataLoader.getLabels(DataMode.recent, ApiTimeRange.latest, 50);
    const topLabels = await this.bridgeDataLoader.getLabels(DataMode.top, ApiTimeRange.longTerm, 50);
    const recommendedLabels = await this.bridgeDataLoader.getLabels(DataMode.recommended, ApiTimeRange.latest, 50);

    const categories: LabelsCategory[] = [];
    categories.push(LabelsManager._createCategory(
      i18next.t('LabelCategory.Recent'),
      i18next.t('LabelCategory.Recent.Desc'),
      'genres01.svg',
      recentLabels
    ));
    categories.push(LabelsManager._createCategory(
      i18next.t('LabelCategory.Top'),
      i18next.t('LabelCategory.Top.Desc'),
      'genres04.svg',
      topLabels
    ));
    categories.push(LabelsManager._createCategory(
      i18next.t('LabelCategory.Recommended'),
      i18next.t('LabelCategory.Recommended.Desc'),
      'genres02.svg',
      recommendedLabels
    ));
    return categories;
  }

  private static _createCategory(name: string, description: string, icon: string, labels: string[]) {
    const groups: LabelsGroup[] = [];

    labels.sort((g1: string, g2: string) => g1.localeCompare(g2));
    groups.push({
      name: '',
      labels: labels.map(g => g[0].toUpperCase() + g.slice(1))
    });
    const category: LabelsCategory = {
      name: name,
      description: description,
      icon: icon,
      groups: groups
    };
    return category;
  }
}