import {useContext} from 'react';
import SpotifyTrack from '../../spotify/spotifyTrack';
import SpotifyApiContext from '../../spotify/spotifyApiContext';
import useUpdateNowPlaying from './useUpdateNowPlaying';

function usePlayTrack(): (track: SpotifyTrack) => void {
  const api = useContext(SpotifyApiContext);
  const updateNowPlaying = useUpdateNowPlaying();

  return async (track: SpotifyTrack) => {
    if (track && api.canPlay()) {
      api.playTrack(track).then(() => setTimeout(updateNowPlaying, 500));
    }
  };
}

export default usePlayTrack;
