import {useAppDispatch} from '../../redux/store';
import SpotifyTrack from '../../spotify/spotifyTrack';
import {yourMixStoreSlice} from '../../redux/your-mix/yourMix.slice';

function useCollectTrack(): [(track: SpotifyTrack) => void] {
  const dispatch = useAppDispatch();
  const collectTrack = (track: SpotifyTrack) => {
    dispatch(yourMixStoreSlice.actions.collectTrack(track));
  };
  return [collectTrack];
}

export default useCollectTrack;
