import React from 'react';
import {useTranslation} from 'react-i18next';
import {Menu, MenuButton} from '@szhsin/react-menu';
import useArtistMixMenuItems from '../../menus/artist-mix-menu-items/useArtistMixMenuItems';
import {ArtistTracksCollectionMode, ArtistTracksCount} from '../../hooks/artists/artistTracksCollectionEnums';

import '../../collect-button/collectButton.css';

export type CollectButtonProps = {
    onClick(count: ArtistTracksCount, mode: ArtistTracksCollectionMode) : void
}

const CollectButton: (props: CollectButtonProps) => JSX.Element = (props: CollectButtonProps) => {
  const {t} = useTranslation();

  return <div className="collect-button">
    <Menu
      className="artist-mix-menu"
      portal={true}
      arrow={true}
      align="start"
      menuButton={<MenuButton><img src={'playlist.svg'} alt={t('TracksCollector.CollectButtonAlt')}/></MenuButton>}
      theming="dark">
      {useArtistMixMenuItems(props.onClick, false, false)}
    </Menu>
  </div>;
};

export default CollectButton;
