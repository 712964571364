import React from 'react';
import SpotifyTrack from '../spotify/spotifyTrack';
import {TracklistEntry} from './tracklistEntry';
import {useSelector} from 'react-redux';
import {nowPlayingSelector} from '../redux/player/player.selector';

import './tracklist.css';

type TracklistProps = {
  tracks: readonly SpotifyTrack[],
  displayCoverArt?: boolean
}

export function Tracklist(props: TracklistProps): JSX.Element {
  const nowPlaying = useSelector(nowPlayingSelector);

  let number = 0;
  const trackBlocks = props.tracks.map(t => <TracklistEntry
    key={'t-' + t.id}
    track={t}
    number={++number}
    displayCoverArt={props.displayCoverArt}
    nowPlaying={(nowPlaying?.isPlaying ?? false) && nowPlaying?.track.id === t.id}
  />);
  return <div className="tracklist">
    {trackBlocks}
  </div>;
}