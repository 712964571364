import React from 'react';

import LabelGroup from './labelGroup';
import {LabelsCategory} from './labelsManager';

import './labelCategory.css';

type LabelCategoryProps = {
    category: LabelsCategory
    last: boolean
}

const LabelCategory: (props: LabelCategoryProps) => JSX.Element = (props: LabelCategoryProps) => {
  const category = props.category;

  const groups = category.groups.map(group =>
    <LabelGroup
      key={group.name}
      group={group}
      last={false}
    />);

  const categoryClasses = 'label-category' + (props.last ? ' last' : '');

  return <div className={categoryClasses}>
    <div className="label-category-info">
      <div className="label-category-label">
        <img className="label-category-icon" src={category.icon} alt=""/>
        <span className="label-category-name">{category.name}</span>
      </div>
      <div className="label-category-description">
        {category.description}
      </div>
    </div>
    <div className="label-category-genres">
      {groups}
    </div>
  </div>;
};

export default LabelCategory;
