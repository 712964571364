import React from 'react';
import './popularity.css';

type PopularityProps = {
    value: number
}

export function Popularity(props: PopularityProps): JSX.Element {
  const value = (props.value > 10) ? props.value : 10;

  return <div className="popularity-bar">
    <div className="popularity-value" style={{width: value + '%'}}>{props.value}</div>
  </div>;
}