import React from 'react';
import SpotifyAlbum from '../spotify/spotifyAlbum';
import {getYYFromReleaseDate} from '../util/getYearFromReleaseDate';
import AlbumMenu from '../menus/album-menu/albumMenu';

import './albumFlowchartNode.css';

type AlbumFlowchartNodeProps = {
    album: SpotifyAlbum
    expanded: boolean
    toggleHandler(): void
}

const AlbumFlowchartNode: (props: AlbumFlowchartNodeProps) => JSX.Element = (props: AlbumFlowchartNodeProps) => {
  const album = props.album;
  const nodeClass = props.expanded ? 'expanded' : 'collapsed';

  const handleMobileToggle = (event: React.TouchEvent): void => {
    event.stopPropagation();
    props.toggleHandler();
  };

  const toggle = (props.expanded)
    ? <span className="node-toggle collapse" onClick={props.toggleHandler} onTouchEnd={handleMobileToggle}
      title="Hide similar albums">
      <img src={'collapse.svg'}  alt="Hide similar albums button"/>
    </span>
    : <span className="node-toggle expand" onClick={props.toggleHandler} onTouchEnd={handleMobileToggle}
      title="Show similar albums">
      <img src={'expand.svg'}  alt="Show similar albums button"/>
    </span>;

  const albumImage = (album.images?.length ?? false)
    ? <div className="album-image" style={{backgroundImage: 'url(' + (album.images?.[0].url ?? '') + ')'}} />
    : <div className="album-image no-cover" />;

  const artists = album.artists.map(a => (a.name.toLowerCase() === 'various artists') ? 'VA' : a.name);

  return (
    <div className={'album-flowchart-node ' + nodeClass}>
      <AlbumMenu album={album}>
        <div className="album-tile">
          {albumImage}
          <div className="album-info">
            <span className="album-name">{album.name} &apos;{getYYFromReleaseDate(album.releaseDate)}</span>
            <span className="album-artist">{artists.join(', ')}</span>
          </div>
        </div>
      </AlbumMenu>
      {toggle}
    </div>
  );
};

export default AlbumFlowchartNode;
