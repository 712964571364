import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {playerStoreInitialState} from './player.constants';
import SpotifyNowPlaying from '../../spotify/spotifyNowPlaying';

export const playerStoreSlice = createSlice({
  name: 'player',
  initialState: playerStoreInitialState,
  reducers: {
    setNowPlaying(store, action: PayloadAction<SpotifyNowPlaying|null>) {
      if (store.nowPlaying?.track.id !== action.payload?.track.id || store.nowPlaying?.isPlaying !== action.payload?.isPlaying) {
        store.nowPlaying = action.payload;
      }
    }
  }
});
