import throttle from 'lodash/throttle';
import {useEffect, useMemo, useRef, useState} from 'react';

// Returns `true` if large mode is on (header and footer should not be shown)
export const useLargeScreenModeDetector: ({throttleDelay}?: { throttleDelay?: number }) => boolean = ({throttleDelay = 200}: {throttleDelay?: number} = {}) => {
  const [isLargeScreen, setIsLargeScreen] = useState(false);
  const prevY = useRef(window.scrollY);
  const scrollHandler = useMemo(() => throttle(() => {
    const {scrollHeight, offsetHeight} = document.documentElement;
    const isScrollAvailable = scrollHeight > offsetHeight;
    const scrolledHeight = window.scrollY + offsetHeight;
    const isScrolledToTop = window.scrollY <= 0;
    const isScrolledToBottom = scrolledHeight >= scrollHeight;
    const isScrolled = window.scrollY > prevY.current;

    setIsLargeScreen(isScrollAvailable && (isScrolledToBottom || (!isScrolledToTop && isScrolled)));
    prevY.current = window.scrollY;
  }, throttleDelay), [throttleDelay]);

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);

    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, []);
  return isLargeScreen;
};
