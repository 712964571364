import React, {useEffect, useState} from 'react';
import SpotifyAlbum from '../spotify/spotifyAlbum';
import {AlbumTile} from '../album-tile/albumTile';
import {AlbumsListSortingMenu, AlbumsListSortingOption} from './albumsListSortingMenu';
import AlbumMixTile from '../album-mix-tile/albumMixTile';
import {getYYYYFromReleaseDate} from '../util/getYearFromReleaseDate';

import './albumsList.css';

type AlbumsListProps = {
    albums: SpotifyAlbum[]
    displayArtist?: boolean
    displayYear?: boolean,
    sorting?: AlbumsListSortingOption,
    integratedSorting?: boolean,
    integratedAddToMix?: boolean,
    mixSuffix?: string
}

const sortByYearNew = (a1: SpotifyAlbum, a2: SpotifyAlbum) =>
  (getYYYYFromReleaseDate(a2.releaseDate) ?? 0).localeCompare(getYYYYFromReleaseDate(a1.releaseDate) ?? 0);

const sortByYearOld = (a1: SpotifyAlbum, a2: SpotifyAlbum) =>
  (getYYYYFromReleaseDate(a1.releaseDate) ?? 0).localeCompare(getYYYYFromReleaseDate(a2.releaseDate) ?? 0);

const sortByTitle = (a1: SpotifyAlbum, a2: SpotifyAlbum) =>
  (a1.name ?? '').localeCompare(a2.name ?? '');

const sortByArtist = (a1: SpotifyAlbum, a2: SpotifyAlbum) =>
  (a1.artists[0].name ?? '').localeCompare(a2.artists[0].name ?? '');

const sortMap = new Map([
  [AlbumsListSortingOption.yearNew, sortByYearNew],
  [AlbumsListSortingOption.yearOld, sortByYearOld],
  [AlbumsListSortingOption.artist, sortByArtist],
  [AlbumsListSortingOption.title, sortByTitle]]
);

export function AlbumsList(props: AlbumsListProps): JSX.Element {

  const [albums, setAlbums] = useState<SpotifyAlbum[]>(props.albums);
  const [originalAlbumsOrder] = useState<SpotifyAlbum[]>(props.albums);
  const [sortingOption, setSortingOption] = useState<AlbumsListSortingOption>(props.sorting
        ?? AlbumsListSortingOption.relevancy);

  useEffect(() => {
    if (sortingOption === AlbumsListSortingOption.relevancy) {
      setAlbums(originalAlbumsOrder);
    } else {
      setAlbums(Array.from(albums).sort(sortMap.get(sortingOption)));
    }
  }, [sortingOption, originalAlbumsOrder]);

  useEffect(() => {
    if (props.sorting) {
      setSortingOption(props.sorting);
    }
  }, [props.sorting]);

  const displayArtist = props.displayArtist ?? true;
  const displayYear = props.displayYear ?? true;
  const integratedSorting = props.integratedSorting ?? true;
  const integratedAddToMix = props.integratedAddToMix ?? true;
  const mixSuffix = props.mixSuffix ?? '';

  const albumBlocks = albums.map(a =>
    <AlbumTile key={'ali' + a.id} album={a} displayArtist={displayArtist} displayYear={displayYear} />);

  return <div className="albums-list">
    {integratedSorting && <div className="albums-list-sorting-menu">
      <AlbumsListSortingMenu selectedOption={sortingOption} optionSelectionHandler={setSortingOption} />
    </div>}
    <div className="albums-list-items">
      {integratedAddToMix && <AlbumMixTile albums={albums} mixSuffix={mixSuffix} />}
      {albumBlocks}
    </div>
  </div>;
}
