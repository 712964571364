import SpotifyWebApi from 'spotify-web-api-js';

import {TokenData, TokenType} from '../authenticator/authenticator';
import SpotifyArtist from './spotifyArtist';
import SpotifyPlayer from './spotifyPlayer';
import SpotifyAlbum from './spotifyAlbum';
import SpotifyImage from './spotifyImage';
import SpotifyTrack from './spotifyTrack';
import SpotifyUserProfile from './spotifyUserProfile';
import {SpotifyPlaylist} from './spotifyPlaylist';
import {deduplicateArtists} from './apiHelpers';
import arrayChunk from '../util/arrayChunk';
import SpotifyNowPlaying from './spotifyNowPlaying';

interface AlbumObjectFullWithLabel extends SpotifyApi.AlbumObjectFull {
  label: string;
}

export enum ApiTimeRange {
  shortTerm = 'short_term',
  mediumTerm = 'medium_term',
  longTerm = 'long_term',
  latest = 'latest'
}

export enum SubscriptionType {
  guest = 'guest',
  premium = 'premium',
  free = 'free'
}

class Api {

  static readonly artistsChunkSize = 50;
  static readonly albumsChunkSize = 50;
  static readonly tracksChunkSize = 50;
  static readonly maximumDepth = 1000;

  private userProfile: SpotifyUserProfile = {
    avatar: '',
    email: '',
    followers: 0,
    id: '',
    market: '',
    name: '',
    uri: '',
    url: '',
    subscriptionType: SubscriptionType.guest
  };

  private _market = () => this.userProfile?.market
    ? {market: this.userProfile.market}
    : {};

  private players: SpotifyPlayer[] = [];
  private currentPlayer = '';

  private api: SpotifyWebApi.SpotifyWebApiJs = new SpotifyWebApi();

  public playbackAbilityChange = new EventTarget();

  async initialize(token: TokenData): Promise<boolean> {
    this.api.setAccessToken(token.token);

    if (token.type == TokenType.guest) {
      return true;
    }
    try {
      await this.sleep(200);
      await Promise.all([this.detectDevices(), this.loadUserProfile()]);
      return true;
    } catch (e) {
      return false;
    }
  }

  getUserProfile(): SpotifyUserProfile {
    return this.userProfile;
  }

  async detectDevices(): Promise<void> {
    const response = await this.api.getMyDevices();
    this.players = response.devices
      .filter(device => {
        return !device.is_restricted;
      })
      .map(device => {
        return {
          id: device.id ?? 'Broken player',
          name: device.name
        };
      });
    if (this.players.length) {
      this.currentPlayer = this.players[0].id;
    }
    this.playbackAbilityChange.dispatchEvent(new Event('playbackAbilityChange'));
  }

  async loadUserProfile(): Promise<void> {
    const response = await this.api.getMe();
    this.userProfile = {
      id: response.id,
      name: response.display_name || '',
      email: response.email,
      market: response.country,
      avatar: response.images?.[0]?.url || '',
      followers: response.followers?.total ?? 0,
      url: response.external_urls.spotify,
      uri: response.uri,
      subscriptionType: response.product as SubscriptionType
    };
  }

  async getArtist(artistId: string): Promise<SpotifyArtist> {
    const artist = await this.api.getArtist(artistId);
    return Api.getSpotifyArtist(artist);
  }

  async getArtists(ids: string[]): Promise<SpotifyArtist[]> {
    const response = await this.api.getArtists(ids);
    return response.artists.map(Api.getSpotifyArtist);
  }

  async getArtistTopTracks(artistId: string): Promise<SpotifyTrack[]> {
    // todo: fix by contributing to https://github.com/JMPerez/spotify-web-api-js making countryId optional
    const response = await this.api.getArtistTopTracks(artistId, this.userProfile.market || 'US');
    return response.tracks.map(Api.getSpotifyTrack);
  }

  async searchArtists(query: string, offset = 0, depth: number = Api.maximumDepth) : Promise<Array<SpotifyArtist>> {
    const items: SpotifyArtist[] = [];
    const stopAt = (depth < Api.maximumDepth) ? depth : Api.maximumDepth;
    while (offset < stopAt) {
      const response = await this.api.search(query, ['artist'], {
        limit: Api.artistsChunkSize,
        offset: offset
      });
      if (response.artists) {
        if (!response.artists.items) {
          break;
        }
        items.push(...response.artists.items.map(item => Api.getSpotifyArtist(item)) ?? []);
        if (!response.artists.next) {
          break;
        }
        offset += Api.artistsChunkSize;
      }

    }
    return deduplicateArtists(items);
  }

  async getSimilarArtists(id: string) : Promise<Array<SpotifyArtist>> {
    const data : SpotifyApi.ArtistsRelatedArtistsResponse = await this.api.getArtistRelatedArtists(id);
    return data.artists.map(artist => Api.getSpotifyArtist(artist));
  }

  async loadArtistImages(artists: SpotifyArtist[]): Promise<void> {
    const incompleteArtists = artists.filter(a => !a.images.length).map(a => a.id);
    if (!incompleteArtists.length) {
      return;
    }
    const imagesCache = new Map((await this.getArtists(incompleteArtists)).map(a => [a.id, a.images]));
    for (let i = 0; i < artists.length; i++) {
      const artist = artists[i];
      artists[i].images = (artist.images.length)
        ? artist.images
        : imagesCache.get(artists[i].id) ?? [];
    }
  }

  async getArtistRecommendations(artistIds: string[]): Promise<SpotifyTrack[]> {
    const data = await this.api.getRecommendations({
      'seed_artists': artistIds.join(','),
      ...this._market(),
      'limit': 50
    });
    return data.tracks.map(t => Api.getSpotifyTrack(t as SpotifyApi.TrackObjectFull));
  }

  async getTracksRecommendations(trackIds: string[]): Promise<SpotifyTrack[]> {
    const data = await this.api.getRecommendations({
      'seed_tracks': trackIds.join(','),
      ...this._market(),
      'limit': 50
    });
    return data.tracks.map(t => Api.getSpotifyTrack(t as SpotifyApi.TrackObjectFull));
  }

  async getUserFollowedArtists() : Promise<Array<SpotifyArtist>> {
    const artists = [];
    let next = true;
    let after = '';
    while (next) {
      const options = (after) ? {limit: 50, after: after} : {limit: 50};
      const data = await this.api.getFollowedArtists(options);
      artists.push(...data.artists.items);
      next = data.artists.next !== null;
      after = data.artists.items[data.artists.items.length - 1]?.id ?? '';
    }
    return artists.map(artist => {
      return Api.getSpotifyArtist(artist);
    }).sort((a:SpotifyArtist, b:SpotifyArtist) => a.name.localeCompare(b.name));
  }

  getUserPlayers = (): SpotifyPlayer[] => {
    return this.players;
  };

  getUserCurrentPlayer = (): SpotifyPlayer|null => {
    if (!this.currentPlayer) {
      return null;
    }
    const player = this.players.find(p => p.id === this.currentPlayer);
    return (player) ? player : null;
  };

  switchUserPlayer = async (playerId: string): Promise<void> => {
    if (this.currentPlayer !== playerId) {
      this.currentPlayer = playerId;
      await this.api.transferMyPlayback([this.currentPlayer]);
    }
  };

  isGuestSession = (): boolean => this.userProfile.subscriptionType === SubscriptionType.guest;

  isFreeSession = (): boolean => this.userProfile.subscriptionType === SubscriptionType.free;

  isPremiumSession = (): boolean => this.userProfile.subscriptionType === SubscriptionType.premium;

  canPlay = (): boolean => this.isPremiumSession() && this.players.length !== 0;

  async playArtist(artist: SpotifyArtist) : Promise<void> {
    await this.api.play({
      device_id: this.currentPlayer,
      context_uri: artist.uri
    });
  }

  async playAlbum(album: SpotifyAlbum): Promise<void> {
    await this.api.play({
      device_id: this.currentPlayer,
      context_uri: album.uri
    });
  }

  async playTrack(track: SpotifyTrack): Promise<void> {
    await this.api.play({
      device_id: this.currentPlayer,
      uris: [track.uri]
    });
  }

  async playTracks(tracks: SpotifyTrack[]): Promise<void> {
    await this.api.play({
      device_id: this.currentPlayer,
      uris: tracks.map(t => t.uri)
    });
  }

  async skipToNext() : Promise<void> {
    await this.api.skipToNext();
  }

  async skipToPrevious() : Promise<void> {
    await this.api.skipToPrevious();
  }

  async pause() : Promise<void> {
    await this.api.pause();
  }

  async play() : Promise<void> {
    await this.api.play();
  }

  async getNowPlaying(): Promise<SpotifyNowPlaying|null> {
    const nowPlayingResponse = await this.api.getMyCurrentPlayingTrack({...this._market()});
    if (!nowPlayingResponse.item) {
      return null;
    }
    return {
      isPlaying: nowPlayingResponse.is_playing,
      track: Api.getSpotifyTrack(nowPlayingResponse.item)
    };
  }

  getArtistAlbums = async (artistId: string, groups: string[] = ['album', 'single', 'compilation', 'appears_on'], limit?: number)
        : Promise<Map<string, SpotifyAlbum[]>> => {

    const loadAlbums = async (group: string) => {
      const items: SpotifyApi.AlbumObjectSimplified[] = [];
      let offset = 0;
      const stopAt = limit ?? Api.maximumDepth;
      while (offset < stopAt) {
        const response = await this.api.getArtistAlbums(artistId, {
          include_groups: group,
          country: !this.isGuestSession ? 'from_token' : 'US',
          ...this._market(),
          limit: Api.albumsChunkSize,
          offset: offset
        });
        items.push(...response.items);
        if (!response.items || !response.next) {
          break;
        }
        offset += Api.albumsChunkSize;
      }
      return items;
    };

    const processedAlbums: string[] = [];
    const processAlbums = (albums: SpotifyApi.AlbumObjectSimplified[]) => {
      const albumIds = albums.filter(item => {
        if (processedAlbums.includes(item.name.toLocaleLowerCase())) {
          return false;
        }
        processedAlbums.push(item.name.toLocaleLowerCase());
        return true;
      });
      return albumIds.map(a => Api.getSpotifyAlbum(a as SpotifyApi.AlbumObjectFull));
    };
    const albumsByGroups = new Map<string, SpotifyAlbum[]>();
    for (const group of groups) {
      const response = await loadAlbums(group);
      albumsByGroups.set(group, processAlbums(response));
    }
    return albumsByGroups;
  };

  async getAlbums(ids: string[]): Promise<SpotifyAlbum[]> {
    const chunks = arrayChunk(ids, 20);
    const albums: SpotifyAlbum[] = [];
    for (const chunk of chunks) {
      const response = await this.api.getAlbums(chunk, {...this._market()});
      albums.push(...response.albums.map(Api.getSpotifyAlbum));
    }
    return albums;
  }

  async searchTracks(query: string, limit = 50, offset = 0) :
        Promise<SpotifyApi.PagingObject<SpotifyApi.TrackObjectFull> | undefined> {

    const response = await this.api.search(query, ['track'], {
      offset: offset,
      limit: limit ?? 50,
      ...this._market()
    });
    return response.tracks;
  }

  async getTracks(ids: string[]) : Promise<SpotifyTrack[]> {
    const response = await this.api.getTracks(ids, {
      ...this._market()
    });
    return response.tracks.map(Api.getSpotifyTrack);
  }

  async getAlbum(albumId: string): Promise<SpotifyAlbum> {
    const album = await this.api.getAlbum(albumId, {...this._market()});
    return Api.getSpotifyAlbum(album as AlbumObjectFullWithLabel);
  }

  async searchAlbums(query: string, offset = 0, depth: number = Api.albumsChunkSize) : Promise<[SpotifyAlbum[], boolean]> {
    const items: SpotifyAlbum[] = [];
    const stopAt = (depth < Api.maximumDepth) ? depth : Api.maximumDepth;

    let hasMore = false;
    while (offset < stopAt) {
      const response = await this.api.search(query, ['album'], {
        limit: Api.albumsChunkSize,
        offset: offset
      });
      if (response.albums) {
        if (!response.albums.items) {
          break;
        }
        hasMore = !!response.albums.next;

        // eslint-disable-next-line no-loop-func
        items.push(...response.albums.items.map(item => Api.getSpotifyAlbum(item as SpotifyApi.AlbumObjectFull)) ?? []);
        if (!response.albums.next) {
          break;
        }
        offset += Api.albumsChunkSize;
      }
    }
    return [items, hasMore];
  }

  async getUserSavedAlbums() : Promise<Array<SpotifyAlbum>> {
    const albumsData = [];
    let next = true;
    let offset = 0;
    while (next) {
      const options = {
        limit: 50,
        offset: offset,
        ...this._market(),
      };
      const data = await this.api.getMySavedAlbums(options);
      albumsData.push(...data.items);
      offset += 50;
      next = data.next != null;
    }
    return albumsData.map(albumData => {
      return Api.getSpotifyAlbum(albumData.album);
    }).sort((a:SpotifyAlbum, b:SpotifyAlbum) => {
      const search1 = a.artists?.map(a => a.name).join(',') + ' ' + a.name;
      const search2 = b.artists?.map(a => a.name).join(',') + ' ' + b.name;
      return search1.localeCompare(search2);
    });
  }

  async getUserTopArtists(timeRange: ApiTimeRange = ApiTimeRange.shortTerm): Promise<SpotifyArtist[]> {
    const result = await this.api.getMyTopArtists({
      'time_range': timeRange
    });
    return result.items.map(Api.getSpotifyArtist);
  }

  async getUserTopTracks(timeRange: ApiTimeRange = ApiTimeRange.shortTerm, limit = 50): Promise<SpotifyTrack[]> {
    const result = await this.api.getMyTopTracks({
      'time_range': timeRange,
      'limit': limit,
      'offset': 0
    });
    return result.items.map(Api.getSpotifyTrack);
  }

  async getUserRecentTracks(limit = 50) : Promise<SpotifyTrack[]> {
    const result = await this.api.getMyRecentlyPlayedTracks({
      limit: (limit < 50) ? limit : 50
    });
    return result.items.map(item => Api.getSpotifyTrack(item.track as SpotifyApi.TrackObjectFull));
  }

  saveAlbumsToLibrary(albumIds: string[]): Promise<SpotifyApi.SaveAlbumsForUserResponse> {
    return this.api.addToMySavedAlbums(albumIds);
  }

  removeAlbumsFromLibrary(albumIds: string[]): Promise<SpotifyApi.RemoveAlbumsForUserResponse> {
    return this.api.removeFromMySavedAlbums(albumIds);
  }

  followArtists(artistIds: string[]): Promise<SpotifyApi.FollowArtistsOrUsersResponse> {
    return this.api.followArtists(artistIds);
  }

  unfollowArtists(artistIds: string[]): Promise<SpotifyApi.UnfollowArtistsOrUsersResponse> {
    return this.api.unfollowArtists(artistIds);
  }

  async getUserPlaylists(limit = 50) : Promise<SpotifyPlaylist[]> {
    const result = await this.api.getUserPlaylists(undefined, {
      limit: (limit < 50) ? limit : 50,
      offset: 0
    });
    return result.items.map(Api.getSpotifyPlaylist);
  }

  async getUserPlaylist(playlistId: string) : Promise<SpotifyPlaylist> {
    const result = await this.api.getPlaylist(playlistId);
    return Api.getSpotifyPlaylist(result as SpotifyApi.PlaylistObjectSimplified);
  }

  async addToUserPlaylist(playlistId: string, tracks: SpotifyTrack[]) : Promise<void> {
    await this.api.addTracksToPlaylist(playlistId, tracks.slice(0, 100).map(t => t.uri));
  }

  async createUserPlaylist(name: string, description?: string) : Promise<SpotifyPlaylist> {
    const result = await this.api.createPlaylist(this.userProfile.id, {
      name: name,
      description: description
    });
    return Api.getSpotifyPlaylist(result as SpotifyApi.PlaylistObjectSimplified);
  }

  public static getSpotifyAlbum = (album: SpotifyApi.AlbumObjectFull|AlbumObjectFullWithLabel): SpotifyAlbum => ({
    id: album.id,
    name: album.name,
    uri: album.uri,
    url: album.external_urls.spotify,
    releaseDate: album.release_date ?? '',
    releaseDatePrecision: album.release_date_precision,
    genres: album.genres,
    images: album.images as SpotifyImage[],
    type: album.album_type,
    popularity: album.popularity,
    artists: album.artists.map(Api.getSpotifySimplifiedArtist),
    tracks: album.tracks?.items?.map(t => Api.getSpotifyTrack(t as SpotifyApi.TrackObjectFull)) ?? [],
    copyrights: album.copyrights?.map(c => ((c.type === 'C') ? '© ' : '℗ ') +
      c.text.replace(/[©℗]|\([CP]\)/, '')) ?? [],
    label: ('label' in album) ? album.label : null
  });

  private static getSpotifyArtist(artist: SpotifyApi.ArtistObjectFull) : SpotifyArtist {
    return {
      id: artist.id,
      name: artist.name,
      uri: artist.uri,
      url: artist.external_urls.spotify,
      genres: artist.genres,
      images: artist.images as SpotifyImage[],
      popularity: artist.popularity,
      followers: artist.followers.total
    };
  }

  private static getSpotifySimplifiedArtist(artist: SpotifyApi.ArtistObjectSimplified) : SpotifyArtist {
    return {
      id: artist.id,
      name: artist.name,
      uri: artist.uri,
      url: artist.external_urls.spotify,
      genres: [],
      images: [],
      popularity: 0,
      followers: 0
    };
  }

  public static getSpotifyTrack = (track: SpotifyApi.TrackObjectFull): SpotifyTrack => ({
    id: track.id,
    name: track.name,
    duration: track.duration_ms,
    uri: track.uri,
    url: track.external_urls.spotify,
    previewUrl: track.preview_url,
    popularity: track.popularity,
    album: track.album ? Api.getSpotifyAlbum(track.album as SpotifyApi.AlbumObjectFull) : null,
    artists: track.artists.map(Api.getSpotifySimplifiedArtist),
    number: track.track_number
  });

  private static getSpotifyPlaylist = (playlist: SpotifyApi.PlaylistObjectSimplified): SpotifyPlaylist => ({
    id: playlist.id,
    name: playlist.name,
    owner: playlist.owner.id,
    public: playlist.public,
    image: (playlist.images.length) ? playlist.images[0].url : '',
    url: playlist.external_urls['spotify'],
    uri: playlist.uri
  });

  private sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}

export default Api;
