import {IYourMixStore} from './yourMix.interface';
import {TracksSortingOption} from '../../your-mix/tracksSortingMenu';

export const YOUR_MIX_STORAGE_KEY = 'tracksCollectorState';

export const yourMixStoreInitialState: IYourMixStore = {
  tracks: [],
  sorting: TracksSortingOption.added,
  mixSuffix: ''
};
