import React from 'react';

import {Menu, MenuButton, MenuItem} from '@szhsin/react-menu';
import {DataMode} from './bridgeDataLoader';
import {ApiTimeRange} from '../spotify/api';
import {useTranslation} from 'react-i18next';

import './modeMenu.css';

type ModeMenuProps = {
    mode: DataMode,
    range: ApiTimeRange,
    includeRecommended?: boolean,
    modeChangeHandler(mode: DataMode, range: ApiTimeRange) : void
}

export default function ModeMenu(props: ModeMenuProps): JSX.Element {
  const { t } = useTranslation();

  const includeRecommended = props.includeRecommended ?? false;
  const isRecent = props.mode === DataMode.recent;
  const isTop = props.mode === DataMode.top;
  const isRecommended = props.mode === DataMode.recommended;
  const isShortTerm = props.range === ApiTimeRange.shortTerm;
  const isMidTerm = props.range === ApiTimeRange.mediumTerm;
  const isLongTerm = props.range === ApiTimeRange.longTerm;
  const isLatest = props.range === ApiTimeRange.latest;

  let rangeButtonLabel = t('CabinMode.ShortTerm');
  if (isMidTerm) {
    rangeButtonLabel = t('CabinMode.MediumTerm');
  } else if (isLongTerm) {
    rangeButtonLabel = t('CabinMode.LongTerm');
  } else if (isLatest) {
    rangeButtonLabel = t('CabinMode.Latest');
  }

  let modeLabel = t('CabinMode.Top');
  if (isRecent) {
    modeLabel = t('CabinMode.Recent');
  } else if (isRecommended) {
    modeLabel = t('CabinMode.Recommended');
  }

  return <>
    <Menu
      menuButton={
        <MenuButton className="mode-menu-button">
          {modeLabel}
          <img src={'down.svg'} width={10} alt={t('CabinMode.OpenMenu')}/>
        </MenuButton>
      }
      theming={'dark'}>

      <MenuItem type="checkbox" checked={isRecent} onClick={() =>
        props.modeChangeHandler(DataMode.recent, props.range)}>{t('CabinMode.Recent')}</MenuItem>
      {includeRecommended && <MenuItem type="checkbox" checked={isRecommended} onClick={() =>
        props.modeChangeHandler(DataMode.recommended, props.range)}>{t('CabinMode.Recommended')}</MenuItem>}
      <MenuItem type="checkbox" checked={isTop} onClick={() =>
        props.modeChangeHandler(DataMode.top, props.range)}>{t('CabinMode.Top')}</MenuItem>
    </Menu>
    {(isTop || isRecommended) && <Menu
      menuButton={
        <MenuButton className="mode-menu-button">
          {rangeButtonLabel}
          <img src={'down.svg'} width={10} alt={t('CabinMode.OpenMenu')}/>
        </MenuButton>
      }
      theming={'dark'}>

      {isRecommended && <MenuItem type="checkbox" checked={isLatest} onClick={() =>
        props.modeChangeHandler(props.mode, ApiTimeRange.latest)}>{t('CabinMode.Latest')}</MenuItem>}
      <MenuItem type="checkbox" checked={isShortTerm} onClick={() =>
        props.modeChangeHandler(props.mode, ApiTimeRange.shortTerm)}>{t('CabinMode.ShortTerm')}</MenuItem>
      <MenuItem type="checkbox" checked={isMidTerm} onClick={() =>
        props.modeChangeHandler(props.mode, ApiTimeRange.mediumTerm)}>{t('CabinMode.MediumTerm')}</MenuItem>
      <MenuItem type="checkbox" checked={isLongTerm} onClick={() =>
        props.modeChangeHandler(props.mode, ApiTimeRange.longTerm)}>{t('CabinMode.LongTerm')}</MenuItem>
    </Menu>}
  </>;
}
