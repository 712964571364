import {useContext, useState} from 'react';
import SpotifyApiContext from '../../spotify/spotifyApiContext';
import {artistsStoreSlice} from '../../redux/artists/artists.slice';
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';
import {useAppDispatch} from '../../redux/store';

function useFollowArtist(): [(id: string) => Promise<void>, boolean] {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const api = useContext(SpotifyApiContext);
  const dispatch = useAppDispatch();
  const followArtist = (artistId: string) => {
    setIsLoading(true);

    return api
      .followArtists([artistId])
      .then(() => {
        dispatch(artistsStoreSlice.actions.addFollowedArtistIds([artistId]));

        toast(t('ArtistMenu.FollowToast'), {
          type: 'success',
          position: 'top-center'
        });
      })
      .catch(() => {
        toast(t('ArtistMenu.FollowToastErr'), {
          type: 'error',
          position: 'top-center'
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return [followArtist, isLoading];
}

export default useFollowArtist;
