import React, {useContext, useEffect, useState} from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {isMobile} from 'react-device-detect';
import {ToastContainer} from 'react-toastify';
import {useTranslation} from 'react-i18next';
import SpotifyExplorer from '../artist-explorer/artistExplorer';
import SpotifyApiContext from '../spotify/spotifyApiContext';
import {GenreExplorer} from '../genre-explorer/genreExplorer';
import AlbumExplorer from '../album-explorer/albumExplorer';
import Navigation from './navigation';
import GenreBrowser from '../genre-browser/genreBrowser';
import {track, TrackingEvent} from '../util/track';
import Bridge from '../bridge/bridge';
import AppFooter from './footer';
import ArtistDetails from '../artist-details/artistDetails';
import AlbumDetails from '../album-details/albumDetails';
import ArtistBrowser from '../artist-browser/artistBrowser';
import AlbumBrowser from '../album-browser/albumBrowser';
import {LabelExplorer} from '../label-explorer/labelExplorer';
import UserMenu from '../user-menu/userMenu';
import YourMix from '../your-mix/yourMix';
import ClickableLogo from './logo';
import useInitFollowedArtists from '../hooks/artists/useInitFollowedArtists';
import useInitSavedAlbums from '../hooks/albums/useInitSavedAlbums';
import useInitGuiSettings from '../hooks/gui-settings/useInitGuiSettings';
import {persistGuiSettings} from '../redux/gui-settings/guiSettings.actions';
import {useAppDispatch} from '../redux/store';
import {TokenChecker} from '../authenticator/token-checker/tokenChecker';
import {useLargeScreenModeDetector} from './hooks/useLargeScreenModeDetector';
import YourMixBadge from '../your-mix/yourMixBadge';
import {persistYourMix} from '../redux/your-mix/yourMix.actions';
import useInitYourMix from '../hooks/your-mix/useInitYourMix';
import useInitPlaylists from '../hooks/playlists/useInitPlaylists';
import {permissionsStoreSlice} from '../redux/permissions/permissions.slice';
import {useSelector} from 'react-redux';
import {permissionsSelector} from '../redux/permissions/permissions.selector';
import {TokenData} from '../authenticator/authenticator';
import AuthenticatorContext from '../authenticator/authenticatorContext';
import Logo from '../logo/logo';

import 'react-image-lightbox/style.css';
import 'react-toastify/dist/ReactToastify.css';

import './app.css';
import './appMobile.css';
import './spinner.css';
import './burger.css';
import ServerApiContext from '../server-api/serverApiContext';

enum AppNavPoint {
    bridge = 0,
    exploreArtists = 1,
    exploreGenres = 2,
    exploreAlbums = 3,
    exploreLabels = 4,
    artistDetails = 5,
    albumDetails = 6,
    yourMix = 7
}

function App(): JSX.Element {
  const { t } = useTranslation();
  const authenticator = useContext(AuthenticatorContext);
  const spotify = useContext(SpotifyApiContext);
  const serverApi = useContext(ServerApiContext);
  const history = useHistory();
  const dispatch = useAppDispatch();

  history.listen(() => {
    window.scrollTo(0, 0);
  });

  let appNavPoint = AppNavPoint.bridge;
  const exploreArtistsRoute = useRouteMatch('/artists/:artist?');
  const exploreGenresRoute = useRouteMatch('/genres/:genres?');
  const exploreAlbumsRoute = useRouteMatch('/albums/:album?');
  const exploreLabelsRoute = useRouteMatch('/labels/:label?');
  const artistDetailsRoute = useRouteMatch('/artist/:artist');
  const albumDetailsRoute = useRouteMatch('/album/:album');
  const yourMixRoute = useRouteMatch('/your-mix');
  let selectedGenres: string[] = [];
  let selectedArtist = '';
  let selectedAlbum = '';
  if (exploreArtistsRoute) {
    appNavPoint = AppNavPoint.exploreArtists;
    selectedArtist = (exploreArtistsRoute.params as {artist: string}).artist;
  } else if (exploreGenresRoute) {
    appNavPoint = AppNavPoint.exploreGenres;
    selectedGenres = (exploreGenresRoute.params as {genres: string}).genres?.split(',') ?? [];
  } else if (exploreAlbumsRoute) {
    appNavPoint = AppNavPoint.exploreAlbums;
    selectedAlbum = (exploreAlbumsRoute.params as {album: string}).album;
  } else if (exploreLabelsRoute) {
    appNavPoint = AppNavPoint.exploreLabels;
  } else if (artistDetailsRoute) {
    appNavPoint = AppNavPoint.artistDetails;
    selectedArtist = (artistDetailsRoute.params as {artist: string}).artist;
  } else if (albumDetailsRoute) {
    appNavPoint = AppNavPoint.albumDetails;
    selectedAlbum = (albumDetailsRoute.params as {album: string}).album;
  } else if (yourMixRoute) {
    appNavPoint = AppNavPoint.yourMix;
  }

  const [initialized, setInitialized] = useState<boolean>(false);
  const [canPlay, setCanPlay] = useState<boolean>(false);
  const isLargeScreenMode = useLargeScreenModeDetector();

  const isBridge = appNavPoint === AppNavPoint.bridge;
  const isExploreArtists = appNavPoint === AppNavPoint.exploreArtists;
  const isExploreGenres = appNavPoint === AppNavPoint.exploreGenres;
  const isExploreAlbums = appNavPoint === AppNavPoint.exploreAlbums;
  const isExploreLabels = appNavPoint === AppNavPoint.exploreLabels;
  const isArtistDetails = appNavPoint === AppNavPoint.artistDetails;
  const isAlbumDetails = appNavPoint === AppNavPoint.albumDetails;
  const isCollectedTracks = appNavPoint === AppNavPoint.yourMix;

  const onPlaybackAbilityChange = () => {
    setCanPlay(spotify.canPlay());
  };

  const permissions = useSelector(permissionsSelector);
  const [initFollowedArtists] = useInitFollowedArtists();
  const [initSavedAlbums] = useInitSavedAlbums();
  const [initPlaylists] = useInitPlaylists();
  const initGuiSettings = useInitGuiSettings();
  const initCollectedTracks = useInitYourMix();

  useEffect(() => {
    authenticator.authorize().then((token: TokenData) => {
      spotify.initialize(token).then((result) => {
        if (!result) {
          authenticator.logout();
        }
        dispatch(permissionsStoreSlice.actions.setPermissions({
          subscription: spotify.getUserProfile().subscriptionType,
          canPlayNow: spotify.canPlay()
        }));
        const initLoaders = !spotify.isGuestSession() ? [
          initFollowedArtists(),
          initSavedAlbums(),
          initGuiSettings(),
          initCollectedTracks(),
          initPlaylists()]
          : [];

        Promise
          .all(initLoaders)
          .finally(() => {
            setCanPlay(spotify.canPlay());
            setInitialized(result);
          });
      });
    });
    spotify.playbackAbilityChange.addEventListener('playbackAbilityChange', onPlaybackAbilityChange);
    return () => {
      spotify.playbackAbilityChange.removeEventListener('playbackAbilityChange', onPlaybackAbilityChange);
    };
  }, [spotify]);

  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      dispatch(persistGuiSettings());
      dispatch(persistYourMix());
    });
  }, []);

  const backToTheBridge = () => {
    if (appNavPoint !== AppNavPoint.bridge) {
      track(TrackingEvent.screenView, {
        'app_name': 'Unchartify',
        'screen_name': 'Captain\'s Bridge'
      });
    }
  };

  const exploreArtists = () => {
    if (appNavPoint !== AppNavPoint.exploreArtists) {
      track(TrackingEvent.screenView, {
        'app_name': 'Unchartify',
        'screen_name': 'Artists Explorer'
      });
    }
  };

  const exploreGenres = () => {
    if (appNavPoint !== AppNavPoint.exploreGenres) {
      track(TrackingEvent.screenView, {
        'app_name': 'Unchartify',
        'screen_name': 'Genres Explorer'
      });
    }
  };

  const exploreAlbums = () => {
    if (appNavPoint !== AppNavPoint.exploreAlbums) {
      track(TrackingEvent.screenView, {
        'app_name': 'Unchartify',
        'screen_name': 'Albums Explorer'
      });
    }
  };

  const exploreLabels = () => {
    if (appNavPoint !== AppNavPoint.exploreLabels) {
      track(TrackingEvent.screenView, {
        'app_name': 'Unchartify',
        'screen_name': 'Labels Explorer'
      });
    }
  };

  const screenCaption = (initialized && isMobile) ? {
    [AppNavPoint.bridge]: t('ScreenCaption.Bridge'),
    [AppNavPoint.exploreArtists]: t('ScreenCaption.ExploreArtists'),
    [AppNavPoint.exploreGenres]: t('ScreenCaption.ExploreGenres'),
    [AppNavPoint.exploreAlbums]: t('ScreenCaption.ExploreAlbums'),
    [AppNavPoint.exploreLabels]: t('ScreenCaption.ExploreLabels'),
    [AppNavPoint.artistDetails]: t('ScreenCaption.Artist'),
    [AppNavPoint.albumDetails]: t('ScreenCaption.Album'),
    [AppNavPoint.yourMix]: t('ScreenCaption.CollectedTracks')
  }[appNavPoint]
    : null;

  let content;
  if (initialized) {
    if (isBridge && !permissions.isSubscriber) {
      history.push('/genres');
    } else if (isBridge) {
      content = <Bridge/>;
    } else if (isExploreArtists && selectedArtist) {
      content = <SpotifyExplorer rootArtistId={selectedArtist}/>;
    } else if (isExploreArtists) {
      content = <ArtistBrowser />;
    } else if (isExploreGenres && selectedGenres.length) {
      content = <GenreExplorer isLargeScreenMode={isLargeScreenMode} />;
    } else if (isExploreGenres) {
      content = <GenreBrowser isLargeScreenMode={isLargeScreenMode} />;
    } else if (isExploreAlbums && selectedAlbum) {
      content = <AlbumExplorer
        rootAlbumId={selectedAlbum}
        canPlay={canPlay}
      />;
    } else if (isExploreAlbums) {
      content = <AlbumBrowser/>;
    } else if (isExploreLabels) {
      content = <LabelExplorer isLargeScreenMode={isLargeScreenMode}/>;
    } else if (isArtistDetails && selectedArtist) {
      content = <ArtistDetails artistId={selectedArtist} />;
    } else if (isAlbumDetails && selectedAlbum) {
      content = <AlbumDetails albumId={selectedAlbum} />;
    } else if (isCollectedTracks) {
      content = <YourMix/>;
    }
  }

  return (
    <>
      <div
        className={'app-container' + (isMobile ? ' mobile' : '')}>
        <AuthenticatorContext.Provider value={authenticator}>
          <SpotifyApiContext.Provider value={spotify}>
            <ServerApiContext.Provider value={serverApi}>
              {initialized && <div className={`app-header ${isLargeScreenMode ? 'app-header-hidden' : ''}`}>
                {!isMobile && <ClickableLogo />}
                <Navigation
                  bridgeSelected={isBridge}
                  artistsSelected={isExploreArtists}
                  genresSelected={isExploreGenres}
                  albumsSelected={isExploreAlbums}
                  labelsSelected={isExploreLabels}
                  canPlay={canPlay}
                  bridgeSelectionHandler={backToTheBridge}
                  artistsSelectionHandler={exploreArtists}
                  genresSelectionHandler={exploreGenres}
                  albumsSelectionHandler={exploreAlbums}
                  labelsSelectionHandler={exploreLabels}
                />
                <div className='app-options'>
                  {screenCaption && <div className="section-title">{screenCaption}</div>}
                </div>
                {<YourMixBadge/>}
                {<UserMenu profile={spotify.getUserProfile()} />}
                {permissions.canViewNowPlaying && <TokenChecker />}
              </div>}
              {initialized && <div className='app-body'>
                {content}
              </div>}
              {initialized && <AppFooter isLargeScreenMode={isLargeScreenMode}/>}
              {!initialized && <div className={'loading-logo'}><Logo iconSize={isMobile ? 30 : 50}/></div>}
            </ServerApiContext.Provider>
          </SpotifyApiContext.Provider>
        </AuthenticatorContext.Provider>
      </div>
      <ToastContainer
        position="top-center"
        hideProgressBar={true}
        autoClose={2000}
      />
    </>
  );
}

export default App;
