import {useCallback} from 'react';
import {useAppDispatch} from '../../redux/store';
import {
  yourMixStoreInitialState,
  YOUR_MIX_STORAGE_KEY
} from '../../redux/your-mix/yourMix.constants';
import {yourMixStoreSlice} from '../../redux/your-mix/yourMix.slice';

function useInitYourMix(): () => Promise<void> {
  const dispatch = useAppDispatch();

  return useCallback(() => {
    const yourMixJson = localStorage.getItem(YOUR_MIX_STORAGE_KEY);
    const yourMixState = yourMixJson
      ? JSON.parse(yourMixJson)
      : yourMixStoreInitialState;

    dispatch(yourMixStoreSlice.actions.setYourMixState(yourMixState));
    return Promise.resolve();
  }, [dispatch]);
}

export default useInitYourMix;
