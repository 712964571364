import {useAppDispatch} from '../../redux/store';
import SpotifyTrack from '../../spotify/spotifyTrack';
import {yourMixStoreSlice} from '../../redux/your-mix/yourMix.slice';

function useCollectTracks(): [(tracks: SpotifyTrack[]) => void] {
  const dispatch = useAppDispatch();

  const collectTracks = (tracks: SpotifyTrack[]) => {
    dispatch(yourMixStoreSlice.actions.collectTracks(tracks));
  };
  return [collectTracks];
}

export default useCollectTracks;
