import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {artistsStoreInitialState} from './artists.constants';

export const artistsStoreSlice = createSlice({
  name: 'artists',
  initialState: artistsStoreInitialState,
  reducers: {
    addFollowedArtistIds(store, action: PayloadAction<ReadonlyArray<string>>) {
      store.followedArtistIds = [...store.followedArtistIds, ...action.payload];
    },
    removeFollowedArtistIds(store, action: PayloadAction<ReadonlyArray<string>>) {
      const idsToRemove = new Set(action.payload);

      store.followedArtistIds = store.followedArtistIds.filter(id => !idsToRemove.has(id));
    },
  },
});
