import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {isMobile} from 'react-device-detect';
import {useAppDispatch} from '../redux/store';

import SpotifyApiContext from '../spotify/spotifyApiContext';
import {SpotifyPlaylist} from '../spotify/spotifyPlaylist';
import SpotifyTrack from '../spotify/spotifyTrack';

import {
  collectedTracksSelector,
  yourMixSuffixSelector,
  yourMixTracksSortingSelector
} from '../redux/your-mix/yourMix.selector';
import {Tracklist} from '../tracklist/tracklist';
import {Popularity} from '../popularity/popularity';
import YourMixZeroState from './yourMixZeroState';
import {userPlaylistsSelector} from '../redux/playlists/playlists.selector';
import {PlaylistMenuButton} from '../menu-buttons/playlists-menu-button/playlistsMenuButton';
import {TracksSortingMenu, TracksSortingOption} from './tracksSortingMenu';
import useEmptyYourMix from '../hooks/your-mix/useEmptyYourMix';
import useAddToPlaylist from '../hooks/playlists/useAddToPlaylist';
import useAddToNewPlaylist from '../hooks/playlists/useAddToNewPlaylist';
import {yourMixStoreSlice} from '../redux/your-mix/yourMix.slice';
import usePlayTracks from '../hooks/player/usePlayTracks';

import {activateGenericBackground, deactivateGenericBackground} from '../util/background';
import {arrayShuffle} from '../util/arrayShuffle';
import {getFriendlyDuration} from '../util/getFriendlyDuration';
import {getYYYYFromReleaseDate} from '../util/getYearFromReleaseDate';
import {track, TrackingEvent} from '../util/track';

import './yourMix.css';
import './yourMixMobile.css';

const sortByYearNew = (t1: SpotifyTrack, t2: SpotifyTrack) =>
  (getYYYYFromReleaseDate(t2.album?.releaseDate ?? ''))
    .localeCompare(getYYYYFromReleaseDate(t1.album?.releaseDate ?? ''));

const sortByYearOld = (t1: SpotifyTrack, t2: SpotifyTrack) =>
  (getYYYYFromReleaseDate(t1.album?.releaseDate ?? ''))
    .localeCompare(getYYYYFromReleaseDate(t2.album?.releaseDate ?? ''));

const sortByTitle = (t1: SpotifyTrack, t2: SpotifyTrack) =>
  (t1.name ?? '').localeCompare(t2.name ?? '');

const sortByArtist = (t1: SpotifyTrack, t2: SpotifyTrack) =>
  (t1.artists[0].name ?? '').localeCompare(t2.artists[0].name ?? '');

const sortByPopularity = (t1: SpotifyTrack, t2: SpotifyTrack) =>
  (t2.popularity ?? 0) - (t1.popularity ?? 0);

const sortByUnpopularity = (t1: SpotifyTrack, t2: SpotifyTrack) =>
  (t1.popularity ?? 0) - (t2.popularity ?? 0);

const sortMap = new Map([
  [TracksSortingOption.yearNew, sortByYearNew],
  [TracksSortingOption.yearOld, sortByYearOld],
  [TracksSortingOption.artist, sortByArtist],
  [TracksSortingOption.title, sortByTitle],
  [TracksSortingOption.popularityDesc, sortByPopularity],
  [TracksSortingOption.popularityAsc, sortByUnpopularity]]
);

const sortTracks = (tracks: SpotifyTrack[], originalOrder: SpotifyTrack[], sortingOption: TracksSortingOption) : SpotifyTrack[] => {
  if (sortingOption === TracksSortingOption.added) {
    return originalOrder;
  } else if (sortingOption === TracksSortingOption.random) {
    return arrayShuffle(tracks);
  } else {
    return Array.from(tracks).sort(sortMap.get(sortingOption));
  }
};

const YourMix: () => (JSX.Element) = () => {
  const {t} = useTranslation();
  const spotify = useContext(SpotifyApiContext);

  const dispatch = useAppDispatch();
  const tracksInOriginalOrder = useSelector(collectedTracksSelector);
  const tracksSortingOption = useSelector(yourMixTracksSortingSelector);
  const playlists = useSelector(userPlaylistsSelector);
  const mixSuffix = useSelector(yourMixSuffixSelector);

  const emptyYourMix = useEmptyYourMix();
  const addToPlaylist = useAddToPlaylist();
  const addToNewPlaylist = useAddToNewPlaylist();
  const playTracks = usePlayTracks();

  const [sortingOption, setSortingOption] =
        useState<TracksSortingOption>(tracksSortingOption ?? TracksSortingOption.added);
  const [tracks, setTracks] = useState<SpotifyTrack[]>(sortTracks(tracksInOriginalOrder, tracksInOriginalOrder, sortingOption));
  const [canPlay, setCanPlay] = useState<boolean>(spotify.canPlay());
  const [loading, setLoading] = useState<boolean>(false);

  const isEmpty = tracks.length === 0;

  useEffect(() => {
    track(TrackingEvent.screenView, {
      'app_name': 'Unchartify',
      'screen_name': 'Your Mix'
    });
    const background = tracks[Math.floor(Math.random() * tracks.length)]?.album?.images?.[0].url ?? '';
    if (background) {
      activateGenericBackground(background);
    }
    return () => deactivateGenericBackground();
  }, []);

  useEffect(() => {
    const setCanPlayHandler = () => setCanPlay(spotify.canPlay());
    spotify.playbackAbilityChange.addEventListener(
      'playbackAbilityChange', setCanPlayHandler);
    return () => {
      spotify.playbackAbilityChange.removeEventListener('playbackAbilityChange', setCanPlayHandler);
    };
  }, [spotify]);

  useEffect(() => {
    if (sortingOption !== TracksSortingOption.random) {
      setTracks(sortTracks(tracksInOriginalOrder, tracksInOriginalOrder, sortingOption));
    } else {
      // we have to preserve random order of remaining elements
      const newTrackMap = new Map(tracksInOriginalOrder.map(track => [track.id, track]));
      const updatedTracks = tracks.filter(t => newTrackMap.has(t.id));

      // todo: here we should add new tracks appeared in the store
      // but since we don't have such functionality on the single page yet...
      setTracks(updatedTracks);
    }
  }, [tracksInOriginalOrder]);

  const handlePlayTracks = useCallback(() => playTracks(tracks), [tracks, playTracks]);

  const addTracksToPlaylist = async (playlist: SpotifyPlaylist) => {
    setLoading(true);
    await addToPlaylist(playlist, tracks);
    setLoading(false);
  };

  const addTracksToNewPlaylist = async (playlistName: string) => {
    setLoading(true);
    await addToNewPlaylist(playlistName, tracks);
    setLoading(false);
  };

  const changeSortingOption = (sortingOption: TracksSortingOption) => {
    setTracks(sortTracks(tracks, tracksInOriginalOrder, sortingOption));
    setSortingOption(sortingOption);
    dispatch(yourMixStoreSlice.actions.setSorting(sortingOption));
  };

  const totalTracks = t('AlbumDetails.SongsCount', {count: tracks.length});

  const totalDuration = useMemo(() => {
    if (tracks.length === 0) {
      return '';
    }
    const durationInMs = tracks.reduce((value, t2) => value + t2.duration, 0);
    return getFriendlyDuration(durationInMs);
  }, [tracks]);

  const averagePopularity = useMemo(() => {
    if (tracks.length === 0) {
      return  0;
    }
    const totalPopularity = tracks.reduce((value, t2) => value + (t2.popularity ?? 0), 0);
    return Math.floor(totalPopularity / tracks.length);
  }, [tracks]);

  const trackImage = useMemo(() => {
    if (tracks.length === 0) {
      return  '';
    }
    return tracks[Math.floor(Math.random() * tracks.length)]?.album?.images?.[0].url ?? '';
  }, []);

  const image = (trackImage)
    ? <img className='track-image' src={trackImage} alt=''/>
    : <div className='track-image no-cover'/>;

  if (isEmpty) {
    return <YourMixZeroState/>;
  }

  const spinner = (loading)
    ? <div className='spinner'/>
    : null;

  if (!isMobile) {
    return <div className='tracks-collector'>
      <div className='tracks-collector-info'>
        {image}
        <div className='tracks-collector-header'>
          <div className='tracks-collector-title'>
            {t('TracksCollector.CollectedTracks')}{mixSuffix && <>: {mixSuffix}</>}
          </div>
          <div className='tracks-collector-stats'>
            <span>{totalTracks}</span><span>•</span>
            <span>{totalDuration}</span><span>•</span>
            <span className='tracks-collector-popularity'><Popularity value={averagePopularity} /></span>
          </div>
          <div className='tracks-collector-actions'>
            <TracksSortingMenu
              selectedOption={sortingOption}
              optionSelectionHandler={changeSortingOption}
            />
            <PlaylistMenuButton
              playlists={playlists}
              allowPlaylistCreation={true}
              playlistSuffix={mixSuffix}
              playlistSelectedHandler={addTracksToPlaylist}
              playlistCreationSelectedHandler={addTracksToNewPlaylist}
              disabled={true}
            />
            <button
              onClick={emptyYourMix}
              className='tracks-collector-empty-button'>
              {t('TracksCollector.EmptyButton')}
            </button>
          </div>
        </div>
        <div className={'play-button' + ((!canPlay) ? ' disabled' : '')} onClick={handlePlayTracks}>
          <img src={'play.svg'} alt={t('AlbumDetails.PlayButtonAlt')}/>
        </div>
      </div>
      <Tracklist tracks={tracks} displayCoverArt={true} />
      {spinner}
    </div>;
  } else {
    return <div className='tracks-collector'>
      <div className='tracks-collector-cover'>
        {image}
        <div className='tracks-collector-info'>
          <div className='tracks-collector-title'>
            {t('TracksCollector.CollectedTracks')}
          </div>
          <div className='tracks-collector-stats'>
            <span>{totalTracks}</span>
            <span>{totalDuration}</span>
            <span className='tracks-collector-popularity'><Popularity value={averagePopularity} /></span>
          </div>
        </div>
      </div>
      <div className='tracks-collector-actions'>
        <div className='tracks-collector-main-actions'>
          <PlaylistMenuButton
            playlists={playlists}
            allowPlaylistCreation={true}
            playlistSelectedHandler={addTracksToPlaylist}
            playlistCreationSelectedHandler={addTracksToNewPlaylist}
          />
          <button
            onClick={emptyYourMix}
            className='tracks-collector-empty-button'>
            {t('TracksCollector.EmptyButton')}
          </button>
        </div>

        <div className={'play-button' + ((!canPlay) ? ' disabled' : '')} onClick={handlePlayTracks}>
          <img src={'play.svg'} alt={t('AlbumDetails.PlayButtonAlt')}/>
        </div>
      </div>
      <div className='tracks-collector-tracklist'>
        <TracksSortingMenu
          selectedOption={sortingOption}
          optionSelectionHandler={changeSortingOption}
        />
        <Tracklist tracks={tracks} displayCoverArt={true} />
      </div>
      {spinner}
    </div>;
  }
};

export default YourMix;
