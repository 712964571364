import {useContext} from 'react';
import {useAppDispatch} from '../../redux/store';
import SpotifyApiContext from '../../spotify/spotifyApiContext';
import {playerStoreSlice} from '../../redux/player/player.slice';
import SpotifyNowPlaying from '../../spotify/spotifyNowPlaying';

function useUpdateNowPlaying(): () => void {
  const api = useContext(SpotifyApiContext);
  const dispatch = useAppDispatch();

  return async () => {
    api.getNowPlaying().then((track: SpotifyNowPlaying | null) => {
      dispatch(playerStoreSlice.actions.setNowPlaying(track));
    });
  };
}

export default useUpdateNowPlaying;
