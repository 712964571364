import React from 'react';
import {useTranslation} from 'react-i18next';
import {MenuItem} from '@szhsin/react-menu';
import {AlbumTracksCount} from '../../hooks/albums/useCollectAlbumTracks';

export default function useAlbumMixMenuItems(handler: (count: AlbumTracksCount) => void) : JSX.Element[] {
  const { t } = useTranslation();
  return [
    <MenuItem onClick={() => handler(AlbumTracksCount.one)} key={'ctc-one'}>{t('AlbumMenu.CollectTracks.One')}</MenuItem>,
    <MenuItem onClick={() => handler(AlbumTracksCount.few)} key={'ctc-few'}>{t('AlbumMenu.CollectTracks.Few')}</MenuItem>,
    <MenuItem onClick={() => handler(AlbumTracksCount.many)} key={'ctc-many'}>{t('AlbumMenu.CollectTracks.Many')}</MenuItem>,
    <MenuItem onClick={() => handler(AlbumTracksCount.all)} key={'ctc-all'}>{t('AlbumMenu.CollectTracks.All')}</MenuItem>
  ];
}
