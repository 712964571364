import React, {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import {isMobile} from 'react-device-detect';
import {Menu, MenuButton, MenuDivider, MenuHeader, SubMenu} from '@szhsin/react-menu';

import SpotifyAlbum from '../../spotify/spotifyAlbum';

import useArtistMenuItems from '../artist-menu-items/useArtistMenuItems';
import useAlbumMenuItems from '../album-menu-items/useAlbumMenuItems';
import useSubscriberFeaturesMenuItems from '../subscriber-features-menu-items/useSubscriberFeaturesMenuItems';

import './albumMenu.css';

type AlbumMenuProps = {
    album: SpotifyAlbum
}

export const AlbumMenu: FunctionComponent<AlbumMenuProps> = ({album, children}
    : React.PropsWithChildren<AlbumMenuProps>) => {

  const { t } = useTranslation();
  const albumMenuItems = useAlbumMenuItems(album);
  const artistMenuItems = useArtistMenuItems(album.artists);
  const subscriberFeatures = useSubscriberFeaturesMenuItems();

  const artists = album.artists.filter(a => a.name.toLowerCase() !== 'various artists');
  const hasArtists = artists.length !== 0;

  const items = (!isMobile)
    ? [
      ...(hasArtists) ? [<MenuHeader key="ammh1">{t('AlbumMenu.AlbumLabel')}</MenuHeader>] : [],
      ...albumMenuItems,
      ...(hasArtists) ? [<MenuDivider key="ammd1"/>, <MenuHeader key="ammh2">{t('AlbumMenu.ArtistLabel',
        {count: album.artists.length})}</MenuHeader>] : [],
      ...artistMenuItems
    ]
    : [
      ...albumMenuItems,
      ...(hasArtists)
        ? [ <MenuDivider key="ammh2"/>,
          <SubMenu key="amsm1" label={t('AlbumMenu.ArtistLabel',
            {count: album.artists.length})}>{artistMenuItems}
          </SubMenu>]
        : []
    ];
  items.push(...subscriberFeatures);

  return <Menu
    className={'album-menu'}
    portal={true}
    arrow={true}
    align={'center'}
    menuButton={<MenuButton className={'album-menu-button'}>{children}</MenuButton>}
    theming={'dark'}>
    {items}
  </Menu>;
};

export default AlbumMenu;
