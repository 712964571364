import {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';

import {SpotifyPlaylist} from '../../spotify/spotifyPlaylist';
import SpotifyTrack from '../../spotify/spotifyTrack';
import SpotifyApiContext from '../../spotify/spotifyApiContext';

import arrayChunk from '../../util/arrayChunk';

const tracksBatchSize = 100;

function useAddToPlaylist(): (playlist: SpotifyPlaylist, tracks: SpotifyTrack[]) => void {
  const { t } = useTranslation();
  const api = useContext(SpotifyApiContext);

  return async (playlist: SpotifyPlaylist, tracks: SpotifyTrack[]) => {
    try {
      const batches = arrayChunk(tracks, tracksBatchSize);
      for (const batch of batches) {
        await api.addToUserPlaylist(playlist.id, batch);
      }
      toast(t('TracksCollector.AddToPlaylistSucceeded', {count: tracks.length}), {
        type: 'success',
        position: 'top-center'
      });
    } catch (error) {
      toast(t('TracksCollector.AddToPlaylistFailed'), {
        type: 'error',
        position: 'top-center'
      });
      console.log(error);
    }
  };
}

export default useAddToPlaylist;
