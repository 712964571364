import React, {useEffect, useRef, useState} from 'react';
import Popup from 'reactjs-popup';
import Switch from 'react-switch';
import {PopupActions} from 'reactjs-popup/dist/types';
import Select from 'react-select';
import {useTranslation} from 'react-i18next';
import {guiSettingsStoreInitialState} from '../redux/gui-settings/guiSettings.constants';

import './albumSelectionOptions.css';
import './albumSelectionOptionsMobile.css';

export type YearRangeOption = {
    from: number
    to: number
}

export type AlbumSelectionOptionValues = {
    includeAlbums: boolean
    includeSingles: boolean
    includeVA: boolean
    yearsEnabled: boolean
    years: YearRangeOption,
    exactMatch: boolean,
    rememberPreferences: boolean
}

type AlbumSelectionOptionsProps = {
  options: AlbumSelectionOptionValues
  onApply(options: AlbumSelectionOptionValues): void
  includeExactSwitch?: boolean
}

function AlbumSelectionOptions(props: AlbumSelectionOptionsProps): JSX.Element {
  const { t } = useTranslation();

  const options = props.options;
  const [includeAlbums, setIncludeAlbums] = useState<boolean>(options.includeAlbums);
  const [includeSingles, setIncludeSingles] = useState<boolean>(options.includeSingles);
  const [includeVA, setIncludeVA] = useState<boolean>(options.includeVA);
  const [yearsEnabled, setYearsEnabled] = useState<boolean>(options.yearsEnabled);
  const [selectedYears, setSelectedYears] = useState<YearRangeOption>(options.years);
  const [exactMatch, setExactMatch] = useState<boolean>(options.exactMatch);
  const [rememberPreferences, setRememberPreferences] = useState<boolean>(options.rememberPreferences);
  const includeExactSwitch = props.includeExactSwitch === true;

  const ref = useRef<PopupActions>(null);

  useEffect(() => {
    const listener = () => ref?.current?.close();
    document.addEventListener('click', listener);
    return () => {
      document.removeEventListener('click', listener);
    };
  }, []);

  const applySettings = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    const options = {
      includeAlbums: includeAlbums,
      includeSingles: includeSingles,
      includeVA: includeVA,
      yearsEnabled: yearsEnabled,
      years: (yearsEnabled) ? selectedYears : {from: 0, to: 0},
      exactMatch: exactMatch,
      rememberPreferences: rememberPreferences
    };
    props.onApply(options);
    ref?.current?.close();
  };

  const anyReleaseTypeIncluded = includeAlbums || includeSingles || includeVA;
  const yearsValid = !yearsEnabled || (selectedYears.from !== 0 && selectedYears.to !== 0);
  const applyButtonClass = (anyReleaseTypeIncluded && yearsValid) ? 'enabled' : 'disabled';

  const currentYear = (new Date()).getFullYear();
  const availableYears: {value: number, label: number}[] = [];
  for (let y = currentYear; y >= 1900; y--) {
    availableYears.push({value: y, label: y});
  }
  const selectedYearFrom = (selectedYears.from !== 0)
    ? {value: selectedYears.from, label: selectedYears.from}
    : null;

  const selectedYearTo = (selectedYears.to !== 0)
    ? {value: selectedYears.to, label: selectedYears.to}
    : null;

  const defaultOptions = guiSettingsStoreInitialState.genreExplorer.options;
  const optionsCustomized = options.includeAlbums !== defaultOptions.includeAlbums ||
        options.includeSingles !== defaultOptions.includeSingles ||
        options.includeVA !== defaultOptions.includeVA ||
        options.yearsEnabled !== defaultOptions.yearsEnabled ||
        options.years.to !== defaultOptions.years.to ||
        options.years.from !== defaultOptions.years.from ||
        options.exactMatch !== defaultOptions.exactMatch;

  return <Popup
    ref={ref}
    trigger={() => {
      return <button className={'album-selection-options-button'} title={t('Configure view options')}>
        <img src={'options.svg'} width="24" height="24" alt={t('Configure view options button')}/>
        {optionsCustomized && <div className="album-selection-custom-options-indicator"/>}
      </button>;
    }}
    position="bottom right"
    className="album-selection-options-popup"
    closeOnDocumentClick={false}
  >
    <div className="option">
      <div className="label">{t('Show albums')}</div>
      <Switch
        onChange={() => setIncludeAlbums(!includeAlbums)}
        checked={includeAlbums}
        className="switch"
        onColor="#036551"
        offColor="#036551"
        onHandleColor="#ffffff"
        offHandleColor="#036551"
        handleDiameter={26}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
        height={20}
        width={48}
      />
    </div>

    <div className="option">
      <div className="label">{t('Show singles & EPs')}</div>
      <Switch
        onChange={() => setIncludeSingles(!includeSingles)}
        checked={includeSingles}
        className="switch"
        onColor="#036551"
        offColor="#036551"
        onHandleColor="#ffffff"
        offHandleColor="#036551"
        handleDiameter={26}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
        height={20}
        width={48}
      />
    </div>

    <div className="option">
      <div className="label">{t('Show compilations')}</div>
      <Switch
        onChange={() => setIncludeVA(!includeVA)}
        checked={includeVA}
        className="switch"
        onColor="#036551"
        offColor="#036551"
        onHandleColor="#ffffff"
        offHandleColor="#036551"
        handleDiameter={26}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
        height={20}
        width={48}
      />
    </div>

    <div className="option enable-years">
      <div className="label">{t('Select years range')}</div>
      <Switch
        onChange={() => setYearsEnabled(!yearsEnabled)}
        checked={yearsEnabled}
        className="switch"
        onColor="#036551"
        offColor="#036551"
        onHandleColor="#ffffff"
        offHandleColor="#036551"
        handleDiameter={26}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
        height={20}
        width={48}
      />
    </div>

    {yearsEnabled && <div className="year-range-selector">
      <Select
        placeholder={t('From')}
        className="year-select"
        classNamePrefix="year-select"
        value={selectedYearFrom}
        onChange={(value) => setSelectedYears({
          from: value?.value ?? 0,
          to: (selectedYears.to == null || selectedYears.to === 0) ? value?.value ?? 0 : selectedYears.to
        })}
        options={availableYears}
        menuPortalTarget={document.body}
        autoFocus={false}
      />
      <Select
        placeholder={t('To')}
        className="year-select"
        classNamePrefix="year-select"
        value={selectedYearTo}
        onChange={(value) => setSelectedYears({
          from: (selectedYears.from == null || selectedYears.from === 0) ? value?.value ?? 0 : selectedYears.from,
          to: value?.value ?? 0
        })}
        options={availableYears}
        menuPortalTarget={document.body}
        autoFocus={false}
      />
    </div>}

    {includeExactSwitch && <div className="option exact-match">
      <div className="label">{t('Only exact matches')}</div>
      <Switch
        onChange={() => setExactMatch(!exactMatch)}
        checked={exactMatch}
        className="switch"
        onColor="#036551"
        offColor="#036551"
        onHandleColor="#ffffff"
        offHandleColor="#036551"
        handleDiameter={26}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
        height={20}
        width={48}
      />
    </div>}

    <div className="option remember-preferences">
      <div className="label">{t('GenreExplorerBrowser.Remember')}</div>
      <Switch
        onChange={() => setRememberPreferences(!rememberPreferences)}
        checked={rememberPreferences}
        className="switch"
        onColor="#036551"
        offColor="#036551"
        onHandleColor="#ffffff"
        offHandleColor="#036551"
        handleDiameter={26}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
        height={20}
        width={48}
      />
    </div>

    <button onClick={applySettings} className={applyButtonClass}>{t('Apply')}</button>
  </Popup>;
}

export default AlbumSelectionOptions;
