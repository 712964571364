export enum TrackingEvent {
    screenView = 'screen_view',
    contentSelect = 'content_select',
    search = 'search'
}

export function track(event: TrackingEvent, value?: Record<string, unknown>): void {
  window.gtag('event', event, {
    ...value
  });
}

export function initializeTracking(): void {
  window.gtag('js', new Date());
  window.gtag('config', 'G-K80RYYE3TK');
}
