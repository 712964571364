import {BridgeDataLoader, DataMode} from '../bridge/bridgeDataLoader';
import {ApiTimeRange} from '../spotify/api';
import i18next from 'i18next';

export type GenresCategory = {
    name: string
    description: string
    icon: string
    groups: GenresGroup[]
    toc: string[]
}

export type GenresGroup = {
    name: string
    genres: string[]
}

export class GenresManager {

  private bridgeDataLoader: BridgeDataLoader;
  private readonly personalize: boolean;

  constructor(bridgeDataLoader: BridgeDataLoader, personalize: boolean) {
    this.bridgeDataLoader = bridgeDataLoader;
    this.personalize = personalize;
  }

  private readonly _numericGroup = '#';

  private _categories: GenresCategory[] = [];

  async init(): Promise<GenresCategory[]> {

    const genres = await (await fetch('genres.json')).json();
    const topGenres = (this.personalize)
      ? await this.bridgeDataLoader.getGenres(DataMode.top, ApiTimeRange.longTerm, 50)
      : [];
    const recentGenres = (this.personalize)
      ? await this.bridgeDataLoader.getGenres(DataMode.recent, ApiTimeRange.latest, 50)
      : [];
    const freshGenres = (await (await fetch('genresUpdate.json')).json())['newGenres'];

    if (this.personalize) {
      this._categories.push(this._createCategory(
        i18next.t('GenreCategory.Recent'),
        i18next.t('GenreCategory.Recent.Desc'),
        'genres01.svg',
        recentGenres
      ));
      this._categories.push(this._createCategory(
        i18next.t('GenreCategory.Top'),
        i18next.t('GenreCategory.Top.Desc'),
        'genres04.svg',
        topGenres
      ));
    }
    this._categories.push(this._createCategory(
      i18next.t('GenreCategory.New'),
      i18next.t('GenreCategory.New.Desc'),
      'genres02.svg',
      freshGenres
    ));
    this._categories.push(this._createCategory(
      i18next.t('GenreCategory.All'),
      i18next.t('GenreCategory.All.Desc', {count: genres.length}),
      'genres03.svg',
      genres,
      true
    ));
    return this._categories;
  }

  filter(filter: string): GenresCategory[] {
    if (!filter) {
      return this._categories;
    }
    const filteredCategories: GenresCategory[] = [];
    this._categories.forEach(category => {
      const filteredGroups: GenresGroup[] = [];
      category.groups.forEach(group => {
        const filteredGenres = this._filterGenres(group.genres, filter);
        if (filteredGenres.length) {
          filteredGroups.push({
            name: group.name,
            genres: filteredGenres
          });
        }
      });
      if (filteredGroups.length) {
        filteredCategories.push({
          name: category.name,
          description: category.description,
          icon: category.icon,
          groups: filteredGroups,
          toc: category.toc
        });
      }
    });
    return filteredCategories;
  }

  private _createCategory(name: string, description: string, icon: string, genres: string[], createToc = false) {
    const groups: GenresGroup[] = [];

    genres.sort((g1: string, g2: string) => g1.localeCompare(g2));
    if (!createToc) {
      groups.push({
        name: '',
        genres: genres.map(g => g[0].toUpperCase() + g.slice(1))
      });
    } else {
      groups.push(...Array.from(this._groupGenresByAlpha(genres).values()));
    }
    const toc = createToc ? groups.map(g => g.name[0]) : [];

    const category: GenresCategory = {
      name: name,
      description: description,
      icon: icon,
      groups: groups,
      toc: toc
    };
    return category;
  }

  private _groupGenresByAlpha(genres: string[]) {
    const groups: Map<string, GenresGroup> = new Map();
    for (const genre of genres) {
      const alpha = genre[0].toUpperCase();
      const groupName = (alpha.match(/\d/)) ? this._numericGroup : alpha;
      if (!groups.has(groupName)) {
        groups.set(groupName, {name: groupName, genres: []});
      }
      groups.get(groupName)?.genres.push(alpha + genre.slice(1));
    }
    return groups;
  }

  _filterGenres(genres: string[], filter: string): string[] {
    const filteredGenres = (filter)
      ? genres.slice().filter(genre => genre.toLocaleLowerCase().match(filter.toLocaleLowerCase()))
      : [];

    if (filter && filteredGenres.length > 1) {
      const exactMatchIndex = filteredGenres.indexOf(filter);
      if (exactMatchIndex !== -1) {
        filteredGenres.unshift(filteredGenres[exactMatchIndex]);
        delete filteredGenres[exactMatchIndex + 1];
      }
    }
    return filteredGenres;
  }
}
