import React, { FunctionComponent, useEffect } from 'react';
import './slider.css';

export const SlidingRow: FunctionComponent = (props) => {
  const ref = React.createRef<HTMLDivElement>();

  useEffect(() => {
    ref.current?.scroll({ left: 0, behavior: 'smooth' });
  }, [ref]);

  const moveRight = () => {
    const container = ref.current;
    if (container != null) {
      const step = container.children[0].clientWidth * 2;
      container.scrollBy({ left: step, behavior: 'smooth' });
    }
  };

  const moveLeft = () => {
    const container = ref.current;
    if (container != null) {
      const step = container.children[0].clientWidth * 2;
      container.scrollBy({ left: -step, behavior: 'smooth' });
    }
  };

  return (
    <div className="sliding-row-container">
      <button onClick={moveLeft} className="slide-left">
        <img src={'left.svg'} width={16} height={16} alt="" />
      </button>
      <div className="sliding-row" ref={ref}>
        {props.children ?? null}
      </div>
      <button className="slide-right" onClick={moveRight}>
        <img src={'right.svg'} width={16} height={16} alt="" />
      </button>
    </div>
  );
};

export default SlidingRow;
