import React from 'react';
import {useTranslation} from 'react-i18next';
import ArtistMenu from '../menus/artist-menu/artistMenu';
import SpotifyArtist from '../spotify/spotifyArtist';

import './artistFlowchartNode.css';

type ArtistFlowchartNodeProps = {
    artist: SpotifyArtist,
    expanded: boolean
    toggleHandler(): void
}

const ArtistFlowchartNode: (props: ArtistFlowchartNodeProps) => JSX.Element = (props: ArtistFlowchartNodeProps) => {
  const { t } = useTranslation();
  const artist = props.artist;

  const nodeClass = props.expanded ? 'expanded' : 'collapsed';

  const image = (props.artist.images.length)
    ? <div className="node-image" style={{backgroundImage: 'url(' + artist.images[artist.images.length - 1].url + ')'}} />
    : <div className="node-image"/>;

  let mobileMoved = false;

  const handleMobileToggle = (event: React.TouchEvent): void => {
    event.stopPropagation();
    props.toggleHandler();
  };

  const toggle = (props.expanded)
    ? <span className="node-toggle collapse"
      onClick={props.toggleHandler}
      onTouchStart={() => mobileMoved = false}
      onTouchMove={() => mobileMoved = true}
      onTouchEnd={(e) => (!mobileMoved) ? handleMobileToggle(e) : null}
      title={t('Collapse similar artists')}>
      <img src={'collapse.svg'} alt={t('Collapse similar artists button')}/>
    </span>
    : <span className="node-toggle expand" onClick={props.toggleHandler} onTouchEnd={handleMobileToggle}
      title={t('Expand similar artists')}>
      <img src={'expand.svg'} alt={t('Expand similar artists button')}/>
    </span>;

  return (
    <div
      className={'artist-node ' + nodeClass}>
      <ArtistMenu artist={props.artist}>
        {image}
        <div className="node-name">{artist.name}</div>
      </ArtistMenu>
      {toggle}
    </div>
  );
};

export default ArtistFlowchartNode;
