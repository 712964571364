import {xorString} from '../util/xorString';
import ServerApi from '../server-api/serverApi';

export enum TokenType {
  guest,
  subscriber
}

export type TokenData = {
  token: string,
  type: TokenType,
  granted: number
  expiresIn: number
}

const guestTokenKey = '1sP#tF~nC_Hr!td_';

type GuestTokenResponse = {
  t: string,
  tt: string,
  e: string
}

const spotifyCallbackUrlRx = /access_token=([^&]+)&token_type=([^&]+)&expires_in=(\d+)$/;

class Authenticator {
  private readonly clientId = 'b0583876218d439fb2229e67cee47962';
  private readonly authorizeUrlBase = 'https://accounts.spotify.com/authorize';
  private readonly baseUrl = document.location.protocol + '//' + document.location.host;
  private readonly redirectUri = this.baseUrl + '?run';

  // todo: change to uncharted.com
  private readonly spotifyUnchartedServerBase = ServerApi.serverBase;
  private readonly guestTokenUrl = `${this.spotifyUnchartedServerBase}/auth/unscoped`;

  private readonly scopes = [
    'user-follow-read',
    'user-follow-modify',

    'user-library-read',
    'user-library-modify ',

    'user-read-currently-playing',
    'user-read-playback-state',
    'user-modify-playback-state',

    'user-top-read',
    'user-read-recently-played',
    'user-read-private',

    'playlist-read-private',
    'playlist-modify-public',
    'playlist-modify-private'
  ];

  private readonly tokenKey = 'spotify-uncharted-token';
  private readonly redirectHash = 'spotify-uncharted-redirect-hash';

  private token: TokenData | null = null;

  hasAuth(): boolean {
    const token = window.localStorage.getItem(this.tokenKey);
    if (!token) {
      return false;
    }
    try {
      JSON.parse(token || '');
      return true;
    } catch {
      return false;
    }
  }

  isAuthBacklink(): boolean {
    return !!window.location.hash.match(spotifyCallbackUrlRx);
  }

  getAuthRedirectUrl(): string {
    window.localStorage.setItem(this.redirectHash, document.location.search + document.location.hash);
    return `${this.authorizeUrlBase}?client_id=${this.clientId}&response_type=token&redirect_uri=` +
      `${this.redirectUri}&scope=${this.scopes.join(',')}`;
  }

  async authorize(): Promise<TokenData> {
    // try process Spotify auth callback
    const tokenParams = window.location.hash.match(spotifyCallbackUrlRx);
    if (tokenParams) {
      this.token = {
        token: tokenParams[1],
        type: TokenType.subscriber,
        granted: Date.now(),
        expiresIn: parseInt(tokenParams[3])
      };
      window.localStorage.setItem(this.tokenKey, JSON.stringify(this.token));
      const hash = window.localStorage.getItem(this.redirectHash);
      window.localStorage.removeItem(this.redirectHash);
      document.location.replace(this.baseUrl + hash);
    }

    // try to get already existing token ('logged in' case)
    const _token = window.localStorage.getItem(this.tokenKey);
    try {
      this.token = _token ? (JSON.parse(_token) as TokenData) : null;
    } catch {
      this.token = null;
    }
    if (this.token) {
      // check is token valid (not expired)
      const isValidToken = (Date.now() - this.token.granted)/1000 < this.token.expiresIn;
      if (!isValidToken) {
        window.localStorage.removeItem(this.tokenKey);
        if (this.token.type == TokenType.subscriber) {
          // redirect to login page in case of subscriber's token
          document.location.replace(this.baseUrl);
        } else {
          // get guest token
          await fetch(this.guestTokenUrl);
        }
      }
    } else {
      // get guest token
      const response = await fetch(this.guestTokenUrl);
      if (!response.ok) {
        // redirect to login page if you can't get the guest token
        document.location.replace(this.baseUrl);
      }
      const data = (await response.json()) as GuestTokenResponse;
      this.token = {
        token: xorString(data.t, guestTokenKey),
        type: TokenType.guest,
        granted: Date.now(),
        expiresIn: parseInt(data.e)
      };
      window.localStorage.setItem(this.tokenKey, JSON.stringify(this.token));
    }
    return this.token;
  }

  logout() : void {
    window.localStorage.removeItem(this.tokenKey);
    document.location.replace(this.baseUrl);
  }
}

export default Authenticator;