import Api, {ApiTimeRange} from '../spotify/api';
import SpotifyUserProfile from '../spotify/spotifyUserProfile';
import SpotifyArtist from '../spotify/spotifyArtist';
import SpotifyTrack from '../spotify/spotifyTrack';
import SpotifyAlbum from '../spotify/spotifyAlbum';
import {deduplicateArtists, getArtistsFromTracks} from '../spotify/apiHelpers';
import memoize from 'lodash/memoize';
import {toTitleCase} from '../util/titleCase';

export enum DataMode {
    recent = 0,
    top = 1,
    recommended = 2
}

export class BridgeDataLoader {

  private api: Api;
  private readonly memoizedGetTopArtists: (range: ApiTimeRange) => Promise<SpotifyArtist[]>;
  private readonly memoizedGetArtists: (artistIds: string[]) => Promise<SpotifyArtist[]>;
  private readonly memoizedGetTopTracks: (range: ApiTimeRange) => Promise<SpotifyTrack[]>;
  private readonly memoizedGetRecentTracks: () => Promise<SpotifyTrack[]>;
  private readonly memoizedGetAlbums: (albumIds: string[]) => Promise<SpotifyAlbum[]>;

  constructor(spotifyApi: Api) {
    this.api = spotifyApi;

    this.memoizedGetTopArtists = memoize((range: ApiTimeRange) => this.api.getUserTopArtists(range));
    this.memoizedGetArtists = memoize(
      (artistIds: string[]) => this.api.getArtists(artistIds),
      (artistsIds) => artistsIds.sort().join(':'),
    );
    this.memoizedGetTopTracks = memoize((range: ApiTimeRange) => this.api.getUserTopTracks(range));
    this.memoizedGetRecentTracks = memoize(() => this.api.getUserRecentTracks());
    this.memoizedGetAlbums = memoize(
      (albumIds: string[]) => this.api.getAlbums(albumIds),
      (albumIds) => albumIds.join(':'),);
  }

  getUserProfile(): SpotifyUserProfile | undefined {
    return this.api.getUserProfile();
  }

  async getArtists(mode: DataMode, range: ApiTimeRange, limit = 20): Promise<SpotifyArtist[]> {
    let artists: SpotifyArtist[] = [];

    if (mode === DataMode.top) {
      artists = (await this.memoizedGetTopArtists(range)).slice(0, limit);
    } else if (mode === DataMode.recent) {
      artists = await this.getRecentArtists(limit);
    } else if (mode === DataMode.recommended) {
      artists = await this.getRecommendedArtists(range, limit);
    }
    return artists;
  }

  private async getRecentArtists(limit = 20) {
    const artists = (await this.getRawRecentArtists()).slice(0, limit);

    return this.loadArtistsWithImages(artists);
  }

  private async getRecommendedArtists(range: ApiTimeRange, limit = 20) {
    const seedPool = (range !== ApiTimeRange.latest)
      ? await this.memoizedGetTopArtists(range)
      : await this.getRawRecentArtists();
    const seeds = seedPool.sort(() => .5 - Math.random()).slice(0, 5);
    const tracks = await this.api.getArtistRecommendations(seeds.map(a => a.id));
    const artists = deduplicateArtists(getArtistsFromTracks(tracks)).slice(0, limit);

    return this.loadArtistsWithImages(artists);
  }

  private async getRawRecentArtists(): Promise<SpotifyArtist[]> {
    const rawArtists = getArtistsFromTracks(await this.memoizedGetRecentTracks());

    // for recent mode, combine artists from recent tracks with short-term top artists
    // to overcome stupid Spotify playback history limit
    rawArtists.push(...await this.memoizedGetTopArtists(ApiTimeRange.shortTerm) ?? []);
    return deduplicateArtists(rawArtists);
  }

  async getAlbums(mode: DataMode, range: ApiTimeRange, limit = 20): Promise<SpotifyAlbum[]> {
    let tracks: SpotifyTrack[] = [];
    if (mode === DataMode.top) {
      tracks = await this.memoizedGetTopTracks(range);
    } else if (mode === DataMode.recent) {
      tracks = await this.getRawRecentTracks();
    } else if (mode === DataMode.recommended) {

      const seedPool = getArtistsFromTracks((range !== ApiTimeRange.latest)
        ? await this.memoizedGetTopTracks(range)
        : await this.getRawRecentTracks());

      const seeds = seedPool.sort(() => .5 - Math.random()).slice(0, 5);
      tracks = await this.api.getArtistRecommendations(seeds.map(a => a.id));
    }
    const albums = new Map<string, SpotifyAlbum>();
    for (const track of tracks) {
      if (track.album) {
        albums.set(track.album.id, track.album);
      }
    }
    return Array.from(albums.values()).slice(0, limit);
  }

  private async getRawRecentTracks(): Promise<SpotifyTrack[]> {
    const tracks = await this.memoizedGetRecentTracks();

    // for recent mode, combine albums from recent tracks with short-term top albums
    // to overcome stupid Spotify playback history limit
    tracks.push(...await this.memoizedGetTopTracks(ApiTimeRange.shortTerm));
    return tracks;
  }

  async getGenres(mode: DataMode, range: ApiTimeRange, limit = 20): Promise<string[]> {
    const genreFreq = new Map<string, number>();

    const updateGenreFreq = (genres: string[]) => {
      for (const genre of genres) {
        if (!genreFreq.has(genre)) {
          genreFreq.set(genre, 0);
        }
        genreFreq.set(genre, (genreFreq.get(genre) ?? 0) + 1);
      }
    };

    let genres: [string, number][] = [];
    if (mode === DataMode.top) {
      const artists = await this.getArtists(mode, range);
      artists.forEach(a => updateGenreFreq(a.genres));
      genres = Array.from(genreFreq.entries())
        .sort((a: [string, number], b: [string, number]) => (a[1] > b[1]) ? -1 : 1);

    } else if (mode === DataMode.recent) {
      const artists = await this.getArtists(mode, ApiTimeRange.shortTerm);
      const artistsWithGenres = await this.loadArtistsWithGenres(artists);

      artistsWithGenres.forEach(({genres}) => updateGenreFreq((genres)));
      genres = Array.from(genreFreq.entries());
    }

    return genres.map(g => g[0]).slice(0, limit);
  }

  async getLabels(mode: DataMode, range: ApiTimeRange, limit = 20): Promise<string[]> {
    const labelFreq = new Map<string, number>();
    const updateLabelFreq = (label: string) => labelFreq.set(label, (labelFreq.get(label) ?? 0) + 1);
    const albumIds = (await this.getAlbums(mode, range, limit)).map(a => a.id);
    const albums = await this.memoizedGetAlbums(albumIds);
    albums.forEach(a => a.label ? updateLabelFreq(toTitleCase(a.label)) : null);
    const labels = Array.from(labelFreq.entries())
      .sort((a: [string, number], b: [string, number]) => (a[1] > b[1]) ? -1 : 1);

    return labels.map(l => l[0]).slice(0, limit);
  }

  private async loadArtistsWithImages(artists: SpotifyArtist[]): Promise<SpotifyArtist[]> {
    const incompleteArtistIds = artists
      .filter(artists => !artists.images.length)
      .map(artist => artist.id);

    if (!incompleteArtistIds.length) {
      return artists;
    }

    const fullArtistsById = await this.loadFullArtists(incompleteArtistIds);

    return artists.map(artist => ({
      ...artist,
      images: artist.images.length
        ? artist.images
        : fullArtistsById.get(artist.id)?.images ?? [],
    }));
  }

  private async loadArtistsWithGenres(artists: SpotifyArtist[]): Promise<SpotifyArtist[]> {
    const incompleteArtistIds = artists
      .filter(artists => !artists.genres.length)
      .map(artist => artist.id);

    if (!incompleteArtistIds.length) {
      return artists;
    }

    const fullArtistsById = await this.loadFullArtists(incompleteArtistIds);

    return artists.map(artist => ({
      ...artist,
      genres: artist.genres.length
        ? artist.genres
        : fullArtistsById.get(artist.id)?.genres ?? [],
    }));
  }

  private async loadFullArtists(artistsIds: string[]): Promise<Map<string, SpotifyArtist>> {
    const fullArtists = await this.memoizedGetArtists(artistsIds);
    const fullArtistEntries: [string, SpotifyArtist][] = fullArtists
      .map(fullArtist => [fullArtist.id, fullArtist]);

    return new Map(fullArtistEntries);
  }
}
