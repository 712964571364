import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {Menu, MenuDivider, MenuItem} from '@szhsin/react-menu';
import {useTranslation} from 'react-i18next';
import {SpotifyPlaylist} from '../../spotify/spotifyPlaylist';
import {PlaylistNameDialog} from '../../playlist-name-dialog/playlistNameDialog';
import {permissionsSelector} from '../../redux/permissions/permissions.selector';
import useSubscriberFeaturesMenuItems from '../../menus/subscriber-features-menu-items/useSubscriberFeaturesMenuItems';

import './playlistsMenuButton.css';

type PlaylistsMenuProps = {
    playlists: SpotifyPlaylist[],
    allowPlaylistCreation?: boolean,
    playlistSelectedHandler(playlist: SpotifyPlaylist): void,
    playlistCreationSelectedHandler(playlistName: string): void,
    disabled?: boolean,
    playlistSuffix?: string
}

export function PlaylistMenuButton(props: PlaylistsMenuProps): JSX.Element {
  const {t} = useTranslation();
  const permissions = useSelector(permissionsSelector);
  const subscriberFeatures = useSubscriberFeaturesMenuItems(false);

  const [namingNewPlaylist, setNamingNewPlaylist] = useState<boolean>(false);

  const selectNewPlaylist = (playlistName: string) => {
    setNamingNewPlaylist(false);
    if (playlistName) {
      props.playlistCreationSelectedHandler(playlistName);
    }
  };

  const playlists = props.playlists;
  const creationItem = props.allowPlaylistCreation
    ? <MenuItem onClick={() => setNamingNewPlaylist(true)}>
      <div className='icon' />{t('PlaylistMenu.CreatePlaylist')}</MenuItem>
    : null;

  const playlistsMenuItems = playlists.map(playlist => <MenuItem
    key={'playlist-' + playlist.id} onClick={() => props.playlistSelectedHandler(playlist)}>
    {!playlist.image && <div className='icon' />}
    {playlist.image && <div className='icon' style={{backgroundImage: `url(${playlist.image})`}}/>}
    {playlist.name}
  </MenuItem>);

  const playlistNamingDialog = (namingNewPlaylist)
    ? <PlaylistNameDialog playlistNamedHandler={selectNewPlaylist} playlistSuffix={props.playlistSuffix}/>
    : null;

  const canManage = permissions.canManagePlaylists;

  return <>
    <Menu
      className='playlists-menu'
      portal={true}
      arrow={true}
      align='start'
      menuButton={<div className='playlists-menu-button'>{t('PlaylistMenu.AddToPlaylist')}</div>}
      theming='dark'>
      {canManage && playlistsMenuItems}
      {canManage && creationItem && <MenuDivider/>}
      {canManage && creationItem}
      {!canManage && subscriberFeatures}
    </Menu>
    {canManage && playlistNamingDialog}
  </>;
}
