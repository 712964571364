import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {MenuDivider, MenuItem, SubMenu} from '@szhsin/react-menu';
import copy from 'copy-to-clipboard';

import SpotifyArtist from '../../spotify/spotifyArtist';

import useFollowArtist from '../../hooks/artists/useFollowArtist';
import useUnfollowArtist from '../../hooks/artists/useUnfollowArtist';
import {followedArtistIdsSelector} from '../../redux/artists/artists.selector';
import useCollectArtistTracks from '../../hooks/artists/useCollectArtistTracks';
import useArtistMixMenuItems from '../artist-mix-menu-items/useArtistMixMenuItems';
import usePlayArtist from '../../hooks/player/usePlayArtist';
import {ArtistTracksCollectionMode, ArtistTracksCount} from '../../hooks/artists/artistTracksCollectionEnums';
import {permissionsSelector} from '../../redux/permissions/permissions.selector';

export default function useArtistMenuItems(menuArtists: SpotifyArtist[]): JSX.Element[] {
  const {t} = useTranslation();
  const history = useHistory();

  const permissions = useSelector(permissionsSelector);
  const followedArtists = useSelector(followedArtistIdsSelector);
  const [followArtist] = useFollowArtist();
  const [unfollowArtist] = useUnfollowArtist();
  const collectArtistTracks = useCollectArtistTracks();
  const playArtist = usePlayArtist();

  const artists = menuArtists.filter(a => a.name.toLowerCase() !== 'various artists');

  const isArtistFollowed = (artist: SpotifyArtist) => {
    return followedArtists.includes(artist.id);
  };

  const exploreArtist = (artist: SpotifyArtist) => {
    history.push(`/artists/${artist.id}`);
  };

  const handlePlayArtist = useCallback((artist: SpotifyArtist) => artist && playArtist(artist), [playArtist]);

  const collectArtistsTracks = (count: ArtistTracksCount, mode: ArtistTracksCollectionMode) => {
    collectArtistTracks(artists, count, mode, '').then();
  };

  const followArtistHandler = (artist: SpotifyArtist) => {
    followArtist(artist.id).then();
  };

  const unfollowArtistHandler = (artist: SpotifyArtist) => {
    unfollowArtist(artist.id).then();
  };

  const openArtistDetails = (artist: SpotifyArtist) => {
    history.push(`/artist/${artist.id}`);
  };

  const openArtistInSpotifyApp = (artist: SpotifyArtist) => {
    window.open(artist.uri, '_blank');
  };

  const openArtistInSpotifyWebApp = (artist: SpotifyArtist) => {
    window.open(artist.url, '_blank');
  };

  const copyArtistNameToClipboard = (artist: SpotifyArtist) => {
    copy(artist.name);
  };

  const findArtistInGoogle = (artist: SpotifyArtist) => {
    window.open(`https://www.google.com/search?q=${encodeURIComponent(artist.name)}`, '_blank');
  };

  const tracksCollectionMenuItems = useArtistMixMenuItems(collectArtistsTracks, true, true);
  const hasArtists = artists.length !== 0;
  if (!hasArtists) {
    return [];
  }
  const isSingleArtist = artists.length === 1;

  const exploreArtistItems = artists.map(a =>
    <MenuItem key={'ea' + a.id} onClick={() => exploreArtist(a)}>
      {isSingleArtist ? t('AlbumMenu.ExploreArtist') : a.name}</MenuItem>);

  const playArtistItems = artists.map(a =>
    <MenuItem key={'pa' + a.id} onClick={() => handlePlayArtist(a)} disabled={!permissions.canPlayNow}>
      {isSingleArtist ? t('AlbumMenu.PlayArtist') : a.name}</MenuItem>);

  const artistDetailsItems = artists.map(a =>
    <MenuItem key={'ad' + a.id} onClick={() => openArtistDetails(a)}>
      {isSingleArtist ? t('AlbumMenu.ArtistDetails') : a.name}</MenuItem>);

  const followArtistItems = artists
    .filter(a => !isArtistFollowed(a))
    .map(a => <MenuItem key={'fa' + a.id} onClick={() => followArtistHandler(a)}
      disabled={!permissions.canManageLibrary}>
      {isSingleArtist ? t('AlbumMenu.FollowArtist') : a.name}
    </MenuItem>);

  const unfollowArtistItems = artists
    .filter(isArtistFollowed)
    .map(a => <MenuItem key={'ufa' + a.id} onClick={() => unfollowArtistHandler(a)}
      disabled={!permissions.canManageLibrary}>
      {isSingleArtist ? t('AlbumMenu.UnfollowArtist') : a.name}
    </MenuItem>);

  const openArtistItems = artists.map(a =>
    <MenuItem key={'oa' + a.id} onClick={() => openArtistInSpotifyApp(a)} disabled={!permissions.canOpenSpotifyLinks}>
      {isSingleArtist ? t('AlbumMenu.OpenArtistApp') : a.name}</MenuItem>);

  const openArtistWebItems = artists.map(a =>
    <MenuItem key={'oaw' + a.id} onClick={() => openArtistInSpotifyWebApp(a)}
      disabled={!permissions.canOpenSpotifyLinks}>
      {isSingleArtist ? t('AlbumMenu.OpenArtistWeb') : a.name}</MenuItem>);

  const findInGoogleItems = artists.map(a =>
    <MenuItem key={'fig' + a.id} onClick={() => findArtistInGoogle(a)}>
      {isSingleArtist ? t('AlbumMenu.FindInGoogle') : a.name}</MenuItem>);

  const copyToClipboardItems = artists.map(a =>
    <MenuItem key={'ctc' + a.id} onClick={() => copyArtistNameToClipboard(a)}>
      {isSingleArtist ? t('ArtistMenu.CopyToClipboard') : a.name}</MenuItem>);

  return [
    ...isSingleArtist
      ? playArtistItems
      : [<SubMenu key={'amsm1'} label={t('AlbumMenu.PlayArtist')}
        disabled={!permissions.canPlayNow}>{playArtistItems}</SubMenu>],
    <SubMenu key={'amsm2'} label={t('ArtistMenu.CollectTracks')} disabled={!permissions.canCollectTracks}>
      {tracksCollectionMenuItems}
    </SubMenu>,
    ...isSingleArtist
      ? exploreArtistItems
      : [<SubMenu key={'amsm3'} label={t('AlbumMenu.ExploreArtist')}>{exploreArtistItems}</SubMenu>],
    ...isSingleArtist
      ? artistDetailsItems
      : [<SubMenu key={'amsm4'} label={t('AlbumMenu.ArtistDetails')}>{artistDetailsItems}</SubMenu>],
    ...isSingleArtist
      ? followArtistItems
      : [<SubMenu key={'amsm5'} label={t('AlbumMenu.FollowArtist')}
        disabled={!permissions.canManageLibrary}>{followArtistItems}</SubMenu>],
    ...unfollowArtistItems.length
      ? isSingleArtist
        ? unfollowArtistItems
        : [<SubMenu key={'amsm6'} label={t('AlbumMenu.UnfollowArtist')}
          disabled={!permissions.canManageLibrary}>{unfollowArtistItems}</SubMenu>]
      : [],
    ...isSingleArtist
      ? [<SubMenu key={'amsm7'} label={t('AlbumMenu.OpenArtist')}>
        {openArtistItems}{openArtistWebItems}<MenuDivider key='amdiv1'/>{findInGoogleItems}{copyToClipboardItems}</SubMenu>]
      : [
        <SubMenu key={'amsm8'} label={t('AlbumMenu.OpenArtistApp')}>{openArtistItems}</SubMenu>,
        <SubMenu key={'amsm9'} label={t('AlbumMenu.OpenArtistWeb')}>{openArtistWebItems}</SubMenu>,
        <SubMenu key={'amsm10'} label={t('ArtistMenu.FindInGoogle')}>{findInGoogleItems}</SubMenu>,
        <SubMenu key={'amsm11'} label={t('ArtistMenu.CopyToClipboard')}>{copyToClipboardItems}</SubMenu>
      ]
  ];
}
