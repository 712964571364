import {GenresCategory} from './genresManager';
import React, {useEffect, useState} from 'react';
import GenreGroup from './genreGroup';

import './genreCategory.css';

type GenreCategoryProps = {
    category: GenresCategory
    expanded: boolean
    last: boolean
}

const GenreCategory: (props: GenreCategoryProps) => JSX.Element = (props: GenreCategoryProps) => {
  const category = props.category;

  const [groupToScroll, setGroupToScroll] = useState<string>('');

  useEffect(() => {
    if (groupToScroll) {
      const previousGroupIndex = category.toc.indexOf(groupToScroll) - 1;
      if (previousGroupIndex > -1) {
        setTimeout(() => {
          document.getElementById('group-anchor-' +
                        category.toc[previousGroupIndex])?.scrollIntoView(true);
        }, 100);
      }
      setGroupToScroll('');
    }
  }, [category.toc, groupToScroll]);

  const handleTocItemSelect = (e: React.MouseEvent<HTMLButtonElement>, name: string) => {
    e.preventDefault();
    setGroupToScroll(name);
  };

  const groups = category.groups.map(group =>
    <GenreGroup
      key={group.name}
      group={group}
      expanded={props.expanded}
      last={false}
    />);

  const isNameless = category.name === '';
  const categoryClasses = 'genre-category' + (props.last ? ' last' : '') + (isNameless ? ' nameless' : '');

  const tocEntries = category.toc.map(name => <button
    key={name}
    onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleTocItemSelect(e, name)}>{name}
  </button>);

  const toc = tocEntries.length ? <div className="genre-category-toc">{tocEntries}</div> : null;

  return <div className={categoryClasses}>
    {!isNameless && <div className="genre-category-info">
      <div className="genre-category-label">
        <img className="genre-category-icon" src={category.icon} alt=""/>
        <span className="genre-category-name">{category.name}</span>
      </div>
      <div className="genre-category-description">
        {category.description}
      </div>
    </div>}
    <div className="genre-category-genres">
      {toc}
      {groups}
    </div>
  </div>;
};

export default GenreCategory;
