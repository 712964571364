import React, {useContext, useState} from 'react';
import {Link} from 'react-router-dom';
import {isMobile} from 'react-device-detect';
import {useTranslation} from 'react-i18next';
import {slide as Menu} from 'react-burger-menu';

import SpotifyApiContext from '../spotify/spotifyApiContext';
import SpotifyPlayer from '../spotify/spotifyPlayer';
import SocialLinks from '../social/socialLinks';
import Logo from '../logo/logo';

import {useSelector} from 'react-redux';
import {permissionsSelector} from '../redux/permissions/permissions.selector';
import AuthenticatorContext from '../authenticator/authenticatorContext';

import './navigation.css';
import './navigationMobile.css';

type NavigationProps = {
  bridgeSelected: boolean;
  artistsSelected: boolean;
  genresSelected: boolean;
  albumsSelected: boolean;
  labelsSelected: boolean;
  canPlay: boolean;
  bridgeSelectionHandler(): void;
  artistsSelectionHandler(): void;
  genresSelectionHandler(): void;
  albumsSelectionHandler(): void;
  labelsSelectionHandler(): void;
};

export default function Navigation(props: NavigationProps): JSX.Element {
  const { t } = useTranslation();
  
  const spotifyApi = useContext(SpotifyApiContext);
  const permissions = useSelector(permissionsSelector);
  const authenticator = useContext(AuthenticatorContext);
  
  const [currentPlayer, setCurrentPlayer] = useState<SpotifyPlayer | null>(
    spotifyApi.getUserCurrentPlayer()
  );
  const [canPlay, setCanPlay] = useState<boolean>(props.canPlay);
  const [isDetecting, setIsDetecting] = useState<boolean>(false);

  const hasBridge = permissions.hasPersonalization;

  const bridgeNavPointClassName = props.bridgeSelected
    ? 'app-nav-point-selected'
    : '';

  const exploreArtistsNavPointClassName = props.artistsSelected
    ? 'app-nav-point-selected'
    : '';

  const exploreGenresNavPointClassName = props.genresSelected
    ? 'app-nav-point-selected'
    : '';

  const exploreAlbumsNavPointClassName = props.albumsSelected
    ? 'app-nav-point-selected'
    : '';

  const exploreLabelsNavPointClassName = props.labelsSelected
    ? 'app-nav-point-selected'
    : '';

  const loginToSpotify = () => {
    window.location.replace(authenticator.getAuthRedirectUrl());
  };

  let players;
  if (isMobile) {
    const onPlayerSelected = (player: SpotifyPlayer) => {
      setCurrentPlayer(player);
      spotifyApi.switchUserPlayer(player.id).then();
    };
    const detectDevices = async (): Promise<void> => {
      setIsDetecting(true);
      await spotifyApi.detectDevices();
      setIsDetecting(false);
      setCurrentPlayer(spotifyApi.getUserCurrentPlayer());
      setCanPlay(spotifyApi.canPlay());
    };
    if (isDetecting) {
      players = <div className='no-players'>{t('Detecting devices...')}</div>;
    } else if (canPlay) {
      players = spotifyApi.getUserPlayers().map((p) => (
        <span
          key={p.id}
          className={'player' + (p.id === currentPlayer?.id ? ' selected' : '')}
          onClick={() => onPlayerSelected(p)}
        >
          {p.name}
        </span>
      ));
    }
    else if (!permissions.isSubscriber) {
      players = (
        <div className='no-players'>
          <span onClick={loginToSpotify}>
            {t('Players.Mobile4')}
          </span>
          {t('Players.Mobile5')}
        </div>
      );
    } else {
      players = (
        <div className='no-players'>
          {t('Players.Mobile1')}
          <span title={t('Players.Mobile2')} onClick={detectDevices}>
            {t('Players.Mobile3')}
          </span>
        </div>
      );
    }
  }

  const navigate = (handler: () => void): void => {
    // todo: stupid hack, don't know why this menu doesn't close itself
    document.getElementById('react-burger-cross-btn')?.click();
    handler();
  };

  const links = (
    <div className='app-navigation'>
      {hasBridge && <Link
        to='/'
        className={bridgeNavPointClassName}
        onClick={() => navigate(props.bridgeSelectionHandler)}
      >
        {t('Bridge')}
      </Link>}
      <Link
        to='/artists'
        className={exploreArtistsNavPointClassName}
        onClick={() => navigate(props.artistsSelectionHandler)}
      >
        {t('Artists')}
      </Link>
      <Link
        to='/genres'
        className={exploreGenresNavPointClassName}
        onClick={() => navigate(props.genresSelectionHandler)}
      >
        {t('Genres')}
      </Link>
      <Link
        to='/albums'
        className={exploreAlbumsNavPointClassName}
        onClick={() => navigate(props.albumsSelectionHandler)}
      >
        {t('Albums')}
      </Link>
      <Link
        to='/labels'
        className={exploreLabelsNavPointClassName}
        onClick={() => navigate(props.labelsSelectionHandler)}
      >
        {t('Labels')}
      </Link>
    </div>
  );

  return isMobile ? (
    <Menu disableAutoFocus={true}>
      <Logo iconSize={30} />
      <h3>{t('Explore')}</h3>
      {links}
      <h3>{t('Playback device')}</h3>
      {players}
      <h3>{t('Social')}</h3>
      <SocialLinks size={32} />
      <div className='legal'>{t('Footer.Copyright')}</div>
    </Menu>
  ) : (
    links
  );
}
