import React, {useContext, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {GenresCategory, GenresGroup, GenresManager} from './genresManager';
import GenreCategory from './genreCategory';
import SuggestInput from '../suggest-input/suggestInput';
import {activateGenericBackground, deactivateGenericBackground} from '../util/background';
import {BridgeDataLoader} from '../bridge/bridgeDataLoader';
import SpotifyApiContext from '../spotify/spotifyApiContext';
import {track, TrackingEvent} from '../util/track';
import {useSelector} from 'react-redux';
import {permissionsSelector} from '../redux/permissions/permissions.selector';

import './genreBrowser.css';

const autoCollapseGenresThreshold = 50;
const expandAllGroupsThreshold = 100;

type GenreBrowserProps = {
    isLargeScreenMode?: boolean;
}

const getFlatGenreList = (categories: GenresCategory[]) => {
  if (categories.length === 0) {
    return  [];
  }
  return [...categories.map(g => g.groups)]
    .reduce((a: GenresGroup[], b: GenresGroup[]) => [...a, ...b])
    .map(g => g.genres)
    .reduce((a: string[], b: string[]) => [...a, ...b])
    .filter((value: string, index: number, self: string[]) => self.indexOf(value) === index);
};

const GenreBrowser: (props: GenreBrowserProps) => JSX.Element = (props: GenreBrowserProps) => {
  const { t } = useTranslation();
  const spotify = useContext(SpotifyApiContext);

  const permissions = useSelector(permissionsSelector);

  const genresManager: GenresManager = useMemo(() => {
    return new GenresManager(new BridgeDataLoader(spotify), permissions.hasLibrary); // todo: refactor this
  }, [spotify]);

  const [genres, setGenres] = useState<GenresCategory[]>([]);
  const [filter, setFilter] = useState<string>('');
  const [expandAll, setExpandAll] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const isFiltered = filter !== '';

  const prepareData = () => {
    const categories = genresManager.filter(filter);
    if (categories.length) {
      const genresList = getFlatGenreList(categories);
      if (genresList.length <= autoCollapseGenresThreshold) {
        setGenres([{name: '', description: '', icon: '', groups: [{name: '', genres: genresList}], toc: []}]);
      } else {
        setGenres(categories);
      }
      setExpandAll(genresList.length <= expandAllGroupsThreshold || categories.length === 1);
    } else {
      setGenres([]);
    }
  };

  useEffect(() => {
    activateGenericBackground();
    return () => deactivateGenericBackground();
  }, []);

  useEffect(() => {
    genresManager.init().then(() => {
      prepareData();
      setLoading(false);
    });
  }, [genresManager]);

  useEffect(() => {
    track(TrackingEvent.search, {
      'search_term': filter
    });
    prepareData();
  }, [filter, genresManager, isFiltered]);

  const handleBackToTopClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    document.body.scrollIntoView(true);
    document.getElementById('genre-input')?.focus();
  };

  const filterGenres = (filter: string) => {
    setFilter((filter.length) > 1 ? filter : '');
  };

  let categoryCounter = 0;
  const categoryElements = genres.map(category => {
    categoryCounter++;
    return <GenreCategory
      key={category.name}
      category={category}
      expanded={expandAll}
      last={categoryCounter === genres.length}
    />;
  });

  const genresCount = getFlatGenreList(genres).length;
  const resultStats = (filter !== '' && genresCount > 1)
    ? <div className="genres-browser-browse-all">
      <a href={'#/genres/' + getFlatGenreList(genres).join(',')}>
        <span>{t('browse.selected.1') + genresCount + t('browse.selected.2')}
          <strong> {filter}</strong> {t('browse.selected.3')}
        </span>
      </a>
    </div>
    : null;

  return <div className="genres-browser">
    <div className={`top-link-wrapper ${props.isLargeScreenMode ? 'visible' : ''}`} title="Back to top">
      <button onClick={handleBackToTopClick}>
        <img src={'top.svg'} alt="Move to top"/>
      </button>
    </div>
    <div className="selection-options">
      <SuggestInput placeholder={t('Type genre name')} value={filter} valueChangeHandler={filterGenres}/>
    </div>
    {resultStats}
    {categoryElements}
    {loading && <div className="spinner"/>}
  </div>;
};

export default GenreBrowser;
