import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {albumsStoreInitialState} from './albums.constants';

export const albumsStoreSlice = createSlice({
  name: 'albums',
  initialState: albumsStoreInitialState,
  reducers: {
    addSavedAlbumIds(store, action: PayloadAction<ReadonlyArray<string>>) {
      store.savedAlbumIds = [...store.savedAlbumIds, ...action.payload];
    },
    removeSavedAlbumIds(store, action: PayloadAction<ReadonlyArray<string>>) {
      const idsToRemove = new Set(action.payload);

      store.savedAlbumIds = store.savedAlbumIds.filter(id => !idsToRemove.has(id));
    },
  },
});
