import React, {useContext, useEffect, useState} from 'react';
import ReactFlow, {FlowElement} from 'react-flow-renderer';
import {isMobile} from 'react-device-detect';
import dagre from 'dagre';
import SpotifyApiContext from '../spotify/spotifyApiContext';
import {getLayoutElements} from '../util/getLayoutElements';
import SpotifyAlbum from '../spotify/spotifyAlbum';
import {arrayShuffle} from '../util/arrayShuffle';
import AlbumNode from './albumNode';
import AlbumFlowchartNode from './albumFlowchartNode';
import {activateGenericBackground, deactivateGenericBackground} from '../util/background';
import useCollectAlbumTracks, {AlbumTracksCount} from '../hooks/albums/useCollectAlbumTracks';
import CollectButton from './collect-button/collectButton';

import './albumExplorer.css';

const similarAlbumsCount = 8;

type AlbumExplorerProps = {
    rootAlbumId: string
    canPlay: boolean
}

const getNodeFromAlbum = (album: SpotifyAlbum, relatedAlbums: SpotifyAlbum[] = [],
  expanded = false) : AlbumNode => {
  return {
    id: album.id,
    album: album,
    relatedAlbums: relatedAlbums.map(a => a.id),
    expanded: expanded
  };
};

const AlbumExplorer: (props: AlbumExplorerProps) => JSX.Element = (props: AlbumExplorerProps) => {

  const spotifyApi = useContext(SpotifyApiContext);
  const [nodesCache, setNodesCache] = useState<Map<string, AlbumNode>>(new Map<string, AlbumNode>());
  const [rootNode, setRootNode] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [dagreGraph, setDagreGraph] = useState<dagre.graphlib.Graph>(new dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({})));
  const collectAlbumsTracks = useCollectAlbumTracks();

  const nodesToShow: string[] = [];

  const addNode = (album: SpotifyAlbum, relatedAlbums: SpotifyAlbum[],
    expanded = false, root = false) : void => {

    const cache = new Map<string, AlbumNode>((root) ? [] : nodesCache);
    cache.set(album.id, getNodeFromAlbum(album, relatedAlbums, expanded));
    relatedAlbums.forEach((album: SpotifyAlbum) =>
      cache.set(album.id, getNodeFromAlbum(album, [], false)));

    setNodesCache(cache);
    if (root) {
      setRootNode(album.id);
    }
  };

  const getRelatedAlbums = async (album: SpotifyAlbum): Promise<SpotifyAlbum[]> => {
    const artist = album.artists[0];
    const relatedArtistsIds = arrayShuffle((await spotifyApi.getSimilarArtists(artist.id))
      .map(a => a.id)).slice(0, similarAlbumsCount);

    const relatedAlbums: SpotifyAlbum[] = [];
    for (const artistId of relatedArtistsIds) {
      const artistAlbumsByGroups = await spotifyApi.getArtistAlbums(artistId, ['album', 'single'], 50);
      const artistAlbums = [
        ...(artistAlbumsByGroups.get('album') ?? []),
        ...(artistAlbumsByGroups.get('single') ?? [])
      ];
      if (!artistAlbums.length) {
        continue;
      }
      const hasAlbums = artistAlbums.find(a => a.type === 'album');
      const albumsSelection = (hasAlbums)
        ? artistAlbums.filter(a => a.type === 'album')
        : artistAlbums.filter(a => a.type !== 'compilation');
      const albums = arrayShuffle(albumsSelection);
      relatedAlbums.push(albums[0]);
    }
    return relatedAlbums;
  };

  useEffect(() => {
    activateGenericBackground(nodesCache.get(rootNode)?.album?.images?.[0]?.url);
    return () => deactivateGenericBackground();
  }, [nodesCache.get(rootNode)?.album?.images?.[0]?.url]);

  useEffect(() => {
    setLoading(true);
    spotifyApi.getAlbum(props.rootAlbumId).then(album => {
      getRelatedAlbums(album).then(related => {
        addNode(album, related, true, true);
        setDagreGraph(new dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({})));
        setLoading(false);
      });
    });
  }, [props.rootAlbumId, spotifyApi]);

  const addRelated = (id: string, relatedAlbums: SpotifyAlbum[]) : void => {
    const node = nodesCache.get(id);
    if (node && !node.relatedAlbums.length) {
      const cache = new Map<string, AlbumNode>(nodesCache);
      const albumNode = cache.get(id);
      if (!albumNode) {
        return;
      }
      relatedAlbums.forEach((album: SpotifyAlbum): void => {
        if (!cache.has(album.id)) {
          albumNode.relatedAlbums.push(album.id);
          cache.set(album.id, getNodeFromAlbum(album));
        }
      });
      albumNode.expanded = true;
      setNodesCache(cache);
    }
  };

  const toggleNode = (id: string) : void => {
    const cache = new Map<string, AlbumNode>(nodesCache);
    const node = cache.get(id);
    if (node) {
      node.expanded = !node.expanded;
      if (!node.expanded) {
        const collapseNodes = function(node: AlbumNode) {
          node.expanded = false;
          node.relatedAlbums.forEach(function(id: string) {
            const node = cache.get(id);
            if (node) {
              collapseNodes(node);
            }
          });
        };
        collapseNodes(node);
      }
      setNodesCache(cache);
    }
  };

  const isExpanded = (id: string) : boolean =>
    nodesCache.get(id)?.expanded ?? false;

  const toggleAlbum = async (id: string): Promise<void> => {
    if (!isExpanded(id)) {
      const node = nodesCache.get(id);
      if (!node) {
        return;
      }
      if (!node.relatedAlbums.length) {
        setLoading(true);
        addRelated(id, await getRelatedAlbums(node.album));
        setLoading(false);
      } else {
        toggleNode(id);
      }
    } else {
      toggleNode(id);
    }
  };

  const collectTracks = async (count: AlbumTracksCount) => {
    setLoading(true);
    const albums: SpotifyAlbum[] = [];
    for (const albumId of nodesToShow) {
      const node = nodesCache.get(albumId);
      if (node) {
        albums.push(node.album);
      }
    }
    const rNode = nodesCache.get(rootNode);
    if (rNode) {
      await collectAlbumsTracks(albums, count, rNode.album.name + '+');
    }
    setLoading(false);
  };

  const elements: FlowElement[] = [];
  nodesCache.forEach((node: AlbumNode) => {
    if (node.expanded || node.id === rootNode) {
      nodesToShow.push(node.id);
    }
    if (node.expanded) {
      nodesToShow.push(...node.relatedAlbums);
    }
  });

  nodesCache.forEach((node: AlbumNode, id: string) => {
    if (nodesToShow.indexOf(id) === -1) {
      return;
    }
    const nodeComponent =
            <AlbumFlowchartNode
              album={node.album}
              expanded={node.expanded}
              toggleHandler={() => toggleAlbum(id)}
            />;

    elements.push({
      id: node.id,
      data: {label: nodeComponent},
      position: {x: 0, y: 0},
      className: 'flowchart-node'
    });
    if (node.expanded) {
      node.relatedAlbums.forEach(album => {
        elements.push({
          id: node.id + '-' + album,
          source: node.id,
          target: album
        });
      });
    }
  });

  const spinner = (loading)
    ? <div className="spinner"/>
    : null;

  const flowchartElements = getLayoutElements(dagreGraph, elements, 'LR', 240,
    (isMobile) ? 120 : 105, undefined, 100, 35);
  const flow = (flowchartElements.length)
    ? <ReactFlow
      elements={flowchartElements}
      nodesConnectable={false}
      elementsSelectable={false}
      nodesDraggable={false}
    />
    : null;

  return (
    <div className="spotify-explorer spotify-album-explorer">
      <CollectButton onClick={collectTracks} />
      {flow}
      {spinner}
    </div>
  );
};

export default AlbumExplorer;
