import {useContext} from 'react';
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';
import {useAppDispatch} from '../../redux/store';

import SpotifyApiContext from '../../spotify/spotifyApiContext';
import SpotifyArtist from '../../spotify/spotifyArtist';
import SpotifyTrack from '../../spotify/spotifyTrack';

import {ArtistTracksCollectionMode, ArtistTracksCount} from './artistTracksCollectionEnums';
import useCollectTracks from '../your-mix/useCollectTracks';
import {yourMixStoreSlice} from '../../redux/your-mix/yourMix.slice';

import {arrayShuffle} from '../../util/arrayShuffle';
import arrayChunk from '../../util/arrayChunk';
import {persistYourMix} from '../../redux/your-mix/yourMix.actions';

function useCollectArtistTracks() : (artists: SpotifyArtist[],
                                     count: ArtistTracksCount,
                                     mode: ArtistTracksCollectionMode,
                                     mixSuffix: string) => Promise<number> {

  const { t } = useTranslation();
  const api = useContext(SpotifyApiContext);
  const dispatch = useAppDispatch();
  const [collectTracks] = useCollectTracks();

  return async (artists: SpotifyArtist[], count: ArtistTracksCount, mode: ArtistTracksCollectionMode, mixSuffix: string) => {
    try {
      let total = 0;
      for (const artist of artists) {
        let numberOfTracks = 1;
        if (count === ArtistTracksCount.few) {
          numberOfTracks = Math.floor(Math.random() * 2) + 2;
        } else if (count === ArtistTracksCount.many) {
          numberOfTracks = Math.floor(Math.random() * 4) + 4;
        } else if (count === ArtistTracksCount.maximum) {
          numberOfTracks = (mode === ArtistTracksCollectionMode.top) ? 10 : 2000;
        }
        const tracks: SpotifyTrack[] = [];
        if (mode === ArtistTracksCollectionMode.top) {
          const topTracks = await api.getArtistTopTracks(artist.id);
          tracks.push(...arrayShuffle(topTracks).slice(0, numberOfTracks));
        } else {

          const albumTypes = (mode === ArtistTracksCollectionMode.discography)
            ? ['album', 'single']
            : ['album', 'single', 'appears_on'];
          const albums = Array.from((await api.getArtistAlbums(artist.id, albumTypes)).values()).flat(1);

          const trackIds: string[] = [];
          for (const album of await api.getAlbums(albums.map(album => album.id))) {
            const selectedTracks = album.tracks.filter(track =>
              track.artists.map(a => a.name.toLowerCase()).includes(artist.name.toLowerCase()));
            trackIds.push(...selectedTracks.map(t => t.id));
          }
          const selection = arrayShuffle(trackIds).slice(0, numberOfTracks);
          const trackChunks = arrayChunk<string>(selection, 50);
          for (const trackChunk of trackChunks) {
            tracks.push(...await api.getTracks(trackChunk));
          }
        }
        collectTracks(tracks);
        total += tracks.length;
      }
      if (mixSuffix) {
        dispatch(yourMixStoreSlice.actions.setMixSuffix(mixSuffix));
      } else {
        dispatch(yourMixStoreSlice.actions.resetMixSuffix());
      }
      dispatch(persistYourMix());
      if (total > 1) {
        toast(t('TracksCollector.CollectionSucceeded', {count: total}), {
          type: 'success',
          position: 'top-center'
        });
      }
      return total;

    } catch (error) {
      toast(t('TracksCollector.CollectionFailed'), {
        type: 'error',
        position: 'top-center'
      });
      console.log(error);
      return 0;
    }
  };
}

export default useCollectArtistTracks;
