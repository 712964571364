import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SpotifyApiContext from '../spotify/spotifyApiContext';
import { BridgeDataLoader, DataMode } from './bridgeDataLoader';
import SpotifyArtist from '../spotify/spotifyArtist';
import { ApiTimeRange } from '../spotify/api';
import SpotifyAlbum from '../spotify/spotifyAlbum';
import { ArtistTile } from '../artist-tile/artistTile';
import ModeMenu from './modeMenu';
import SlidingRow from './slider';
import { AlbumTile } from '../album-tile/albumTile';
import { activateGenericBackground, deactivateGenericBackground} from '../util/background';
import { useSelector } from 'react-redux';
import { bridgeGuiSettingsSelector } from '../redux/gui-settings/guiSettings.selector';
import { guiSettingsStoreSlice } from '../redux/gui-settings/guiSettings.slice';
import { useAppDispatch } from '../redux/store';

import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/theme-dark.css';
import './bridge.css';
import './bridgeMobile.css';
import {isMobile} from 'react-device-detect';

function Bridge(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const spotifyApi = useContext(SpotifyApiContext);
  const dataLoader: BridgeDataLoader = useMemo(() => {
    return new BridgeDataLoader(spotifyApi);
  }, [spotifyApi]);
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState<boolean>(true);
  const [artists, setArtists] = useState<SpotifyArtist[]>([]);
  const [albums, setAlbums] = useState<SpotifyAlbum[]>([]);
  const [genres, setGenres] = useState<string[]>([]);
  const [labels, setLabels] = useState<string[]>([]);
  const {
    albumsMode,
    albumsRange,
    artistsMode,
    artistsRange,
    genresMode,
    genresRange,
    labelsMode,
    labelsRange
  } = useSelector(bridgeGuiSettingsSelector);
  const userName = dataLoader.getUserProfile()?.name;

  useEffect(() => {
    activateGenericBackground();
    return () => deactivateGenericBackground();
  }, []);

  useEffect(() => {
    setLoading(true);
    dataLoader
      .getArtists(artistsMode, artistsRange)
      .then(setArtists)
      .finally(() => setLoading(false));
  }, [dataLoader, artistsMode, artistsRange]);

  useEffect(() => {
    setLoading(true);
    dataLoader
      .getAlbums(albumsMode, albumsRange)
      .then(setAlbums)
      .finally(() => setLoading(false));
  }, [dataLoader, albumsMode, albumsRange]);

  useEffect(() => {
    setLoading(true);
    dataLoader
      .getGenres(genresMode, genresRange, isMobile ? 20 : 30)
      .then(setGenres)
      .finally(() => setLoading(false));
  }, [dataLoader, genresMode, genresRange]);

  useEffect(() => {
    setLoading(true);
    dataLoader
      .getLabels(labelsMode, labelsRange, isMobile ? 20 : 30)
      .then(setLabels)
      .finally(() => setLoading(false));
  }, [dataLoader, labelsMode, labelsRange]);

  const artistBlocks = artists.map((artist) => (
    <ArtistTile key={artist.id} artist={artist} />
  ));

  const albumBlocks = albums.map((album) => (
    <AlbumTile
      key={album.id}
      album={album}
      displayArtist={true}
      displayYear={true}
    />
  ));

  const genreBlocks = genres.map((genre) => {
    return (
      <a key={`brdgg-${genre}`} className="bridge-genre" href={'#/genres/' + genre}>
        {genre}
      </a>
    );
  });

  const labelBlocks = labels.map((label) => {
    return (
      <a key={`brdgl-${label}`} className="bridge-label" href={'#/labels/' + label}>
        {label}
      </a>
    );
  });

  const _ensureProperRange = function (
    currentMode: DataMode,
    proposedMode: DataMode,
    proposedRange: ApiTimeRange
  ) {
    if (
      currentMode !== DataMode.recommended &&
      proposedMode === DataMode.recommended
    ) {
      return ApiTimeRange.latest;
    } else if (
      currentMode === DataMode.recommended &&
      proposedMode === DataMode.top &&
      proposedRange === ApiTimeRange.latest
    ) {
      return ApiTimeRange.mediumTerm;
    }
    return proposedRange;
  };

  const setArtistsDisplayOptions = function (
    mode: DataMode,
    range: ApiTimeRange
  ) {
    dispatch(
      guiSettingsStoreSlice.actions.setBridgeState({
        artistsMode: mode,
        artistsRange: _ensureProperRange(artistsMode, mode, range),
      })
    );
  };

  const setAlbumsDisplayOptions = function (
    mode: DataMode,
    range: ApiTimeRange
  ) {
    dispatch(
      guiSettingsStoreSlice.actions.setBridgeState({
        albumsMode: mode,
        albumsRange: _ensureProperRange(albumsMode, mode, range),
      })
    );
  };

  const setGenresDisplayOptions = function (
    mode: DataMode,
    range: ApiTimeRange
  ) {
    dispatch(
      guiSettingsStoreSlice.actions.setBridgeState({
        genresMode: mode,
        genresRange: _ensureProperRange(genresMode, mode, range),
      })
    );
  };

  const setLabelsDisplayOptions = function (
    mode: DataMode,
    range: ApiTimeRange
  ) {
    dispatch(
      guiSettingsStoreSlice.actions.setBridgeState({
        labelsMode: mode,
        labelsRange: _ensureProperRange(labelsMode, mode, range),
      })
    );
  };

  const exploreArtists = () => {
    history.push('/artists');
  };

  const exploreAlbums = () => {
    history.push('/albums');
  };

  const exploreGenres = () => {
    history.push('/genres');
  };

  const exploreLabels = () => {
    history.push('/labels');
  };

  return (
    <div className="bridge">
      <div className="bridge-greeting">
        {t('Bridge.Greeting1')}, {userName}!
      </div>
      <div className="bridge-greeting2">{t('Bridge.Greeting2')}</div>

      <div className="bridge-section bridge-artists">
        <div className="bridge-section-title">
          <h2>{t('Bridge.Artists')}</h2>
          <button className="bridge-section-more" onClick={exploreArtists}>
            {t('Bridge.FindArtists')} →
          </button>
        </div>
        <div className="bridge-section-buttons">
          <ModeMenu
            mode={artistsMode}
            range={artistsRange}
            includeRecommended={true}
            modeChangeHandler={setArtistsDisplayOptions}
          />
        </div>
        <div className="bridge-section-list bridge-artists-list">
          <SlidingRow>{artistBlocks}</SlidingRow>
        </div>
      </div>
      <div className="bridge-section bridge-albums">
        <div className="bridge-section-title">
          <h2>{t('Bridge.Albums')}</h2>
          <button className="bridge-section-more" onClick={exploreAlbums}>
            {t('Bridge.FindAlbums')} →
          </button>
        </div>
        <div className="bridge-section-buttons">
          <ModeMenu
            mode={albumsMode}
            range={albumsRange}
            includeRecommended={true}
            modeChangeHandler={setAlbumsDisplayOptions}
          />
        </div>
        <div className="bridge-section-list bridge-albums-list">
          <SlidingRow>{albumBlocks}</SlidingRow>
        </div>
      </div>
      <div className="bridge-section bridge-genres">
        <div className="bridge-section-title">
          <h2>{t('Bridge.Genres')}</h2>
          <button className="bridge-section-more" onClick={exploreGenres}>
            {t('Bridge.FindGenres')} →
          </button>
        </div>
        <div className="bridge-section-buttons">
          <ModeMenu
            mode={genresMode}
            range={genresRange}
            includeRecommended={false}
            modeChangeHandler={setGenresDisplayOptions}
          />
        </div>
        <div className="bridge-section-list bridge-genres-list">
          {genreBlocks}
        </div>
      </div>
      <div className="bridge-section bridge-labels">
        <div className="bridge-section-title">
          <h2>{t('Bridge.Labels')}</h2>
          <button className="bridge-section-more" onClick={exploreLabels}>
            {t('Bridge.FindLabels')} →
          </button>
        </div>
        <div className="bridge-section-buttons">
          <ModeMenu
            mode={labelsMode}
            range={labelsRange}
            includeRecommended={true}
            modeChangeHandler={setLabelsDisplayOptions}
          />
        </div>
        <div className="bridge-section-list bridge-labels-list">
          {labelBlocks}
        </div>
      </div>
      {loading && <div className="spinner" />}
    </div>
  );
}

export default Bridge;
