type CoverArtInfo = {
  url: string,
  width: number,
  height: number
}

class ServerApi {

  // todo: change to uncharted.com
  public static readonly serverBase = 'https://spotify-uncharted.com:4000/v1';
  // public static readonly serverBase = 'https://127.0.0.1:4000/v1';

  async getCoverArt(artist: string, album: string): Promise<CoverArtInfo> {
    const response = await fetch(`${ServerApi.serverBase}/external/am?ar=${artist}&al=${album}`);
    return response.json();
  }
}

export default ServerApi;