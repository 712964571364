import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import App from './app/app';
import reportWebVitals from './reportWebVitals';
import {HashRouter} from 'react-router-dom';
import Landing from './landing/landing';
import {isMobile} from 'react-device-detect';
import {initializeTracking} from './util/track';
import './i18n/i18n';
import store from './redux/store';
import Authenticator from './authenticator/authenticator';

import './index.css';

initializeTracking();
const body = document.getElementsByTagName('body')[0];
body.classList[(isMobile) ? 'add' : 'remove']('mobile');

const isSkipLogin = window.location.search.startsWith('?guest');
if (isSkipLogin) {
  window.location.replace(window.location.href.replace('?guest', '') + '#genres');
}

const isLandingPage = !window.location.hash;
const authenticator = new Authenticator();
if (!isLandingPage || authenticator.hasAuth() || authenticator.isAuthBacklink()) {
  body.classList.remove('full-height');
  ReactDOM.render(
    <React.StrictMode>
      <HashRouter>
        <Provider store={store}>
          <App />
        </Provider>
      </HashRouter>
    </React.StrictMode>,
    document.getElementById('root')
  );
}
else {
  body.classList.add('full-height');
  ReactDOM.render(
    <React.StrictMode>
      <Landing authRedirectUrl={authenticator.getAuthRedirectUrl()} />
    </React.StrictMode>,
    document.getElementById('root')
  );
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
