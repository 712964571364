import React from 'react';
import {useSelector} from 'react-redux';
import {collectedTracksSelector} from '../redux/your-mix/yourMix.selector';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';

import './yourMixBadge.css';

const YourMixBadge: () => JSX.Element = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const tracks = useSelector(collectedTracksSelector);

  const open = () => {
    history.push('/your-mix');
  };

  return <div className="tracks-collector-badge" onClick={open} title={t('TracksCollectorBadge.Open')}>
    <span className="tracks-collector-badge-counter">
      {tracks.length}
    </span>
    <img src={'playlist.svg'} alt={t('TracksCollectorBadge.PlaylistIcon')}/>
  </div>;
};

export default YourMixBadge;