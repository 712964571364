import {combineReducers} from 'redux';
import {artistsStoreSlice} from './artists/artists.slice';
import {albumsStoreSlice} from './albums/albums.slice';
import {guiSettingsStoreSlice} from './gui-settings/guiSettings.slice';
import {yourMixStoreSlice} from './your-mix/yourMix.slice';
import {playlistsStoreSlice} from './playlists/playlists.slice';
import {playerStoreSlice} from './player/player.slice';
import {permissionsStoreSlice} from './permissions/permissions.slice';

export const storeReducer = combineReducers({
  permissions: permissionsStoreSlice.reducer,
  artists: artistsStoreSlice.reducer,
  albums: albumsStoreSlice.reducer,
  guiSettings: guiSettingsStoreSlice.reducer,
  yourMix: yourMixStoreSlice.reducer,
  playlists: playlistsStoreSlice.reducer,
  player: playerStoreSlice.reducer
});
