import React from 'react';
import {useTranslation} from 'react-i18next';
import {MenuDivider, MenuHeader, MenuItem} from '@szhsin/react-menu';
import {useSelector} from 'react-redux';
import {ArtistTracksCollectionMode, ArtistTracksCount} from '../../hooks/artists/artistTracksCollectionEnums';
import {permissionsSelector} from '../../redux/permissions/permissions.selector';

export default function useArtistMixMenuItems
(handler: (count: ArtistTracksCount, mode: ArtistTracksCollectionMode) => void,
  includeMaxTracks = true, includeAnyTracks = false) : JSX.Element[] {

  const {t} = useTranslation();
  const permissions = useSelector(permissionsSelector);
  const canCollect = permissions.canCollectTracks;

  return [
    <MenuHeader key={'amctt-popular'}>
      {t('ArtistMenu.CollectTracks.Top')}
    </MenuHeader>,
    <MenuItem
      key={'amctt-popular-one'}
      onClick={() => handler(ArtistTracksCount.one, ArtistTracksCollectionMode.top)}
      disabled={!canCollect}>
      {t('ArtistMenu.CollectTracks.One')}
    </MenuItem>,
    <MenuItem
      key={'amctt-popular-few'}
      onClick={() => handler(ArtistTracksCount.few, ArtistTracksCollectionMode.top)}
      disabled={!canCollect}>
      {t('ArtistMenu.CollectTracks.Few')}
    </MenuItem>,
    <MenuItem
      key={'amctt-popular-many'}
      onClick={() => handler(ArtistTracksCount.many, ArtistTracksCollectionMode.top)}
      disabled={!canCollect}>
      {t('ArtistMenu.CollectTracks.Many')}
    </MenuItem>,
    <MenuItem
      key={'amctt-popular-max'}
      onClick={() => handler(ArtistTracksCount.maximum, ArtistTracksCollectionMode.top)}
      disabled={!canCollect}>
      {t('ArtistMenu.CollectTracks.Maximum')}
    </MenuItem>,
    <MenuDivider key={'amctt-divider'}/>,

    <MenuHeader key={'amctt-disco'}>
      {t('ArtistMenu.CollectTracks.Disco')}
    </MenuHeader>,
    <MenuItem
      key={'amctt-disco-one'}
      onClick={() => handler(ArtistTracksCount.one, ArtistTracksCollectionMode.discography)}
      disabled={!canCollect}>
      {t('ArtistMenu.CollectTracks.One')}
    </MenuItem>,
    <MenuItem
      key={'amctt-disco-few'}
      onClick={() => handler(ArtistTracksCount.few, ArtistTracksCollectionMode.discography)}
      disabled={!canCollect}>
      {t('ArtistMenu.CollectTracks.Few')}
    </MenuItem>,
    <MenuItem
      key={'amctt-disco-many'}
      onClick={() => handler(ArtistTracksCount.many, ArtistTracksCollectionMode.discography)}
      disabled={!canCollect}>
      {t('ArtistMenu.CollectTracks.Many')}
    </MenuItem>,
    ...includeMaxTracks
      ? [<MenuItem
        key={'amctt-disco-max'}
        onClick={() => handler(ArtistTracksCount.maximum, ArtistTracksCollectionMode.discography)}
        disabled={!canCollect}>
        {t('ArtistMenu.CollectTracks.Maximum')}
      </MenuItem>]
      : [],

    ...includeAnyTracks
      ? [ <MenuDivider key={'amctt-divider'}/>,
        <MenuHeader key={'amctt-anytracks'}>
          {t('ArtistMenu.CollectTracks.Any')}
        </MenuHeader>,
        <MenuItem
          key={'amctt-any-one'}
          onClick={() => handler(ArtistTracksCount.one, ArtistTracksCollectionMode.any)}
          disabled={!canCollect}>
          {t('ArtistMenu.CollectTracks.One')}
        </MenuItem>,
        <MenuItem
          key={'amctt-any-few'}
          onClick={() => handler(ArtistTracksCount.few, ArtistTracksCollectionMode.any)}
          disabled={!canCollect}>
          {t('ArtistMenu.CollectTracks.Few')}
        </MenuItem>,
        <MenuItem
          key={'amctt-any-many'}
          onClick={() => handler(ArtistTracksCount.many, ArtistTracksCollectionMode.any)}
          disabled={!canCollect}>
          {t('ArtistMenu.CollectTracks.Many')}
        </MenuItem>,
        ...includeMaxTracks
          ? [<MenuItem key={'amctt-any-max'}
            onClick={() => handler(ArtistTracksCount.maximum, ArtistTracksCollectionMode.any)}
            disabled={!canCollect}>
            {t('ArtistMenu.CollectTracks.Maximum')}
          </MenuItem>]
          : []
      ] : [],
  ];
}
