import React, {useContext, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useAppDispatch} from '../redux/store';
import {useHistory, useRouteMatch} from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import {useTranslation} from 'react-i18next';
import {isMobile} from 'react-device-detect';

import SuggestInput from '../suggest-input/suggestInput';
import SpotifyApiContext from '../spotify/spotifyApiContext';
import SpotifyAlbum from '../spotify/spotifyAlbum';
import {AlbumTile} from '../album-tile/albumTile';
import {activateGenericBackground, deactivateGenericBackground} from '../util/background';
import CollectButton from '../album-selection-collect-button/collectButton';
import useCollectAlbumTracks, {AlbumTracksCount} from '../hooks/albums/useCollectAlbumTracks';
import {labelsExplorerSettingsSelector} from '../redux/gui-settings/guiSettings.selector';
import {guiSettingsStoreSlice} from '../redux/gui-settings/guiSettings.slice';
import {persistGuiSettings} from '../redux/gui-settings/guiSettings.actions';
import {AlbumSelectionLoader} from '../album-selection-loader/albumSelectionLoader';
import AlbumSelectionOptions, {AlbumSelectionOptionValues} from '../album-selection-options/albumSelectionOptions';

import {BridgeDataLoader} from '../bridge/bridgeDataLoader';
import {LabelsCategory, LabelsManager} from './labelsManager';
import LabelCategory from './labelCategory';

import Api from '../spotify/api';
import {permissionsSelector} from '../redux/permissions/permissions.selector';

import './labelExplorer.css';
import './labelExplorerMobile.css';

interface LabelExplorerProps {
    isLargeScreenMode?: boolean;
    label?: string
}

export const LabelExplorer: (props: LabelExplorerProps) => JSX.Element = (props: LabelExplorerProps) => {
  const {t} = useTranslation();
  const history = useHistory();

  const defaultOptions = useSelector(labelsExplorerSettingsSelector);
  const permissions = useSelector(permissionsSelector);

  const [categories, setCategories] = useState<LabelsCategory[]>([]);
  const [showCategories, setShowCategories] = useState<boolean>(false);
  const [albums, setAlbums] = useState<SpotifyAlbum[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [categoriesLoading, setCategoriesLoading] = useState<boolean>(false);
  const [label, setLabel] = useState<string>('');
  const [options, setOptions] = useState<AlbumSelectionOptionValues>(defaultOptions.options);
  const [query, setQuery] = useState<string>('');
  const hasQuery = query !== '';
  const hasAlbums = albums.length !== 0;
  const isSearchEnabled = hasQuery && !categoriesLoading;

  const dispatch = useAppDispatch();

  const spotifyApi = useContext(SpotifyApiContext);

  const labelsRoute = useRouteMatch('/labels/:label?');
  let routeParams = {label: ''};
  if (labelsRoute) {
    routeParams = labelsRoute.params as {label: string};
  }

  const labelsManager: LabelsManager = useMemo(() => {
    return new LabelsManager(new BridgeDataLoader(spotifyApi)); // todo: refactor this
  }, [spotifyApi]);

  const albumsLoader: AlbumSelectionLoader = useMemo(() => {
    return new AlbumSelectionLoader(
      spotifyApi,

      (label: string) => `label:"${label}"`,

      async (query: string, limit: number, offset: number, exactMatch) => {
        const response = await spotifyApi.searchTracks(query, limit, offset);
        if (!response) {
          return [[], false];
        }
        const albums = await spotifyApi.getAlbums(response.items.map(t => t.album.id));
        const labelToMatch = label.toLocaleLowerCase();
        const matchedAlbums = albums.filter(album => {
          if (exactMatch === true) {
            const copyrights = [...new Set(album.copyrights.map(c =>
              c.trim().toLocaleLowerCase().replace(/^[©℗]\s*\d+\s*/, '')))];
            return copyrights.filter(c => c === labelToMatch).length !== 0;
          } else {
            return album.copyrights.join(' ').toLocaleLowerCase().match(labelToMatch);
          }
        });
        return [matchedAlbums, !!response?.next];
      }
    );
  }, [spotifyApi, label]);

  const collectAlbumsTracks = useCollectAlbumTracks();

  const loadAlbums = async (initial = false) => {
    if ((initial || loading) && !albumsLoader.isLoading()) {
      const nextAlbums = await albumsLoader.nextAlbums();
      if (!albumsLoader.hasMoreAlbums()) {
        setLoading(false);
      }
      const currentAlbums = initial ? [] : albums;
      setAlbums([...currentAlbums, ...nextAlbums]);
    }
  };

  useEffect(() => {
    activateGenericBackground();
    return () => deactivateGenericBackground();
  }, []);

  useEffect(() => {
    if (label === '') {
      return;
    }
    setAlbums([]);
    setLoading(true);

    albumsLoader.init(
      [label],
      options.includeAlbums,
      options.includeSingles,
      options.includeVA,
      options.years.from,
      options.years.to,
      options.exactMatch
    );
    loadAlbums(true).then();
  }, [albumsLoader, label, options]);

  const startSearch = (labelQuery: string) => {
    history.push(`/labels/${labelQuery}`);
  };

  // todo: refactor this abomination
  useEffect(() => {
    const query = routeParams.label?.trim() ?? '';
    setQuery(query);
    setLabel(query);

    const showPersonalizedLabels = permissions.hasPersonalization && !query;
    console.log(query);
    if (!showPersonalizedLabels) {
      setShowCategories(false);
      setCategoriesLoading(false);
    } else {
      setAlbums([]);
      if (categories.length === 0) {
        setCategoriesLoading(true);
        labelsManager.getCategories().then((categories: LabelsCategory[]) => {
          setCategories(categories);
          setShowCategories(true);
          setCategoriesLoading(false);
        });
      } else {
        setShowCategories(true);
      }
    }
  }, [routeParams.label]);

  const applyOptions = (options: AlbumSelectionOptionValues) => {
    dispatch(guiSettingsStoreSlice.actions.setLabelExplorerState({options: options}));
    dispatch(persistGuiSettings());
    setOptions(options);
  };

  const collectTracks = async (count: AlbumTracksCount) => {
    setLoading(true);
    await collectAlbumsTracks(albums, count, label);
    setLoading(false);
  };

  const albumBlocks = albums.map(album =>
    <AlbumTile album={album} key={album.id} displayArtist={true} displayLabel={true}/>);

  let categoryCounter = 0;
  const categoryElements = categories.map(category => {
    categoryCounter++;
    return <LabelCategory
      key={category.name}
      category={category}
      last={categoryCounter === categories.length}
    />;
  });

  const loader = loading ? <div className="spinner"/> : null;

  return <div className="label-explorer" style={{backgroundImage: 'url(seabg.png)'}}>
    <div className="selection-options">
      <SuggestInput
        placeholder={t('LabelsExplorer.SuggestPlaceholder')}
        value={query}
        valueChangeHandler={setQuery}
        submitHandler={(labelQuery: string) => {
          setQuery(labelQuery);
          startSearch(labelQuery);
        }}
      />
      {!isMobile && <input className="label-search-button" type="button" value={'Search'} onClick={() => startSearch(query)} disabled={!isSearchEnabled} />}
      {isMobile && <button className="label-search-button" onClick={() => startSearch(query)} disabled={!isSearchEnabled}>
        <img src={'search.svg'} alt="Search for label albums"/>
      </button>}
    </div>

    <div className={`album-selection-options ${props.isLargeScreenMode ? 'album-selection-options-large-screen' : ''}`}>
      <AlbumSelectionOptions options={options} includeExactSwitch={true} onApply={applyOptions}/>
      {hasAlbums && <CollectButton onClick={collectTracks} />}
    </div>

    {!showCategories &&
        <InfiniteScroll
          dataLength={albums.length}
          next={loadAlbums}
          hasMore={true}
          loader={loader}
          className="scroll-container">

          {albumBlocks}
        </InfiniteScroll>
    }
    {showCategories && categoryElements}
    {categoriesLoading && <div className="spinner"/>}
  </div>;
};
